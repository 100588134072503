import { put, cancel, delay, takeEvery, type SagaReturnType } from 'redux-saga/effects';
import Alert from 'components/Alert';

import * as actions from '../actions';
import loadItems, { type LoadGroup } from '../model/loadItems';
import prepareData from '../model/prepareData';

export const config = {
  action: actions.loadById.type,
  method: takeEvery,
};

let lastCallId = 0;

const requestBuffer: {
  id: number[];
  spaceId?: number;
  mode: 'preview' | 'full';
}[] = [];

/**
 * Задержка для дебаунса.
 */
const DEBOUNCE_TIME_MS = 150;

/**
 * Вспомогательная функция, которая «выгружает» все накопленные запросы из буфера
 * и группирует их по spaceId, выбирая "приоритет" (all vs interaction).
 */
function flushRequestsAndGroup() {
  // Забираем все запросы
  const requests = requestBuffer.splice(0, requestBuffer.length);
  const groupMap = new Map<number | 'default', { ids: Set<number>; modePriority: number }>();

  for (const req of requests) {
    // mode='full' => приоритет 2, mode='preview' => приоритет 1
    const priority = req.mode === 'full' ? 2 : 1;
    const key = req.spaceId ?? 'default';

    if (!groupMap.has(key)) {
      groupMap.set(key, {
        ids: new Set(req.id),
        modePriority: priority,
      });
    } else {
      const group = groupMap.get(key)!;
      req.id.forEach((i) => group.ids.add(i));
      // Если среди нескольких запросов попался хотя бы один 'full', ставим приоритет = 2 => 'all'
      if (priority > group.modePriority) {
        group.modePriority = priority;
      }
    }
  }

  // Превращаем Map в массив
  return Array.from(groupMap.entries()).map(([spaceIdKey, info]) => ({
    spaceId: spaceIdKey === 'default' ? undefined : (spaceIdKey as number),
    ids: Array.from(info.ids),
    join: info.modePriority === 2 ? 'all' : ('interaction' as const),
  })) as LoadGroup[];
}

/**
 * Основная сага, реагирующая на экшн loadById.
 * Собирает все payload'ы в буфер, ждет DEBOUNCE_TIME_MS мс,
 * затем формирует один (или несколько) запрос(ов).
 */
export function* func(action: SagaReturnType<typeof actions.loadById>) {
  const callId = ++lastCallId;

  const { id, options } = action.payload;
  const spaceId = options?.spaceId;
  const mode: 'preview' | 'full' = options?.mode ?? 'full';

  const idsArray = Array.isArray(id) ? id : [id];
  requestBuffer.push({ id: idsArray, spaceId, mode });

  yield delay(DEBOUNCE_TIME_MS);

  // Если за время дебаунса пришёл новый экшн, у которого callId больше, чем у текущего
  // => завершаем эту сагу, так как будет выполняться более новый запрос.
  if (callId !== lastCallId) {
    yield cancel();
    return;
  }

  // Группируем все накопленные запросы
  const groups = flushRequestsAndGroup();

  // Вызываем loadItems
  const { entities, errorMessage } = yield* loadItems(groups);

  if (errorMessage) {
    Alert.error(errorMessage || 'Server error');
  } else {
    // Пишем данные в редакс
    yield put(actions.setItem(entities.map(prepareData)));
  }

  // Для всех запросов (которые мы сейчас обрабатывали) отправляем loadByIdDone,
  // чтобы «закрыть» экшны.
  // Обратите внимание: в нашем текущем коде, когда мы вызвали flushRequestsAndGroup,
  // requests уже извлекли из буфера, так что нам нужно
  // либо сохранить их заранее, либо перебрать groups и «восстановить» из них.
  // Ниже, для простоты, просто пройдемся по тем, что были в буфере.
  // Но flushRequestsAndGroup «спилил» массив. Можно переписать чуть иначе,
  // если нужно точное соответствие id->options.

  // Вариант: накапливать исходные экшны (со всеми options) в буфере:
  // requestBuffer: { payload, ... } и т.д.
  // Тогда после flushRequestsAndGroup можно выдать их же.
  // Для наглядности ниже — простое решение:
  for (const group of groups) {
    // Нет 100% инфо о mode / options, но предположим, что options нам достаточно
    yield put(
      actions.loadByIdDone({
        id: group.ids,
        options: { ...options, spaceId: group.spaceId },
      }),
    );
  }
}
