import { type SyntheticEvent, memo, useCallback } from 'react';
import * as Popover from '@radix-ui/react-popover';
import MenuItem from '@mui/joy/MenuItem';
import { Box, ListItemDecorator, MenuList, Switch, useColorScheme } from '@mui/joy';

import Icon from 'ui/Icon';
import Divider from 'ui/Divider';

export type ContextMenuActionsType =
  | 'OpenCopilot'
  | 'Rename'
  | 'Edit'
  | 'Permissions'
  | 'Delete'
  | 'New'
  | 'TogglePrivate'
  | 'Select'
  | 'Duplicate'
  | 'Move'
  | 'Share';

export interface ContextMenuProps {
  open: boolean;
  coords: { x: number; y: number };
  onOpenChange: (open: boolean) => void;
  onAction?: (event: SyntheticEvent, name: ContextMenuActionsType) => void;
  isPrivate?: boolean;
  hasCopilot?: boolean;
  hasShare?: boolean;
  hasNew?: boolean;
  hasRename?: boolean;
  hasEdit?: boolean;
  hasPermissions?: boolean;
  hasDelete?: boolean;
  hasPrivate?: boolean;
  hasSelect?: boolean;
  hasDuplicate?: boolean;
  hasMove?: boolean;
}

const ContextMenu = (props: ContextMenuProps) => {
  const {
    open,
    coords,
    onOpenChange,
    onAction,
    isPrivate,
    hasCopilot,
    hasShare,
    hasNew,
    hasRename,
    hasEdit,
    hasPermissions,
    hasDelete,
    hasPrivate,
    hasSelect,
    hasDuplicate,
    hasMove,
  } = props;

  const { mode } = useColorScheme();

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      const name = event.currentTarget.getAttribute('data-action') as ContextMenuActionsType | null;
      if (!name) {
        return;
      }
      onAction?.(event, name);
    },
    [onAction],
  );

  return (
    <Popover.Root open={open} onOpenChange={onOpenChange}>
      <Popover.Trigger asChild>
        <span
          style={{
            position: 'absolute',
            left: coords.x,
            top: coords.y,
            width: 0,
            height: 0,
          }}
        />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content side="right" align="start" sideOffset={5}>
          <MenuList
            variant="plain"
            sx={{
              boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)',
              border: '1px solid var(--joy-palette-background-level1)',
              userSelect: 'none',
              backgroundColor: mode === 'dark' ? 'color-mix(in srgb, var(--joy-palette-neutral-800) 50%, var(--joy-palette-neutral-900))' : undefined,
              minWidth: 136,
              '--ListItemDecorator-size': '1.5rem',
            }}
          >
            {hasCopilot && (
              <MenuItem data-action="Copilot" onClick={handleClick}>
                <ListItemDecorator>
                  <Icon name="microchip-ai" weight="light" sx={{ opacity: 0.5 }} />
                </ListItemDecorator>
                <span
                  style={{
                    fontSize: 13,
                  }}
                >
                  Copilot
                </span>
              </MenuItem>
            )}
            {(hasRename || hasEdit || hasDelete) && <Divider mx={0.75} />}
            {hasRename && (
              <MenuItem data-action="Rename" onClick={handleClick}>
                <ListItemDecorator>
                  <Icon name="edit" weight="light" sx={{ opacity: 0.5 }} />
                </ListItemDecorator>
                <span
                  style={{
                    fontSize: 13,
                  }}
                >
                  Rename
                </span>
              </MenuItem>
            )}
            {hasEdit && (
              <MenuItem data-action="Edit" onClick={handleClick}>
                <ListItemDecorator>
                  <Icon name="edit" weight="light" sx={{ opacity: 0.5 }} />
                </ListItemDecorator>
                <span
                  style={{
                    fontSize: 13,
                  }}
                >
                  Edit
                </span>
              </MenuItem>
            )}
            {hasPermissions && (
              <MenuItem data-action="Permissions" onClick={handleClick}>
                <ListItemDecorator>
                  <Icon name="key" weight="light" sx={{ opacity: 0.5 }} />
                </ListItemDecorator>
                <span
                  style={{
                    fontSize: 13,
                  }}
                >
                  Permissions
                </span>
              </MenuItem>
            )}
            {hasDelete && (
              <MenuItem color="danger" data-action="Delete" onClick={handleClick}>
                <ListItemDecorator>
                  <Icon name="trash" color="inherit" weight="light" />
                </ListItemDecorator>
                <span
                  style={{
                    fontSize: 13,
                  }}
                >
                  Delete
                </span>
              </MenuItem>
            )}
            {hasNew && <Divider mx={0.75} />}
            {hasNew && (
              <MenuItem data-action="New" onClick={handleClick}>
                <ListItemDecorator>
                  <Icon name="plus" weight="light" sx={{ opacity: 0.5 }} />
                </ListItemDecorator>
                <span
                  style={{
                    fontSize: 13,
                  }}
                >
                  New Folder
                </span>
              </MenuItem>
            )}
            {(hasPrivate || hasSelect || hasDuplicate || hasMove || hasShare) && <Divider mx={0.75} />}
            {hasPrivate && (
              <MenuItem data-action="TogglePrivate" onClick={handleClick} sx={{ '--ListItemDecorator-size': '2.5rem' }}>
                <ListItemDecorator>
                  <Switch color={mode === 'dark' ? 'neutral' : undefined} checked={isPrivate} sx={{ opacity: 0.5 }} />
                </ListItemDecorator>
                <span
                  style={{
                    fontSize: 13,
                  }}
                >
                  Private
                </span>
              </MenuItem>
            )}
            {hasSelect && (
              <MenuItem data-action="Select" onClick={handleClick}>
                <ListItemDecorator>
                  <Icon name="square-check" weight="light" sx={{ opacity: 0.5 }} />
                </ListItemDecorator>
                <span
                  style={{
                    fontSize: 13,
                  }}
                >
                  Select
                </span>
              </MenuItem>
            )}
            {hasDuplicate && (
              <MenuItem data-action="Duplicate" onClick={handleClick}>
                <ListItemDecorator>
                  <Icon name="copy" weight="light" sx={{ opacity: 0.5 }} />
                </ListItemDecorator>
                <span
                  style={{
                    fontSize: 13,
                  }}
                >
                  Duplicate
                </span>
              </MenuItem>
            )}
            {hasMove && (
              <MenuItem data-action="Move" onClick={handleClick}>
                <ListItemDecorator>
                  <Icon name="arrows-up-down-left-right" weight="light" sx={{ opacity: 0.5 }} />
                </ListItemDecorator>
                <span
                  style={{
                    fontSize: 13,
                  }}
                >
                  Move
                </span>
              </MenuItem>
            )}
            {hasShare && (
              <MenuItem data-action="Share" onClick={handleClick}>
                <ListItemDecorator>
                  <Icon name="share-nodes" weight="light" sx={{ opacity: 0.5 }} />
                </ListItemDecorator>
                <span
                  style={{
                    fontSize: 13,
                  }}
                >
                  Share
                </span>
              </MenuItem>
            )}
          </MenuList>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default memo(ContextMenu);
