import { Card, Typography, Box, type BoxProps } from '@mui/joy';
import React, { memo } from 'react';

type EmptyPanelProps = {
  text: string;
} & BoxProps;

const EmptyPanel = (props: EmptyPanelProps) => {
  const { text, ...rest } = props;

  return (
    <Box {...rest}>
      <Card
        color="neutral"
        sx={{
          background: 'var(--joy-palette-background-level1)',
          minHeight: 128,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 'var(--joy-radius-xl)',
        }}
      >
        <Typography fontSize={17} fontWeight="bolder">
          {text}
        </Typography>
      </Card>
    </Box>
  );
};

export default memo(EmptyPanel);
