import { type DefaultRootState } from 'react-redux';
import type { SpaceFolderType } from 'app/entities';

const folderById = (folderId: string | number | 'root') => (state: DefaultRootState) => {
  const data = state.spaceResource.data?.[folderId];
  if (!data || data.__typename !== 'Folder') {
    return null;
  }
  return state.spaceResource.data?.[folderId] as SpaceFolderType;
};

export default folderById;
