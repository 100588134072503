import { type ReactElement, Fragment, useCallback, memo } from 'react';
import { Box, Typography, useTheme } from '@mui/joy';
import { isEqual } from 'lodash';

import { useFocusEffect } from 'navigation/hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import { guard } from 'utils';

import TopBarDesktop from './elements/TopBarDesktop';
import CollectionsSwiper from './ui/CollectionsSwiper';
import UsersSwiper from './ui/UsersSwiper';

const Collections = (): ReactElement => {
  const dispatcher = useDispatcher();
  const theme = useTheme();

  const pool = useSelector((state) => state.community.collections.pool);

  useFocusEffect(
    useCallback(() => {
      setTimeout(() => {
        dispatcher.community.loadCollections();
      }, 300);
    }, []),
  );

  return (
    <>
      <TopBarDesktop />
      <Box
        sx={{
          '@media (max-width: 499px)': {
            mt: 2,
            mb: 6,
          },
        }}
      >
        {pool.map(({ title, payload }) => {
          if (!Array.isArray(payload) || payload.length === 0) {
            return null;
          }
          return (
            <Fragment key={title}>
              <Typography fontSize={22} fontWeight={500} mt={2} mb={1}>
                {title}
              </Typography>
              <Box
                sx={{
                  '--swiper-pagination-bullet-inactive-color': theme.palette.neutral.softColor,
                  '@media (max-width: 767px)': {
                    mx: -2,
                  },
                  '& .swiperCollection, & .swiperUser': {
                    '@media (max-width: 767px)': {
                      pl: 2,
                    },
                  },
                }}
              >
                {guard.isLinkToContentVector(payload) && <CollectionsSwiper items={payload} />}
                {guard.isPersonVector(payload) && <UsersSwiper items={payload} />}
              </Box>
            </Fragment>
          );
        })}
      </Box>
    </>
  );
};

export default memo(Collections, (prevProps, nextProps) => isEqual(prevProps, nextProps));
