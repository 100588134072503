import { memo, useCallback } from 'react';
import { Card, CardContent, Skeleton, Typography } from '@mui/joy';

import { guard } from 'utils';
import TextExpand from 'ui/TextExpand';

import useDocumentDescription from 'widgets/Card/model/useDocumentDescription';
import useLoadData from 'widgets/Card/model/useLoadData';
import useResourceData from 'hooks/useResourceData';

import openLink from '../model/openLink';

const SkeletonLayout = () => (
  <>
    <TextExpand
      slotsProps={{
        typography: {
          level: 'title-sm',
        },
      }}
      lines={3}
    >
      <Skeleton loading>0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0</Skeleton>
    </TextExpand>
    <TextExpand
      slotsProps={{
        typography: {
          level: 'body-xs',
          mt: 1,
        },
      }}
      lines={2}
    >
      <Skeleton loading>
        <Typography fontWeight={400} color="neutral">
          0 0 0 0 0
        </Typography>
      </Skeleton>
    </TextExpand>
  </>
);

const MaterialLayout = (props: { title: string; source: string }) => {
  const { title, source } = props;
  return (
    <>
      <TextExpand
        slotsProps={{
          typography: {
            level: 'title-sm',
          },
        }}
        lines={3}
      >
        {title}
      </TextExpand>
      <TextExpand
        slotsProps={{
          typography: {
            level: 'body-xs',
            mt: 1,
          },
        }}
        lines={2}
      >
        <Typography fontWeight={400} color="neutral">
          {source}
        </Typography>
      </TextExpand>
    </>
  );
};

const RagMaterialLayout = (props: { title: string; source: string }) => {
  const { title, source } = props;
  return (
    <>
      <TextExpand
        slotsProps={{
          typography: {
            level: 'title-sm',
          },
        }}
        lines={3}
      >
        {title}
      </TextExpand>
      <TextExpand
        slotsProps={{
          typography: {
            level: 'body-xs',
            mt: 1,
          },
        }}
        lines={2}
      >
        <Typography fontWeight={400} color="neutral">
          {source}
        </Typography>
      </TextExpand>
    </>
  );
};

const CollectionLayout = (props: { title: string; source: string }) => {
  const { title, source } = props;
  return (
    <>
      <TextExpand
        slotsProps={{
          typography: {
            level: 'title-sm',
          },
        }}
        lines={3}
      >
        {title}
      </TextExpand>
      <TextExpand
        slotsProps={{
          typography: {
            level: 'body-xs',
            mt: 1,
          },
        }}
        lines={2}
      >
        <Typography fontWeight={400} color="neutral">
          {source}
        </Typography>
      </TextExpand>
    </>
  );
};

const NoteLayout = (props: { text: string; source: string }) => {
  const { text, source } = props;
  const { title, paragraph, hasContent } = useDocumentDescription((text || '').trim());
  return (
    <>
      <TextExpand
        slotsProps={{
          typography: {
            level: 'title-sm',
          },
        }}
        lines={4}
      >
        {title || paragraph}
      </TextExpand>
      <TextExpand
        slotsProps={{
          typography: {
            level: 'body-xs',
            mt: 1,
          },
        }}
        lines={2}
      >
        <Typography fontWeight={400} color="neutral">
          {source}
        </Typography>
      </TextExpand>
    </>
  );
};

const DocumentLayout = (props: { text: string; source: string }) => {
  const { text, source } = props;
  const { title, paragraph, hasContent } = useDocumentDescription((text || '').trim());
  return (
    <>
      <TextExpand
        slotsProps={{
          typography: {
            level: 'title-sm',
          },
        }}
        lines={4}
      >
        {title || paragraph}
      </TextExpand>
      <TextExpand
        slotsProps={{
          typography: {
            level: 'body-xs',
            mt: 1,
          },
        }}
        lines={2}
      >
        <Typography fontWeight={400} color="neutral">
          {source}
        </Typography>
      </TextExpand>
    </>
  );
};

type SourceCardProps = {
  type: 'Material' | 'Collection' | 'Note' | 'RagMaterial';
  id: number | string;
};

const SourceCard = (props: SourceCardProps) => {
  const { type, id } = props;

  useLoadData(id, type);
  const data = useResourceData(id, type);

  const handleClick = useCallback(() => {
    if (!data?.id) {
      return;
    }
    if ((guard.isMaterial(data) || guard.isCollection(data) || guard.isNote(data) || guard.isDocument(data)) && data.internalUrl) {
      openLink(data.internalUrl, '_blank');
    }
  }, [data]);

  return (
    <Card
      onClick={handleClick}
      size="sm"
      variant="outlined"
      sx={{
        width: 128,
        background: 'transparent',
        cursor: !data ? 'not-allowed' : 'pointer',
        transition: '300ms background-color ease',
        '&:hover': {
          backgroundColor: (theme) => `var(--source-card-softHoverBg, ${theme.palette.background.level1})`,
        },
        '&:active': {
          transition: 'none',
          backgroundColor: (theme) => `var(--source-card-softActiveBg, ${theme.palette.background.level2})`,
        },
        borderRadius: (theme) => theme.radius.md,
        '--joy-lineHeight-md': 1,
      }}
    >
      <CardContent sx={{ justifyContent: 'space-between' }}>
        {!data && <SkeletonLayout />}
        {guard.isMaterial(data) && <MaterialLayout title={data.title || ''} source={data.source?.name || data.creator?.name || ''} />}
        {guard.isRagMaterial(data) && <RagMaterialLayout title={data.title} source={data.originalUrl} />}
        {guard.isCollection(data) && <CollectionLayout title={data.title} source={data.user?.name || ''} />}
        {guard.isNote(data) && <NoteLayout text={data.previewText || data.text} source={data.user.name} />}
        {guard.isDocument(data) && <DocumentLayout text={data.previewText || data.text} source={data.user.name} />}
      </CardContent>
    </Card>
  );
};

export default memo(SourceCard);
