import { type ChangeEvent, memo, useCallback, type MouseEvent } from 'react';
import { Box, type BoxProps, Button, Radio, RadioGroup, ToggleButtonGroup } from '@mui/joy';

import { usePerson, useSearchMethod } from 'hooks';

import { useSelector } from 'store/utils/redux/hooks';
import * as copilotStore from 'widgets/Copilot/store';

const SearchMethods = (props: BoxProps) => {
  const { sx, ...rest } = props;

  const isAnswerTyping = useSelector(
    copilotStore.selectors.isAnswerTyping([{ type: 'web' }, { type: 'library-fulltext' }, { type: 'global-fulltext' }]),
  );

  const { scope, type, setScope, setType } = useSearchMethod();

  const { person } = usePerson('User', 'my');

  const handleScopeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setScope(event.target.value as 'Web' | 'Library' | 'Global');
    },
    [setScope, setType],
  );

  const handleScopeMobileChange = useCallback(
    (event: MouseEvent<HTMLElement>, value: 'Web' | 'Global' | 'Library' | null) => {
      if (!value) {
        return;
      }
      setScope(value);
    },
    [setScope, setType],
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={6}
      sx={{
        '@media (max-width: 599px)': {
          gap: 0.75,
        },
        ...sx,
      }}
      {...rest}
    >
      <Box display="flex" flexDirection="row" alignSelf="center" gap={2}>
        <ToggleButtonGroup
          defaultValue="Web"
          variant="soft"
          size="sm"
          sx={{
            '--Button-minHeight': '24px',
            '--joy-fontSize-sm': '12px',
            '--joy-fontWeight-lg': 400,
            '--ButtonGroup-radius': '40px',
            '@media (min-width: 600px)': {
              display: 'none',
            },
          }}
          onChange={handleScopeMobileChange}
          value={scope}
          orientation="horizontal"
        >
          <Button disabled={isAnswerTyping} value="Web">
            Web
          </Button>
          <Button disabled={!person || isAnswerTyping} value="Library">
            Library
          </Button>
          <Button disabled={isAnswerTyping} value="Global">
            IKI
          </Button>
        </ToggleButtonGroup>
        <RadioGroup
          orientation="horizontal"
          defaultValue="Web"
          value={scope}
          sx={{
            '@media (max-width: 599px)': {
              display: 'none',
            },
          }}
          onChange={handleScopeChange}
        >
          <Radio disabled={isAnswerTyping} label="Web" color="neutral" value="Web" />
          <Radio disabled={!person || isAnswerTyping} label="Library" color="neutral" value="Library" />
          <Radio disabled={isAnswerTyping} label="IKI" color="neutral" value="Global" />
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default memo(SearchMethods);
