import { memo, useCallback } from 'react';
import { StyleSheet } from 'react-native';

import { usePerson } from 'hooks';
import { dispatcher } from 'store/utils/redux/hooks';

import Icon from 'ui/Icon';

import MenuButton from 'components/UIBar/elements/MenuButton';

type ThemeSwitcherProps = {
  isSlim?: boolean;
};

const Settings = (props: ThemeSwitcherProps) => {
  const { isSlim } = props;

  const { person } = usePerson('User', 'my');

  const handleSetting = useCallback(() => {
    dispatcher.modal.open('Profile');
  }, []);

  if (!person) {
    return null;
  }

  return (
    <>
      <MenuButton
        style={styles.colorSchemeItem}
        onPress={handleSetting}
        text="Settings"
        isSlim={isSlim}
        isSelected={false}
        revertSlim
        iconRight={<Icon name="gear" fw size="lg" weight="light" color="icon" />}
      />
    </>
  );
};

const styles = StyleSheet.create({
  colorSchemeItem: {
    justifyContent: 'space-between',
  },
});

export default memo(Settings);
