import type { SpaceUserActionType, SpaceUserRoleType, SpaceUserPermissionType } from 'app/entities';

export interface SourceUserPermissionType {
  teamSpaceId: number;
  permissionId: number;
  userId: number;
  user: {
    id: number;
    createdAt: string;
    updatedAt: string;
  };
  permission: SpaceUserRoleType;
  action: SpaceUserActionType[];
}

function convertEntity(source: SourceUserPermissionType): SpaceUserPermissionType {
  return {
    id: source.permissionId,
    spaceId: source.teamSpaceId,
    user: source.user,
    permission: source.permission,
    action: source.action,
  } as SpaceUserPermissionType;
}

function sourceUserPermissionToSpaceUserPermission<T extends SourceUserPermissionType | SourceUserPermissionType[]>(
  data: T,
): T extends SourceUserPermissionType[] ? SpaceUserPermissionType[] : SpaceUserPermissionType {
  if (Array.isArray(data)) {
    return data.map((item) => convertEntity(item)) as T extends SourceUserPermissionType[] ? SpaceUserPermissionType[] : SpaceUserPermissionType;
  }
  return convertEntity(data) as T extends SourceUserPermissionType[] ? SpaceUserPermissionType[] : SpaceUserPermissionType;
}

export default sourceUserPermissionToSpaceUserPermission;
