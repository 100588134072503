import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Modal, ModalClose, ModalDialog, Typography, Divider } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';

import type { SpaceType } from 'app/entities';
import * as modalStore from 'widgets/modals/store';

import useSpaceState from './model/useSpaceState';

import DataLayout from './layout/DataLayout';
import PermissionLayout from './layout/PermissionLayout';

import Steps, { type ChangeHandler } from './ui/Steps';

export interface CreateParams {
  mode: 'create';
  processing: boolean;
  step: 'data' | 'permission';
  data?: SpaceType;
}

export interface EditParams {
  mode: 'edit';
  processing: boolean;
  spaceId: number;
}

export type ManageDataParams = CreateParams | EditParams;

const ManageData = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(
    modalStore.selectors.state('SpaceManageData', {
      processing: false,
      step: 'data',
    }),
  );

  const { data, change, create, update } = useSpaceState(params.mode === 'edit' ? params.spaceId : undefined);

  const handleStepChange = useCallback<ChangeHandler>((event, newStep) => {
    dispatcher.modal.update('SpaceManageData', {
      step: newStep,
    });
  }, []);

  const handleClose = useCallback(() => {
    dispatcher.modal.close('SpaceManageData');
  }, []);

  const handleSpaceUpdate = useCallback(() => {
    update?.();
    handleClose();
  }, [update, handleClose]);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalDialog
        variant="soft"
        color="neutral"
        size="lg"
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: 'min(540px, calc(100% - 32px))',
          maxWidth: 'min(670px, calc(100% - 32px))',
          borderRadius: '1.25rem',
          '--Card-padding': '2.5rem',
          '--FormLabel-color': '--joy-palette-neutral-plainDisabledColor',
          '--variant-solidDisabledBg': 'var(--joy-palette-background-level2)',
        }}
      >
        <ModalClose onClick={handleClose} size="sm" sx={{ borderRadius: '1rem', m: 2 }} />
        {params?.mode === 'edit' && (
          <DataLayout
            header={
              <>
                <Typography fontSize={34} fontWeight={400}>
                  Edit Space
                </Typography>
              </>
            }
            data={data}
            processing={params.processing}
            submitLabel="Save"
            onChange={change}
            onSubmit={handleSpaceUpdate}
            onCancel={handleClose}
          />
        )}
        {params?.mode === 'create' && params?.step === 'data' && (
          <DataLayout
            header={
              <>
                <Typography fontSize={34} fontWeight={400}>
                  Create a New Space
                </Typography>
              </>
            }
            data={data}
            processing={params.processing}
            submitLabel="Continue"
            onChange={change}
            onSubmit={create}
            onCancel={handleClose}
          />
        )}
        {params?.mode === 'create' && params?.step === 'permission' && params.data && (
          <PermissionLayout
            header={
              <Box display="flex" flexDirection="column" gap={1} flex={1} pb={1}>
                <Typography fontSize={17} fontWeight={500}>
                  {params.data?.title}
                </Typography>
                <Steps value={params.step} onChange={handleStepChange} alignSelf="flex-end" />
                <Divider sx={{ mt: 0.5 }} />
              </Box>
            }
            spaceId={params.data.id}
            onCancel={handleClose}
          />
        )}
      </ModalDialog>
    </Modal>
  );
};

export default memo(ManageData);
