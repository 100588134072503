import { type PropsWithChildren, type ReactElement, memo, useCallback, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Box, Typography } from '@mui/joy';
import { type FontSizeVariant } from 'font';

type DescriptionProps = {
  size?: FontSizeVariant;
};

const Description = (props: PropsWithChildren<DescriptionProps>): ReactElement | null => {
  const { children, size = 17 } = props;

  const [isOpen, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    if (isOpen) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isOpen]);

  if (!children || typeof children !== 'string') {
    return null;
  }

  const crop = 400;

  if (children.length < crop) {
    return (
      <Box display="flex" flexDirection="column">
        <Typography fontSize={size} mt={1.5}>
          {children}
        </Typography>
      </Box>
    );
  }

  const dotPosition = children.indexOf('.', crop);

  return (
    <Box display="flex" flexDirection="column">
      <Typography fontSize={size} mt={1.5}>
        {!isOpen && `${children.substring(0, dotPosition)}...`}
        {isOpen && children}{' '}
        <TouchableOpacity onPress={handleToggle} activeOpacity={0.8}>
          <Typography fontSize={17} fontWeight="bold" color="primary">
            {!isOpen ? 'show more' : 'show less'}
          </Typography>
        </TouchableOpacity>
      </Typography>
    </Box>
  );
};

export default memo(Description);
