import { takeEvery, call, put, cancel, all } from 'redux-saga/effects';
import type { SpaceType } from 'app/entities';

import network from 'lib/network';
import { select } from 'store/utils/saga/effects';

import { actions as structureActions } from '../slices/structure';
import { actions as resourceActions } from '../slices/resource';

import spaceById from '../selectors/spaceById';

import sourceEntityToSpaceEntity, { type SourceEntityType } from '../../model/sourceEntityToSpaceEntity';

export const config = {
  action: structureActions.loadFull.type,
  method: takeEvery,
};

/**
 * Recursively loads the entire folder tree starting from `folderId`,
 * dispatches each loaded set of entities to the store, and triggers
 * parallel loading for each child folder.
 *
 * @generator
 * @param {SpaceType} space - The space object containing basic info (id, libraryId, etc.).
 * @param {string} folderId - The identifier of the folder to load.
 * @param {string[]} [ignore] - An optional list of folder IDs to skip loading (e.g., libraryId).
 * @yields Puts the loaded entities into the Redux store via `resourceActions.setPage`.
 * @returns {Generator<any, void, any>} A generator that can be used by the saga middleware.
 */
export function* loadFolderChildren(space: SpaceType, folderId: string, ignore?: string[]): Generator<any, void, any> {
  const spaceId = space.id;

  const response: {
    data: SourceEntityType[] | null;
    hasError: boolean;
    errors: Error[] | null;
  } = yield call(() =>
    network.request<SourceEntityType[]>(`/stack-2/user/team-space/${spaceId}/${folderId}/structure`).query({ pageSize: 100 }).get(),
  );

  if (response.hasError || !response.data) {
    // If there's an error or no data, simply exit for this folder
    return;
  }

  // Convert the loaded items into the store-friendly format
  const entities = sourceEntityToSpaceEntity(space.libraryId, response.data);

  // Dispatch the loaded entities to the store
  yield put(
    resourceActions.setPage({
      data: entities,
      treeName: 'structure',
      mode: 'first',
    }),
  );

  // Determine which child folders need loading
  const childFolderIds = response.data.filter((item) => Boolean(item.hasFolder) && !ignore?.includes(String(item.id))).map((item) => String(item.id));

  // Load all child folders in parallel
  yield all(childFolderIds.map((id) => call(loadFolderChildren, space, id, ignore)));
}

/**
 * The main saga entry point for loading the entire structure (full tree)
 * starting from a given folder. It checks if the space exists; if not,
 * it cancels. Otherwise, it calls `loadFolderChildren` recursively and
 * finally dispatches the `loadFullDone` action.
 *
 * @generator
 * @param {ReturnType<typeof structureActions.loadFull>} action - The action payload containing `spaceId` and `folderId`.
 * @yields Calls `loadFolderChildren` recursively to load all nested folders.
 * @returns {Generator<any, void, any>} A generator consumed by the saga middleware.
 */
export function* func(action: ReturnType<typeof structureActions.loadFull>) {
  const { spaceId, folderId } = action.payload;
  const space: SpaceType | null = yield* select(spaceById(spaceId));

  if (!space) {
    yield put(structureActions.loadFullDone({ spaceId, folderId }));
    yield cancel();
    return;
  }

  // Recursively load the full folder tree
  yield call(loadFolderChildren, space, folderId, [space.libraryId]);

  // Signal that the full structure loading is done
  yield put(structureActions.loadFullDone({ spaceId, folderId }));
}
