import { useHasSession, useIsContentError, useContentIdFromUrl, usePerson } from 'hooks';

import AdaptivePage from 'components/AdaptivePage';
import AddNoteButton from 'components/AddNoteButton';
import PlusButton from 'components/PlusButton';
import { CopilotUtilityBar, CopilotShowToggle, CopilotModelSelect } from 'widgets/Copilot';

import { Box } from '@mui/joy';
import IndexingStatus from './components/IndexingStatus';
import SourceButton from './elements/SourceButton';
import ActionsBar from './elements/ActionsBar';

import LayoutDefault from './layouts/LayoutDefault';

const Content = () => {
  const hasSession = useHasSession();

  const { person } = usePerson('User', 'my');

  const isContentError = useIsContentError('Material', useContentIdFromUrl('Material'));

  if (hasSession && !person) {
    return null;
  }

  return (
    <AdaptivePage
      desktopHeaderLeftPanel={<ActionsBar />}
      desktopHeaderMiddlePanel={
        <Box display="flex" flexDirection="row" alignItems="center" gap={2} alignSelf="flex-end">
          <IndexingStatus />
          <CopilotShowToggle alignSelf="flex-end" mr={-1} hidden={isContentError === true} />
        </Box>
      }
      desktopHeaderRightPanel={
        <CopilotUtilityBar
          pl={1}
          left={
            <CopilotUtilityBar.Item canHidden hidden={isContentError === true}>
              <CopilotModelSelect ml={2} />
            </CopilotUtilityBar.Item>
          }
          right={
            <>
              <SourceButton />
              <AddNoteButton />
              <PlusButton />
            </>
          }
        />
      }
      bodyMaxWidth={1280}
      paddingHorizontalScheme={{ 320: 16 }}
      scrollToStart
    >
      <LayoutDefault />
    </AdaptivePage>
  );
};

export default Content;
