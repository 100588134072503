import { type DefaultRootState } from 'react-redux';
import { takeEvery, put } from 'redux-saga/effects';

import { select, call } from 'store/utils/saga/effects';
import network from 'lib/network';

import { actions as resourceActions } from '../slices/resource';
import libraryIdBySpaceId from '../selectors/libraryIdBySpaceId';
import sourceEntityToSpaceEntity, { type SourceEntityType } from '../../model/sourceEntityToSpaceEntity';

export const config = {
  action: resourceActions.confirmCreateFolder.type,
  method: takeEvery,
};

export function* func(action: ReturnType<typeof resourceActions.confirmCreateFolder>) {
  const { markerId, title } = action.payload;

  const mewFolderData = yield* select((state: DefaultRootState) => state.spaceResource?.data[markerId]);
  if (!markerId || !mewFolderData) {
    return;
  }

  const { spaceId, parentId } = mewFolderData;
  const libraryId = (yield* select(libraryIdBySpaceId(spaceId))) as string;

  try {
    const body = {
      title,
      description: null,
      parentId: parentId !== 'root' ? parentId : null,
      teamSpaceId: spaceId,
      type: 'FOLDER',
      position: null,
      cover: null,
      isPrivate: true,
    };
    const response = yield* call(() => network.request<SourceEntityType>('/stack-2/user/team-space/folder').body(body).post());

    if (response.hasError || !response.data) {
      // handle error
      return;
    }

    const data = sourceEntityToSpaceEntity(libraryId, response.data);

    yield put(resourceActions.createFolderDone({ markerId, data }));
  } catch (error) {
    console.error(error);
  }
}
