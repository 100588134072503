import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, mergeWith } from 'lodash';

import type { SpaceType } from 'app/entities';

import type { SpaceListStore } from '../types';

const initialState: SpaceListStore = {
  data: null,
  hidden: [],
  isLoading: false,
};

const spaceListSlice = createSlice({
  name: 'space/list',
  initialState,
  reducers: {
    reset: (state) => {
      const resetState = cloneDeep(initialState);
      state.data = cloneDeep(initialState.data);
      state.isLoading = cloneDeep(initialState.isLoading);
    },
    create: (
      state,
      action: PayloadAction<{
        data: Pick<SpaceType, 'title'> & Partial<Pick<SpaceType, 'description' | 'instruction'>>;
      }>,
    ) => {
      const { data } = action.payload;
    },
    createDone: (state, action: PayloadAction<{ data: SpaceType }>) => undefined,
    autoSave: (
      state,
      action: PayloadAction<{
        data: Pick<SpaceType, 'title'> & Partial<Pick<SpaceType, 'description' | 'instruction' | 'id'>>;
      }>,
    ) => undefined,
    update: (
      state,
      action: PayloadAction<{
        id: SpaceType['id'];
        data: Partial<Pick<SpaceType, 'title' | 'description' | 'cover' | 'instruction' | 'isPrivate'>>;
      }>,
    ) => undefined,
    updateDone: (
      state,
      action: PayloadAction<{
        id: SpaceType['id'];
      }>,
    ) => undefined,
    load: (state) => {
      state.isLoading = true;
    },
    loadDone: (state) => {
      state.isLoading = false;
    },
    set: (
      state,
      action: PayloadAction<{ data: SpaceType[]; mode?: 'reset' } | { data: Pick<SpaceType, 'id'> & Partial<Omit<SpaceType, 'id'>>; mode: 'extend' }>,
    ) => {
      const { payload } = action;
      if (!state.data) {
        state.data = {};
      }
      if (!payload.mode || payload.mode === 'reset') {
        state.data = {};
        payload.data.forEach((space) => {
          state.data![space.id] = space;
        });
      }
      if (payload.mode === 'extend') {
        const { id } = payload.data;
        state.data![id] = mergeWith({}, state.data![id], payload.data, (objVal, srcVal) => {
          if (srcVal === undefined) {
            return objVal;
          }
          return undefined;
        });
      }
    },
    delete: (state, action: PayloadAction<{ id: number }>) => undefined,
    deleteDone: (state, action: PayloadAction<{ id: number }>) => undefined,
    hide: (state, action: PayloadAction<{ id: number }>) => {
      state.hidden.push(action.payload.id);
    },
    show: (state, action: PayloadAction<{ id: number }>) => {
      const index = state.hidden.findIndex((id) => id === action.payload.id);
      if (index > -1) {
        state.hidden.splice(index, 1);
      }
    },
    remove: (state, action: PayloadAction<{ id: number }>) => {
      if (!state.data) {
        return;
      }
      delete state.data[action.payload.id];
      const index = state.hidden.findIndex((id) => id === action.payload.id);
      if (index > -1) {
        state.hidden.splice(index, 1);
      }
    },
  },
});

export const { reducer, actions } = spaceListSlice;
