import Subscription from 'widgets/Subscription/modals/Subscription';
import Profile from 'widgets/Profile/modals/Profile';

import SpacePermission from 'screens/Space/modals/Permission';
import SpaceResourcesToFolder from 'screens/Space/modals/ResourcesToFolder';
import SpaceManageData from 'screens/Space/modals/ManageData';

import StripeElementsWrapper from 'components/StripeElementsWrapper';
import PlusBottomSheet from './components/PlusBottomSheet';
import EditMaterial from './components/EditMaterial';
import AddToCollection from './components/AddToCollection';
import AddOrEditCollection from './components/AddorEditCollection';
import SearchUser from './components/SearchUser';
import ProductVideo from './components/ProductVideo';
import AppSumoMatch from './components/AppSumoMatch';
import YoutubeAuth from './components/YoutubeAuth';
import BFBanner from './components/BFBanner';

const Modals = () => (
  <>
    <PlusBottomSheet />
    <EditMaterial />
    <AddOrEditCollection />
    <AddToCollection />
    <SearchUser />
    <ProductVideo />
    <AppSumoMatch />
    <YoutubeAuth />
    <Subscription />
    <BFBanner />
    <Profile />
    <SpacePermission />
    <SpaceResourcesToFolder />
    <SpaceManageData />
  </>
);

export default Modals;
