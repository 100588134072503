import connect from 'store/utils/saga/connect';
import * as init from './sagas/init';
import * as handleRoute from './sagas/handleRoute';
import * as loadList from './sagas/loadList';
import * as loadAvailablePermissions from './sagas/loadAvailablePermissions';
import * as loadUserPermissions from './sagas/loadUserPermissions';
import * as loadFullStructure from './sagas/loadFullStructure';
import * as loadStructure from './sagas/loadStructure';
import * as loadResources from './sagas/loadResources';
import * as createSpace from './sagas/createSpace';
import * as autoSave from './sagas/autoSave';
import * as updateSpace from './sagas/updateSpace';
import * as deleteSpace from './sagas/deleteSpace';
import * as createPermission from './sagas/createPermission';
import * as updatePermission from './sagas/updatePermission';
import * as deletePermission from './sagas/deletePermission';
import * as updatePosition from './sagas/updatePosition';
import * as removeResource from './sagas/removeResource';
import * as moveToFolder from './sagas/moveToFolder';
import * as confirmCreateFolder from './sagas/confirmCreateFolder';
import * as updateFolder from './sagas/updateFolder';

export default connect([
  init,
  handleRoute,
  loadList,
  loadAvailablePermissions,
  loadUserPermissions,
  loadFullStructure,
  loadStructure,
  loadResources,
  createSpace,
  autoSave,
  updateSpace,
  deleteSpace,
  createPermission,
  updatePermission,
  deletePermission,
  updatePosition,
  removeResource,
  moveToFolder,
  confirmCreateFolder,
  updateFolder,
]);
