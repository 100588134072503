import { type FC, type MouseEvent, memo, useCallback } from 'react';
import { Box, type BoxProps, Button } from '@mui/joy';
import Icon from 'ui/Icon';

export interface ChangeHandler {
  (event: MouseEvent<HTMLButtonElement>, value: 'data' | 'permission'): void;
}

interface StepsProps extends Omit<BoxProps, 'onChange'> {
  value: 'data' | 'permission';
  onChange: ChangeHandler;
}

const Steps: FC<StepsProps> = (props) => {
  const { value, onChange, ...rest } = props;

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const id = event.currentTarget.getAttribute('itemID') as 'data' | 'permission';
      onChange?.(event, id);
    },
    [onChange],
  );

  return (
    <Box display="flex" gap={1} {...rest}>
      <Button
        itemID="data"
        variant={value === 'data' ? 'solid' : 'outlined'}
        startDecorator={<Icon name="circle-1" weight="solid" color="inherit" fw size="lg" ml={-0.625} />}
        color="neutral"
        size="sm"
        onClick={handleClick}
      >
        Define the Scope
      </Button>
      <Button
        itemID="permission"
        variant={value === 'permission' ? 'solid' : 'outlined'}
        startDecorator={<Icon name="circle-2" weight="solid" color="inherit" fw size="lg" ml={-0.625} />}
        color="neutral"
        size="sm"
        onClick={handleClick}
      >
        Visibility
      </Button>
    </Box>
  );
};

export default memo(Steps);
