import { memo, type PropsWithChildren, type ReactElement, useCallback, useMemo } from 'react';
import { StyleSheet, TouchableOpacity, type ViewStyle, type StyleProp } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { Box, Typography } from '@mui/joy';

import type { ImageType, ImageInput } from 'app/entities';

import { unit } from 'utils';
import { View } from 'components/Themed';
import Avatar from 'components/Avatar';

import { useField } from '../utils/context';

import type { FieldProps } from '../types';

const getImageUrl = (value?: string | ImageType | ImageInput | any): string | null => {
  if (typeof value === 'object' && !!value && 'url' in value) {
    return value.url;
  }
  if (typeof value === 'object' && !!value && 'data' in value) {
    return value.data;
  }
  if (typeof value === 'string') {
    return value;
  }
  return null;
};

type PhotoInputProps = {
  previewSize?: number;
  alt?: string;
  style?: StyleProp<ViewStyle>;
} & FieldProps;

const PhotoInput = (props: PropsWithChildren<PhotoInputProps>): ReactElement => {
  const { name, alt, label, value, onChange, previewSize = 80, style, children } = props;

  const field = useField(name);

  const onChangeFinal = onChange || field?.onValueChange;
  const valueFinal = getImageUrl(value || field?.value) || '';

  const handlePick = useCallback(async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    const { canceled, assets } = result;
    if (canceled || !Array.isArray(assets) || assets.length === 0) {
      return;
    }

    const [{ uri }] = assets;
    if (!uri) {
      return;
    }

    onChangeFinal?.(uri);
  }, [onChangeFinal]);

  const stylesPreview: ViewStyle = {};
  if (previewSize) {
    stylesPreview.width = unit(previewSize);
    stylesPreview.height = unit(previewSize);
    stylesPreview.borderRadius = unit(previewSize / 2);
  }

  const containerStyles = useMemo(() => {
    let result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.container),
      marginTop: field ? unit(16) : undefined,
    };
    if (style) {
      result = {
        ...result,
        ...StyleSheet.flatten(style),
      };
    }
    return result;
  }, [field, style]);
  return (
    <View style={containerStyles}>
      <TouchableOpacity style={styles.touch} onPress={handlePick} activeOpacity={0.8}>
        <Avatar vars={{ AvatarSize: `${previewSize}px`, Background: 2 }} src={valueFinal} name={alt} />
        {label && (
          <Box ml={2}>
            <Typography fontSize={15} fontWeight={400}>
              {label}
            </Typography>
          </Box>
        )}
        {children && <View style={styles.children}>{children}</View>}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  preview: {
    width: unit(80),
    height: unit(80),
    borderRadius: unit(40),
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  touch: {
    position: 'relative',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  children: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  itemText: {
    marginLeft: unit(16),
  },
});

export default memo(PhotoInput);
