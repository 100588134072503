import { type FC, memo, useCallback, useEffect } from 'react';

import List from 'components/List';
import type { SpaceEntityKey } from 'app/entities';

import useIsFolderLoading from '../model/useIsFolderLoading';
import useFolderHasNext from '../model/useFolderHasNext';
import useFolderChildren from '../model/useFolderChildren';
import useFolderNextPage from '../model/useFolderNextPage';
import ItemRender from '../elements/Item';

interface EntitiesProps {
  spaceId?: number;
  folderId?: string;
}

const Entities: FC<EntitiesProps> = (props) => {
  const { spaceId = 0, folderId = 'root' } = props;

  const isLoading = useIsFolderLoading(spaceId, folderId);
  const hasNext = useFolderHasNext(spaceId, folderId);
  const list = useFolderChildren(spaceId, folderId);
  const { handler: nextPageHandler } = useFolderNextPage(spaceId, folderId);

  const getKey = useCallback((item: SpaceEntityKey) => {
    if (!item || typeof item !== 'object') {
      return 'unknown';
    }
    return `${item.__typename}-${item.id}`;
  }, []);

  return (
    <List
      items={list}
      getKey={getKey}
      renderItem={ItemRender}
      columnsScheme="320:1;528:2;672:3;960:4"
      loading={isLoading}
      hasNextPage={!isLoading && hasNext}
      onEndReached={nextPageHandler}
    />
  );
};

export default memo(Entities);
