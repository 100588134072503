import { memo, useCallback, useState, useRef, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Box, CircularProgress, Button } from '@mui/joy';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Virtuoso, type VirtuosoHandle } from 'react-virtuoso';
import type { PDFPageProxy, PageViewport } from 'pdfjs-dist';
import { type DocumentCallback } from 'react-pdf/dist/esm/shared/types';

import { openInNewTab } from 'utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type PDFViewerProps = {
  src?: string;
  limit?: number;
};

const PDFViewer = memo((props: PDFViewerProps) => {
  const { src, limit } = props;
  const classes = useStyles();

  const [numPages, setNumPages] = useState<number>(0);
  const [availableWidth, setAvailableWidth] = useState<number>(0);
  const [pageViewportsMap, setPageViewportsMap] = useState<Record<number, { ratio: number }>>({});
  const [pageLoadingStates, setPageLoadingStates] = useState<Record<number, boolean>>({});

  const [isPageLoadingError, setIsPageLoadingError] = useState<Error | null>(null);
  const [isCut, setIsCut] = useState<boolean>(false);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [source, setSource] = useState<string | undefined>(undefined);

  useEffect(() => {
    const className = 'hiddenCanvasClass';
    if (!document.getElementById(className)) {
      const style = document.createElement('style');
      style.id = className;
      style.textContent = `
        .hiddenCanvasElement {
          display: none !important;
        }
      `;
      document.head.appendChild(style);
    }
  }, []);

  useEffect(() => {
    setSource(src);
  }, [src]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        const newWidth = entries[0].contentRect.width;
        setAvailableWidth(newWidth);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const onItemClick = useCallback(
    ({ pageNumber }: { pageNumber: number }) => {
      if (virtuosoRef.current) {
        virtuosoRef.current.scrollToIndex({ index: pageNumber });
      }
    },
    [virtuosoRef],
  );

  const handleOpenFull = useCallback(() => {
    if (!source) {
      return;
    }
    openInNewTab(source);
  }, [source]);

  const handleDocumentLoadSuccess = useCallback(
    async (document: DocumentCallback) => {
      if (!availableWidth) {
        return;
      }
      let pages = document.numPages;
      if (limit && document.numPages > limit) {
        pages = limit;
        setIsCut(true);
      }
      setNumPages(pages);

      const updatePageViewportsMap: Record<number, { ratio: number }> = {};
      const updatePageLoadingStates: Record<number, boolean> = {};
      const viewports = await Promise.all(
        new Array(pages).fill(null).map((_, key) => document.getPage(key + 1).then((page: PDFPageProxy) => page.getViewport({ scale: 1 }))),
      );
      viewports.forEach((viewport: PageViewport, key: number) => {
        const { width, height } = viewport;
        const ratio = width / height;
        const pageIndex = key + 1;
        updatePageViewportsMap[pageIndex] = { ratio };
        updatePageLoadingStates[pageIndex] = true;
      });
      setPageViewportsMap(updatePageViewportsMap);
      setPageLoadingStates(updatePageLoadingStates);

      setIsPageLoadingError(null);
    },
    [availableWidth, limit],
  );

  const handleDocumentLoadError = useCallback(
    (error: Error) => {
      // КОСТЫЛЬ ДЛЯ arxiv.org ПЕРЕАДРЕСАЦИИ
      if (source && /arxiv\.org.*?\.pdf/i.test(source)) {
        setSource(source.replace(/\.pdf/i, ''));
        return;
      }
      setIsPageLoadingError(error);
    },
    [source],
  );

  const itemContent = useCallback(
    (index: number) => {
      const pageIndex = index;
      const pageViewport = pageViewportsMap[pageIndex];
      const isLoading = pageLoadingStates[pageIndex];

      const handleAnnotationRenderSuccess = () => {
        setPageLoadingStates((prevState) => ({
          ...prevState,
          [pageIndex]: false,
        }));
      };

      return (
        <Box
          key={`page_${pageIndex}`}
          className={classes.pageContainer}
          style={{
            aspectRatio: pageViewport?.ratio || undefined,
          }}
        >
          {isLoading && !isPageLoadingError && (
            <Box className={classes.loadingOverlay}>
              <CircularProgress />
            </Box>
          )}
          <Page
            pageIndex={pageIndex}
            width={availableWidth}
            renderMode="canvas"
            onRenderTextLayerError={(error) => console.log(`Error while rendering text layer! ${error.message}`)}
            devicePixelRatio={Math.min(2, window.devicePixelRatio)}
            onRenderAnnotationLayerSuccess={handleAnnotationRenderSuccess}
          />
        </Box>
      );
    },
    [pageViewportsMap, availableWidth, pageLoadingStates, classes.pageContainer, classes.loadingOverlay, isPageLoadingError],
  );

  return (
    <Box ref={containerRef} display="flex" flexDirection="column">
      <Document
        file={source}
        onLoadSuccess={handleDocumentLoadSuccess}
        onLoadError={handleDocumentLoadError}
        onItemClick={onItemClick}
        className={classes.pdfDocument}
      >
        <Virtuoso useWindowScroll totalCount={numPages} ref={virtuosoRef} itemContent={itemContent} />
      </Document>
      {isCut && (
        <Button onClick={handleOpenFull} variant="outlined" color="primary" sx={{ mt: 6, alignSelf: 'center' }}>
          Open full document
        </Button>
      )}
    </Box>
  );
});

const useStyles = createUseStyles({
  pdfDocument: {},
  pageContainer: {
    position: 'relative',
    marginBottom: 16,
    boxShadow: '0 0 2px rgba(0, 0, 0, 0.25), 0 2px 5px rgba(0, 0, 0, 0.15)',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
});

export default memo(PDFViewer);
