import { put, call, takeLatest, cancel } from 'redux-saga/effects';

import type { SpaceType } from 'app/entities';
import network from 'lib/network';

import { select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import { actions as listActions } from '../slices/list';
import { actions as permissionActions } from '../slices/permission';

export const config = {
  action: listActions.load.type,
  method: takeLatest,
};

export function* func() {
  const user = yield* select(userStore.selectors.getMy);

  const response: { data: SpaceType[] | null; hasError: boolean; errors: Error[] | null } = yield call(() =>
    network.request<SpaceType[]>(`/stack-2/user/${user?.id}/team-spaces`).query({ pageSize: 100 }).get(),
  );

  if (response.hasError || !response.data) {
    console.error('Ошибка при загрузке пространств:', response.errors);
    yield put(listActions.loadDone());
    yield cancel();
    return;
  }

  const spaces = response.data.map(({ id, libraryId, ...space }) => ({
    id,
    libraryId: String(libraryId),
    ...space,
    __typename: 'Space',
  })) as SpaceType[];

  yield put(permissionActions.loadUserPermissions({ data: spaces }));
  yield put(listActions.set({ data: spaces }));
  yield put(listActions.loadDone());
}
