import { memo, useCallback } from 'react';

import { useRoute } from 'navigation/hooks';
import { usePerson } from 'hooks';

import { unit, guard } from 'utils';

import Icon from 'components/LegacyIcon';
import Button from 'components/Button';
import { useDispatcher } from 'store/utils/redux/hooks';

const ProfileActions = () => {
  const dispatcher = useDispatcher();

  const route = useRoute();
  const { person } = usePerson(
    route.isScreen('Author')
      ? { type: 'Author' as const, id: Number((route.params as Record<string, number>).id) }
      : { type: 'User' as const, login: String((route.params as Record<string, string>).login || 'my') },
  );

  const handleSetting = useCallback(() => {
    dispatcher.modal.open('Profile');
  }, []);

  return (
    <>
      {guard.isUser(person) && person?.isMy && (
        <Button type="button-icon" variant="text" onPress={handleSetting} width={32} height={32} style={{ marginRight: unit(-2) }}>
          <Icon name="SettingsOutline" size={28} />
        </Button>
      )}
    </>
  );
};

export default memo(ProfileActions);
