import { type FC, memo } from 'react';

import FolderLayout from './layouts/Folder';

import { useFolderRoute } from './model/folderRoute';

interface SpaceProps {}

const Space: FC<SpaceProps> = (props) => {
  // const dispatcher = useDispatcher();
  const { itemId, spaceId, folderId } = useFolderRoute();

  return <FolderLayout />;
};

export default memo(Space);
