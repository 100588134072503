import { useHasSession, usePerson } from 'hooks';

import AdaptivePage from 'components/AdaptivePage';
import AddNoteButton from 'components/AddNoteButton';
import PlusButton from 'components/PlusButton';
import { CopilotUtilityBar, CopilotShowToggle, CopilotModelSelect } from 'widgets/Copilot';

import ActionsBar from './elements/ActionsBar';

import LayoutDefault from './layouts/LayoutDefault';

const Collection = () => {
  const hasSession = useHasSession();
  const { person } = usePerson('User', 'my');

  if (hasSession && !person) {
    return null;
  }

  return (
    <AdaptivePage
      desktopHeaderLeftPanel={<ActionsBar />}
      desktopHeaderMiddlePanel={<CopilotShowToggle alignSelf="flex-end" mr={-1} />}
      desktopHeaderRightPanel={
        <CopilotUtilityBar
          pl={1}
          left={
            <CopilotUtilityBar.Item canHidden>
              <CopilotModelSelect ml={2} />
            </CopilotUtilityBar.Item>
          }
          right={
            <>
              <AddNoteButton />
              <PlusButton />
            </>
          }
        />
      }
      bodyMaxWidth={1280}
      paddingHorizontalScheme={{ 320: 16 }}
      scrollToStart
    >
      <LayoutDefault />
    </AdaptivePage>
  );
};

export default Collection;
