import { memo, useMemo, useState } from 'react';

import { useCardContextHandlers } from 'hooks';

import type { ResourceVariant } from 'app/entities';

import { useSelector } from 'store/utils/redux/hooks';
import * as contentStore from 'store/nodes/content';
import * as playlistStore from 'store/nodes/playlist';

import Icon from 'components/LegacyIcon';
import { controller as modal } from 'components/Modal2';

import Menu from '../elements/Menu';

import { type ModalFC, forwardController } from '../helpers';

export interface CardActionsProps {
  resource: ResourceVariant;
  resourceId: number;
  options?: {
    hasQueue?: boolean;
    hasToCollection?: boolean;
    hasShare?: boolean;
    hasLink?: boolean;
    hasPlaySummary?: boolean;
    hasTLDR?: boolean;
    hasShowRelated?: boolean;
    hasRemove?: boolean;
  };
}

const CardActions: ModalFC<CardActionsProps> = (props, forwardedRef) => {
  const [context, setContext] = useState<CardActionsProps>();

  const contentData = useSelector(contentStore.selectors.dataById(context?.resourceId));
  const playlistData = useSelector(playlistStore.selectors.dataById(context?.resourceId));
  const data = context?.resource === 'content' ? contentData : playlistData;

  const options = useMemo(() => {
    const { options: contextOptions } = context || {};
    return {
      hasQueue: contextOptions?.hasQueue || false,
      hasToCollection: contextOptions?.hasToCollection || false,
      hasShare: contextOptions?.hasShare || false,
      hasLink: contextOptions?.hasLink || false,
      hasPlaySummary: contextOptions?.hasPlaySummary || false,
      hasTLDR: contextOptions?.hasTLDR || false,
      hasShowRelated: contextOptions?.hasShowRelated || false,
      hasRemove: contextOptions?.hasRemove || false,
    };
  }, [context]);

  const { handleQueue, handleToCollection, handleShare, handleLink, handlePlaySummary, handleTLDR, handleShowRelated, handleRemove } =
    useCardContextHandlers(data);

  return (
    <Menu.Panel ref={forwardedRef} onContext={setContext}>
      {options.hasQueue && (
        <Menu.Item
          icon={<Icon name={!data?.isBookmarked ? 'BookmarkOutline28' : 'BookmarkFill28'} size={28} lightColor="#696969" darkColor="#D1CFD7" />}
          label={!data?.isBookmarked ? 'In queue' : 'Queue'}
          onPress={() => {
            modal.menu.cardActions.close(handleQueue);
          }}
        />
      )}
      {options.hasToCollection && (
        <Menu.Item
          icon={<Icon name="EditOutline28" size={28} lightColor="#696969" darkColor="#D1CFD7" />}
          label="To collection"
          onPress={() => {
            modal.menu.cardActions.close(handleToCollection);
          }}
        />
      )}
      {options.hasShare && (
        <Menu.Item
          icon={<Icon name="ShareOutline28" size={28} lightColor="#696969" darkColor="#D1CFD7" />}
          label="Share"
          onPress={() => {
            modal.menu.cardActions.close(handleShare);
          }}
        />
      )}
      {options.hasLink && (
        <Menu.Item
          icon={<Icon name="LinkInclineOutline28" size={28} lightColor="#696969" darkColor="#D1CFD7" />}
          label="Copy link"
          onPress={() => {
            modal.menu.cardActions.close(handleLink);
          }}
        />
      )}
      {options.hasPlaySummary && (
        <Menu.Item
          icon={<Icon name="Play" size={28} lightColor="#696969" darkColor="#D1CFD7" />}
          label="Play audio summary"
          onPress={() => {
            modal.menu.cardActions.close(handlePlaySummary);
          }}
        />
      )}
      {options.hasTLDR && (
        <Menu.Item
          icon={<Icon name="IkiCoPilot" size={28} lightColor="#696969" darkColor="#D1CFD7" />}
          label="TLDR with GPT4"
          onPress={() => {
            modal.menu.cardActions.close(handleTLDR);
          }}
        />
      )}
      {options.hasShowRelated && (
        <Menu.Item
          icon={<Icon name="RefreshCCW" size={28} lightColor="#696969" darkColor="#D1CFD7" />}
          label="Show related"
          onPress={() => {
            modal.menu.cardActions.close(handleShowRelated);
          }}
        />
      )}
      {options.hasRemove && (
        <Menu.Item
          icon={<Icon name="DeleteOutline28" size={28} lightColor="#db3327" darkColor="#db3327" />}
          label="Remove"
          onPress={() => {
            modal.menu.cardActions.close();
            handleRemove();
          }}
        />
      )}
    </Menu.Panel>
  );
};

export default memo(forwardController(CardActions));
