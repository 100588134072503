import { takeEvery, put, take, delay, all } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-browser';

import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';
import * as menuStore from 'store/nodes/menu';
import * as authStore from 'store/nodes/auth';
import * as userStore from 'store/nodes/user';
import * as libraryStore from 'store/nodes/library';
import * as searchModalStore from 'store/nodes/searchModal';
import * as subscriptionStore from 'widgets/Subscription/store';
import * as onboardingStore from 'widgets/Onboarding/store';
import * as spaceStore from 'screens/Space/store';
import * as copilotStore from 'widgets/Copilot/store';

import Alert from 'components/Alert';

import * as actions from '../actions';

export const config = {
  action: actions.ready.type,
  method: takeEvery,
};

function* loadUser() {
  const hasSession = api.credentials.hasSession();
  if (!hasSession) {
    return false;
  }

  yield put(userStore.actions.loadMe());
  const { payload: userPayload } = yield take(userStore.actions.loadMeDone.type);
  if (userPayload.login === 'my' && userPayload.error === 'Object not found') {
    api.credentials.clear();
    yield call(() => Alert.success('Your session was closed'));
    return false;
  }

  return true;
}

export function* func() {
  yield put(actions.initProtection());
  yield take(actions.initProtectionDone.type);
  yield put(authStore.actions.checkAuth());
  yield put(authStore.actions.startCredentialListening());
  yield take(authStore.actions.checkAuthDone.type);

  const isUserLoaded = yield* loadUser();

  if (isUserLoaded) {
    yield put(
      copilotStore.actions.clear({
        resources: [{ type: 'web' }, { type: 'global-fulltext' }, { type: 'library-fulltext' }, { type: 'research' }],
      }),
    );
    yield put(spaceStore.slices.spaceRoot.actions.init());
    yield put(subscriptionStore.actions.loadState());
    yield put(onboardingStore.actions.loadState());
    yield put(menuStore.actions.load());
    yield put(libraryStore.actions.loadFilters());
    yield all([
      take(spaceStore.slices.spaceRoot.actions.initDone.type),
      take(subscriptionStore.actions.loadStateDone.type),
      take(onboardingStore.actions.loadStateDone.type),
      take(menuStore.actions.loadDone.type),
      take(libraryStore.actions.loadFiltersDone.type),
    ]);

    yield delay(10);
    yield put(searchModalStore.actions.loadFilters());
  }

  yield put(actions.prepared());
  yield* call(() => track('Started'));

  yield delay(10);
  yield put(actions.hideSplashScreen());
}
