import { type FC, memo } from 'react';

import { Box } from '@mui/joy';

import AdaptivePage from 'components/AdaptivePage';
import PlusButton from 'components/PlusButton';
import AddNoteButton from 'components/AddNoteButton';
import SearchInput from 'components/SearchInput';

import { CopilotModelSelect, CopilotShowToggle, CopilotUtilityBar } from 'widgets/Copilot';
import PanelsBar from 'widgets/PanelsBar';
import { useSelector } from 'store/utils/redux/hooks';

import { useFolderRoute } from '../model/folderRoute';
import usePanelsRender from '../model/usePanelsRender';
import { SelectionProvider } from '../model/SelectionContext';

import Entities from '../elements/Entities';
import SelectionBar from '../elements/SelectionBar';

interface FolderProps {}

const Folder: FC<FolderProps> = () => {
  const { spaceId, folderId } = useFolderRoute();

  const spaceName = useSelector((state) => (!spaceId ? '' : state.spaceList?.data?.[spaceId].title));
  const panelsRender = usePanelsRender(spaceId, folderId);

  return (
    <SelectionProvider>
      <AdaptivePage
        desktopHeaderLeftPanel={false}
        desktopHeaderMiddlePanel={
          <Box display="flex" flexDirection="row" alignItems="center" gap={2} alignSelf="flex-end" flex={1} width="100%">
            <SearchInput size="medium" placeholder={`Search by space "${spaceName}"`} />
            <Box flex={1} />
            <CopilotShowToggle mr={-1} />
          </Box>
        }
        desktopHeaderRightPanel={
          <CopilotUtilityBar
            pl={1}
            left={
              <>
                <CopilotUtilityBar.Item canHidden>
                  <CopilotModelSelect ml={2} />
                </CopilotUtilityBar.Item>
              </>
            }
            right={
              <>
                <AddNoteButton />
                <PlusButton />
              </>
            }
          />
        }
        bodyMaxWidth={1280}
        paddingHorizontalScheme={{ 320: 16 }}
        scrollToStart
      >
        <PanelsBar
          contentMeta={{
            label: 'Content',
            value: 'content',
          }}
          content={
            <>
              <Entities spaceId={spaceId} folderId={folderId} />
              <SelectionBar spaceId={spaceId} folderId={folderId} detectPositionSelector="div.entities" />
            </>
          }
          contentProps={{
            className: 'entities',
          }}
          panels={panelsRender}
          sx={{
            '@media (max-width: 767px)': {
              mx: -2,
            },
          }}
        />
      </AdaptivePage>
    </SelectionProvider>
  );
};

export default memo(Folder);
