import * as React from 'react';
import { memo, useRef, useCallback, type MouseEvent, type FC, forwardRef, useImperativeHandle, useMemo } from 'react';
import { RichTreeViewPro, useTreeViewApiRef, type RichTreeViewProProps } from '@mui/x-tree-view-pro';
import type { TreeViewAnyPluginSignature, TreeViewPublicAPI } from '@mui/x-tree-view/internals/models';
import type { TreeViewItemId } from '@mui/x-tree-view/models';

import useFolderTree from '../../model/useFolderTree';
import { TreeViewInteractionsProvider, TreeViewInteractionsBridge, type TreeViewInteractionsBridgeRef } from './model/treeViewItemInteractionUtils';

import Item, { type ItemProps } from './ui/Item';

export interface SpaceTreeViewRef {
  getInteractionsBridge: () => TreeViewInteractionsBridgeRef | null;
  getApi: <
    TSignatures extends readonly TreeViewAnyPluginSignature[] = readonly [
      import('@mui/x-tree-view/internals').UseTreeViewItemsSignature,
      import('@mui/x-tree-view/internals').UseTreeViewExpansionSignature,
      import('@mui/x-tree-view/internals').UseTreeViewSelectionSignature,
      import('@mui/x-tree-view/internals').UseTreeViewFocusSignature,
      import('@mui/x-tree-view/internals').UseTreeViewKeyboardNavigationSignature,
      import('@mui/x-tree-view/internals').UseTreeViewIconsSignature,
      import('@mui/x-tree-view/internals').UseTreeViewLabelSignature,
    ],
  >() => TreeViewPublicAPI<TSignatures> | null;
}

export interface SpaceTreeViewProps extends Omit<RichTreeViewProProps<{}, false>, 'items' | 'onContextMenu'> {
  /**
   * Включает-выключает возможность перетаскивания/реордеринга.
   */
  enableReordering?: boolean;
  /**
   * Включает-выключает возможность редактирования (rename).
   */
  enableEditing?: boolean;

  /**
   * Если true, при монтировании автоматически развернём все
   * корневые узлы (т.е. parentId='root').
   */
  expandRootOnMount?: boolean;

  /**
   * Обработчик контекстного меню, если нужен.
   */
  onContextMenu?: (event: MouseEvent, itemId: string) => void;

  onItemLabelChange?: (itemId: TreeViewItemId, newLabel: string) => void;

  onItemLabelChangingStart?: (itemId: TreeViewItemId) => void;

  onItemLabelChangingSave?: (itemId: TreeViewItemId, newLabel: string) => void;

  onItemLabelChangingCancel?: (itemId: TreeViewItemId) => void;
}

const SpaceTreeView = forwardRef<SpaceTreeViewRef, SpaceTreeViewProps>((props, forwardedRef) => {
  const {
    enableReordering = false,
    enableEditing = false,
    selectedItems,
    onSelectedItemsChange,
    defaultExpandedItems,
    onExpandedItemsChange,
    canMoveItemToNewPosition,
    onItemPositionChange,
    onContextMenu,
    onItemLabelChange,
    onItemLabelChangingStart,
    onItemLabelChangingSave,
    onItemLabelChangingCancel,
    expandRootOnMount = false,
    sx,
    ...rest
  } = props;

  const { tree } = useFolderTree();

  const apiRef = useTreeViewApiRef();
  const treeViewInteractionsBridgeRef = useRef<TreeViewInteractionsBridgeRef>(null);

  useImperativeHandle(
    forwardedRef,
    () => ({
      getInteractionsBridge: () => treeViewInteractionsBridgeRef.current,
      getApi: (() => apiRef.current || null) as SpaceTreeViewRef['getApi'],
    }),
    [apiRef],
  );

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      const itemId = event.currentTarget.getAttribute('itemId');
      if (itemId) {
        onContextMenu?.(event, itemId);
      }
    },
    [onContextMenu],
  );

  const computedDefaultExpandedItems = useMemo(() => {
    if (!expandRootOnMount || !Array.isArray(tree)) {
      return defaultExpandedItems;
    }
    const rootIds = tree.filter((node) => node.id.includes('space')).map((node) => node.id);
    if (rootIds.length === 0) {
      return defaultExpandedItems;
    }
    const oldSet = new Set(defaultExpandedItems ?? []);
    rootIds.forEach((id) => oldSet.add(id));
    return Array.from(oldSet);
  }, [expandRootOnMount, tree, defaultExpandedItems]);

  return (
    <TreeViewInteractionsProvider>
      <RichTreeViewPro
        apiRef={apiRef}
        items={tree}
        itemsReordering={enableReordering}
        isItemEditable={enableEditing}
        experimentalFeatures={{
          labelEditing: enableEditing,
          itemsReordering: enableReordering,
          indentationAtItemLevel: enableReordering,
        }}
        selectedItems={selectedItems}
        onSelectedItemsChange={onSelectedItemsChange}
        defaultExpandedItems={computedDefaultExpandedItems}
        onExpandedItemsChange={onExpandedItemsChange}
        canMoveItemToNewPosition={enableReordering ? canMoveItemToNewPosition : undefined}
        onItemPositionChange={enableReordering ? onItemPositionChange : undefined}
        onItemLabelChange={onItemLabelChange}
        expansionTrigger="iconContainer"
        slots={{
          item: Item,
        }}
        slotProps={{
          item: {
            onContextMenu: handleContextMenu,
            onItemLabelChangingStart,
            onItemLabelChangingSave,
            onItemLabelChangingCancel,
          } as Omit<ItemProps, 'itemId'>,
        }}
        sx={{
          mb: 2,
          '& .root-level > .MuiTreeItem-content': {
            '--TreeView-rootDepth': 1,
          },
          '& .MuiTreeItem-content': {
            paddingLeft: `
              calc(
                6px - calc(1 * var(--mui-spacing))
                + var(--TreeView-itemChildrenIndentation)
                * var(--TreeView-rootDepth, var(--TreeView-itemDepth))
              )
            `,
          },
          '& > li': { my: 2 },
          '& > li:first-child': { mt: 0 },
          '& > li:last-child': { mb: 0 },
          '--mui-palette-primary-mainChannel': 'var(--joy-palette-neutral-mainChannel)',
          ...sx,
        }}
        {...rest}
      />
      <TreeViewInteractionsBridge ref={treeViewInteractionsBridgeRef} />
    </TreeViewInteractionsProvider>
  );
});

export default memo(SpaceTreeView);
