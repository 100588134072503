import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, DialogTitle, Divider, Modal, ModalClose, ModalDialog } from '@mui/joy';
import { formatCurrency } from 'utils';

import { useDispatcher } from 'store/utils/redux/hooks';

import * as modalStore from 'widgets/modals/store';
import useCosts from 'widgets/Onboarding/model/useCosts';
import * as subscriptionStore from 'widgets/Subscription/store';

import StripeElementsWrapper from 'components/StripeElementsWrapper';
import Alert from 'components/Alert';

import SubscriptionContent from '../modals/SubscriptionContent';
import usePlan from '../model/usePlan';
import getTrialMessage from '../model/getTrialMessage';

import PromoInput, { type OnPromoInputEventHandler, type PromoDataType } from '../ui/PromoInput';
import PriceRow from '../ui/PriceRow';

export type SubscriptionParams = {
  planId: string;
};

const Subscription = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(modalStore.selectors.state('Subscription'));
  const [promo, setPromo] = useState<(PromoDataType & { code: string }) | null>(null);
  const { plan, period } = usePlan(params?.planId);
  const costMap = useCosts({ promo });
  const periodId = period?.id || 'monthly';
  const cost = costMap[params?.planId];

  const { lastPaymentAmount = 0, isExists, isTrialing } = useSelector(subscriptionStore.selectors.currentPlan);
  const hasTrial = useMemo(() => Object.keys(plan?.trial || {}).length > 0 && (!isExists || isTrialing), [plan?.trial, isExists, isTrialing]);

  useEffect(() => {
    if (isOpen === true) {
      setPromo(null);
    }
  }, [isOpen]);

  const handleModalClose = useCallback(async () => {
    dispatcher.modal.close('Subscription');
  }, []);

  const handlePromoEvent: OnPromoInputEventHandler = useCallback((event) => {
    if (event.type === 'applied') {
      setPromo({ ...event.data, code: event.code });
    }
    if (event.type === 'reset') {
      setPromo(null);
    }
    if (event.type === 'error') {
      Alert.error(event.error);
    }
  }, []);

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <StripeElementsWrapper>
        <ModalDialog
          variant="soft"
          size="lg"
          sx={{
            maxWidth: 600,
            minWidth: 420,
            '--ModalClose-radius': '1.125em',
          }}
        >
          <ModalClose onClick={handleModalClose} />
          <DialogTitle sx={{ mt: -1 }}>IKI {plan?.name}</DialogTitle>
          <Box display="flex" flexDirection="column" mb={0.5} gap={0.25}>
            {!!plan && cost.type === 'regular' && (
              <PriceRow label="Billing" value={`${cost[periodId].formatted.total} / ${plan.cost.period.short}`} />
            )}
            {!!plan && cost.type === 'special' && promo && (
              <PriceRow
                label="Billing"
                value={`
                  ${cost[periodId].formatted.discountedTotal} / ${plan.cost.period.short}
                  ${periodId === 'monthly' ? `for first ${promo?.durationInMonths} months` : 'for the first year'}
                `}
                subtext={`${cost[periodId].formatted.total} annually thereafter`}
              />
            )}
            {!!plan && lastPaymentAmount > 0 && (
              <>
                <PriceRow label="Credit applied" value={`-${formatCurrency(lastPaymentAmount)}`} />
              </>
            )}
            {!!plan && cost.type === 'regular' && !hasTrial && (
              <>
                <PriceRow fontWeight={500} label="Total due today" value={formatCurrency(cost[periodId].total - lastPaymentAmount)} />
              </>
            )}
            {!!plan && cost.type === 'special' && !hasTrial && (
              <>
                <PriceRow fontWeight={500} label="Total due today" value={formatCurrency(cost[periodId].discountedTotal - lastPaymentAmount)} />
              </>
            )}
            {!!plan && hasTrial && (
              <>
                <PriceRow fontWeight={400} label="Includes" value={getTrialMessage(plan.trial)} textColor="var(--joy-palette-focusVisible)" />
                <PriceRow fontWeight={500} label="Total due today" value={0} />
              </>
            )}
          </Box>
          <PromoInput isApplied={!!promo} onEvent={handlePromoEvent} />
          <Divider />
          <SubscriptionContent planId={params?.planId} promoCode={promo?.code} trial={hasTrial} />
        </ModalDialog>
      </StripeElementsWrapper>
    </Modal>
  );
};

export default memo(Subscription);
