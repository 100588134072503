import { type CSSProperties } from 'react';
import { type StyleProp, type TextStyle, type ViewStyle, Platform } from 'react-native';

import type {
  AuthorType,
  ImageType,
  MaterialType,
  CollectionType,
  RagMaterialType,
  NoteType,
  DocumentType,
  UserType,
  SearchItemType,
  ActivityNewMaterialType,
  ActivityNewCollectionType,
  ActivityNewDocumentType,
  ActivityCommentMaterialType,
  ActivityCommentCollectionType,
  ActivityCommentDocumentType,
  SourceMaterialType,
  NoteKey,
  ResourceKey,
  CollectionPermissionType,
  PoorMaterialType,
  PoorCollectionType,
  BasicUserType,
  ContentErrorType,
  EntityErrorType,
  EntityDataType,
  LinkToContentType,
  SpaceFolderType,
  SpaceContentType,
  SpaceType,
} from 'app/entities';

export function isEntityError(entity: EntityErrorType | unknown): entity is EntityErrorType {
  return !!entity && typeof entity === 'object' && 'type' in entity && ['NotFoundError', 'AccessError'].includes(`${entity.type}`);
}

export function isEntityMaterial(entity: EntityDataType<'Material'> | unknown): entity is EntityDataType<'Material'> {
  return !!entity && typeof entity === 'object' && 'type' in entity && 'data' in entity && entity.type === 'Material' && entity.data !== null;
}

export function isEntityDocument(entity: EntityDataType<'Document'> | unknown): entity is EntityDataType<'Document'> {
  return !!entity && typeof entity === 'object' && 'type' in entity && 'data' in entity && entity.type === 'Document' && entity.data !== null;
}

export function isEntityCollection(entity: EntityDataType | EntityErrorType): entity is EntityDataType<'Collection'> {
  return !!entity && typeof entity === 'object' && 'type' in entity && 'data' in entity && entity.type === 'Collection' && entity.data !== null;
}

export const isHTMLDivElement = (element: HTMLDivElement | unknown): element is HTMLDivElement => {
  return Platform.OS === 'web' && element instanceof HTMLDivElement;
};

export const isHTMLInputElement = (element: HTMLInputElement | unknown): element is HTMLInputElement => {
  return Platform.OS === 'web' && element instanceof HTMLInputElement;
};

export const isHTMLElement = (element: HTMLElement | unknown): element is HTMLElement => {
  return Platform.OS === 'web' && element instanceof HTMLElement;
};

export const isWebStyleProp = (data: CSSProperties | StyleProp<ViewStyle> | StyleProp<TextStyle> | any): data is CSSProperties =>
  Platform.OS === 'web';

export const isPoorMaterial = (data: PoorMaterialType | unknown): data is PoorMaterialType =>
  !!data &&
  typeof data === 'object' &&
  'id' in data &&
  'resourceType' in data &&
  typeof data?.id === 'number' &&
  data?.id > 0 &&
  typeof data?.resourceType === 'string' &&
  data?.resourceType === 'CONTENT';

export const isPoorCollection = (data: PoorCollectionType | unknown): data is PoorCollectionType =>
  !!data &&
  typeof data === 'object' &&
  'id' in data &&
  'resourceType' in data &&
  typeof data?.id === 'number' &&
  data?.id > 0 &&
  typeof data?.resourceType === 'string' &&
  data?.resourceType === 'PLAYLIST';

export const isLinkToContent = (data: LinkToContentType | unknown): data is LinkToContentType =>
  !!data &&
  typeof data === 'object' &&
  'resourceType' in data &&
  typeof data.resourceType === 'string' &&
  ['Collection', 'Material', 'Document'].includes(data.resourceType) &&
  'resourceId' in data &&
  Number.isInteger(data.resourceId);

export const isLinkToContentVector = (data: LinkToContentType[] | unknown): data is LinkToContentType[] => {
  let isSuitable = true;
  if (typeof data !== 'object' || !Array.isArray(data)) {
    return false;
  }
  for (let key = 0; key < data.length; key++) {
    if (!isLinkToContent(data[key])) {
      isSuitable = false;
      break;
    }
  }
  return isSuitable;
};

export const isContentError = (data: ContentErrorType | unknown): data is ContentErrorType => {
  if (!data || typeof data !== 'object') {
    return false;
  }
  return '__typename' in data && (data.__typename === 'AccessError' || data.__typename === 'NotFoundError' || data.__typename === 'UnknownError');
};

export const isMaterial = (data: MaterialType | unknown): data is MaterialType => {
  if (!data || typeof data !== 'object') {
    return false;
  }
  if ('__typename' in data && data.__typename === 'Material') {
    return true;
  }
  return 'type' in data && 'authors' in data && 'image' in data && !('materialsMetadata' in data);
};

export const isSourceMaterial = (data: SourceMaterialType | unknown): data is SourceMaterialType =>
  !!data && typeof data === 'object' && 'type' in data && 'isSaved' in data && 'originalUrl' in data && !('materialsMetadata' in data);

export const isCollection = (data: CollectionType | unknown): data is CollectionType =>
  !!data && typeof data === 'object' && 'cover' in data && 'materialsMetadata' in data && 'contentImages' in data;

export const isRagMaterial = (data: RagMaterialType | unknown): data is RagMaterialType =>
  !!data &&
  typeof data === 'object' &&
  (('__typename' in data && data.__typename === 'RagMaterial') || ('typename' in data && data.typename === 'RagMaterial'));

export const isSearchItem = (data: SearchItemType | unknown): data is SearchItemType =>
  !!data && typeof data === 'object' && 'element' in data && 'searchMeta' in data && (isMaterial(data.element) || isCollection(data.element));

export const isNote = (data: NoteType | unknown): data is NoteType =>
  !!data && typeof data === 'object' && '__typename' in data && data.__typename === 'Note';

export const isDocument = (data: DocumentType | unknown): data is DocumentType =>
  !!data && typeof data === 'object' && '__typename' in data && data.__typename === 'Document';

export const isSpace = (data: SpaceType | unknown): data is SpaceType =>
  !!data && typeof data === 'object' && '__typename' in data && data.__typename === 'Space';

export const isSpaceFolder = (data: SpaceFolderType | unknown): data is SpaceFolderType =>
  !!data && typeof data === 'object' && '__typename' in data && data.__typename === 'Folder';

export const isSpaceContent = (data: SpaceContentType | unknown): data is SpaceContentType =>
  !!data && typeof data === 'object' && '__typename' in data && data.__typename === 'Content';

export const isImage = (source: ImageType | unknown): source is ImageType =>
  source !== null && typeof source === 'object' && 'id' in source && 'url' in source;

export const isAuthor = (data: AuthorType | unknown): data is AuthorType => {
  return !!data && typeof data === 'object' && 'id' in data && 'name' in data && 'surname' in data && 'owner' in data && 'originalUrl' in data;
};

export const isUser = (data: UserType | BasicUserType | unknown): data is UserType | BasicUserType => {
  return !!data && typeof data === 'object' && !Array.isArray(data) && 'id' in data && 'login' in data && 'name' in data && 'surname' in data;
};

export const isUserArray = (data: UserType[] | BasicUserType[] | unknown): data is UserType[] | BasicUserType[] => {
  return !!data && typeof data === 'object' && Array.isArray(data) && isUser(data?.[0]);
};

export const isPersonVector = (data: (UserType | BasicUserType | AuthorType)[] | unknown): data is (UserType | AuthorType)[] => {
  let isSuitable = true;
  if (typeof data !== 'object' || !Array.isArray(data)) {
    return false;
  }
  for (let key = 0; key < data.length; key++) {
    if (!isUser(data[key]) && !isAuthor(data[key])) {
      isSuitable = false;
      break;
    }
  }
  return isSuitable;
};

export const isActivityNewMaterial = (data: ActivityNewMaterialType | unknown): data is ActivityNewMaterialType => {
  return !!data && typeof data === 'object' && 'type' in data && data?.type === 'NEW_MATERIAL';
};

export const isActivityNewCollection = (data: ActivityNewCollectionType | unknown): data is ActivityNewCollectionType => {
  return !!data && typeof data === 'object' && 'type' in data && data?.type === 'NEW_PLAYLIST';
};

export const isActivityNewDocument = (data: ActivityNewDocumentType | unknown): data is ActivityNewDocumentType => {
  return !!data && typeof data === 'object' && 'type' in data && data?.type === 'NEW_DOCUMENT';
};

export const isActivityCommentMaterial = (data: ActivityCommentMaterialType | unknown): data is ActivityCommentMaterialType => {
  return (
    !!data && typeof data === 'object' && 'type' in data && data?.type === 'NEW_COMMENT' && 'resourceType' in data && data.resourceType === 'Material'
  );
};

export const isActivityCommentCollection = (data: ActivityCommentCollectionType | unknown): data is ActivityCommentCollectionType => {
  return (
    !!data &&
    typeof data === 'object' &&
    'type' in data &&
    data?.type === 'NEW_COMMENT' &&
    'resourceType' in data &&
    data.resourceType === 'Collection'
  );
};

export const isActivityCommentDocument = (data: ActivityCommentDocumentType | unknown): data is ActivityCommentDocumentType => {
  return (
    !!data && typeof data === 'object' && 'type' in data && data?.type === 'NEW_COMMENT' && 'resourceType' in data && data.resourceType === 'Document'
  );
};

export const isLink = (text: string | unknown): text is string => {
  return typeof text === 'string' && /^https?:\/\/.+\..+/.test(text);
};

export const isResourceKey = (key: ResourceKey | unknown): key is ResourceKey =>
  !!key &&
  typeof key === 'object' &&
  'resourceType' in key &&
  'resourceId' in key &&
  typeof key?.resourceType === 'string' &&
  typeof key?.resourceId === 'number';

export const isNoteKey = (key: NoteKey | unknown): key is NoteKey =>
  !!key && typeof key === 'object' && 'noteId' in key && typeof key?.noteId === 'number';

export const isCollectionPermissionType = (arr: CollectionPermissionType[]): arr is CollectionPermissionType[] => {
  return arr.every((item) => typeof item === 'object' && item !== null && 'user' in item && 'action' in item && 'permission' in item);
};

export const resourceKeyIsMaterial = (type: 'Material' | unknown): type is 'Material' =>
  typeof type === 'string' && type.toLowerCase() === 'Material'.toLowerCase();

export const resourceKeyIsCollection = (type: 'Collection' | unknown): type is 'Collection' =>
  typeof type === 'string' && type.toLowerCase() === 'Collection'.toLowerCase();

export const resourceKeyIsNote = (type: 'Note' | unknown): type is 'Note' => typeof type === 'string' && type.toLowerCase() === 'Note'.toLowerCase();

export const resourceKeyIsDocument = (type: 'Document' | unknown): type is 'Document' =>
  typeof type === 'string' && type.toLowerCase() === 'Document'.toLowerCase();

export const resourceKeyIsRagMaterial = (type: 'RagMaterial' | unknown): type is 'RagMaterial' =>
  typeof type === 'string' && type.toLowerCase() === 'RagMaterial'.toLowerCase();

export const resourceKeyIsSpaceFolder = (type: 'Folder' | unknown): type is 'Folder' => typeof type === 'string' && type === 'Folder';
