import { memo } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import type { AuthorType, UserType } from 'app/entities';

import * as menuStore from 'store/nodes/menu';
import { guard } from 'utils';

import HorizontalSwiper from 'widgets/HorizontalSwiper';
import Person from 'components/Person';

type UsersSwiperProps = {
  items: (UserType | AuthorType)[];
};

const UsersSwiper = (props: UsersSwiperProps) => {
  const { items } = props;

  const leftBarWidth = useSelector(menuStore.selectors.leftBarWidth);
  const isOpen = leftBarWidth > 64;

  return (
    <HorizontalSwiper
      className="swiperUser"
      autoHeight={false}
      breakpoints={{
        320: {
          slidesPerView: 3.25,
          spaceBetween: 16,
        },
        560: {
          slidesPerView: 4.75,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 4.75,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: !isOpen ? 8.25 : 6.25,
          spaceBetween: 16,
        },
        1280: {
          slidesPerView: !isOpen ? 10.25 : 8.25,
          spaceBetween: 16,
        },
        1440: {
          slidesPerView: 10.5,
          spaceBetween: 16,
        },
      }}
    >
      {items.map((item) => (
        <Person
          key={`${item.__typename}-${item.id}`}
          type={item.__typename}
          id={guard.isAuthor(item) ? item.id : undefined}
          login={guard.isUser(item) ? item.login : undefined}
          name={item.name}
          surname={item.surname}
          photo={item.photo}
          profession={guard.isUser(item) ? item.profession : undefined}
          style={{ marginBottom: 16 }}
        />
      ))}
    </HorizontalSwiper>
  );
};

export default memo(UsersSwiper, (prevProps, nextProps) => isEqual(prevProps, nextProps));
