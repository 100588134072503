import { type MouseEvent, type SyntheticEvent, useCallback, useMemo, useState } from 'react';

import useFolderMeta from '../../../model/useFolderMeta';
import { parseItemId } from '../../../model/itemIdParser';

import ContextMenuComponent, { type ContextMenuActionsType } from '../ui/ContextMenu';

export type UseContentMenuParamsActionContext =
  | { name: Omit<ContextMenuActionsType, 'TogglePrivate'>; itemId: string; spaceId: number; entityId: string }
  | { name: 'TogglePrivate'; itemId: string; spaceId: number; entityId: string; newValue: boolean };
export type UseContentMenuParamsOpenContext = { itemId: string; spaceId: number; entityId: string };

export interface UseContentMenuParams {
  onAction?: (event: SyntheticEvent, context: UseContentMenuParamsActionContext) => void;
  onOpen?: (event: SyntheticEvent, context: UseContentMenuParamsOpenContext) => void;
}

const useContextMenu = (params?: UseContentMenuParams) => {
  const [contextMenuState, setContextMenuState] = useState<{
    open: boolean;
    itemId: string;
    coords: { x: number; y: number };
  }>({
    open: false,
    itemId: '',
    coords: { x: 0, y: 0 },
  });

  const { isPrivate, type } = useFolderMeta(contextMenuState.itemId);

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      const itemId = event.currentTarget.getAttribute('itemId');
      if (!itemId) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      const { spaceId, entityId } = parseItemId(itemId);
      setContextMenuState({
        open: true,
        itemId,
        coords: { x: event.clientX, y: event.clientY },
      });
      params?.onOpen?.(event, {
        itemId,
        spaceId,
        entityId,
      });
    },
    [params?.onOpen],
  );

  const handleOpenChange = useCallback((open: boolean) => {
    setContextMenuState((prev) => ({ ...prev, open }));
  }, []);

  const handleAction = useCallback(
    (event: SyntheticEvent, name: ContextMenuActionsType) => {
      const { spaceId, entityId } = parseItemId(contextMenuState.itemId);
      if (name !== 'TogglePrivate') {
        params?.onAction?.(event, { name, itemId: contextMenuState.itemId, spaceId, entityId });
      }
      if (name === 'TogglePrivate') {
        params?.onAction?.(event, { name, itemId: contextMenuState.itemId, spaceId, entityId, newValue: !isPrivate });
      }
      setContextMenuState((prev) => ({ ...prev, open: false }));
    },
    [contextMenuState.itemId, isPrivate],
  );

  return useMemo(
    () => ({
      handleContextMenu,
      ContextMenu: () => (
        <ContextMenuComponent
          open={contextMenuState.open}
          coords={contextMenuState.coords}
          onOpenChange={handleOpenChange}
          onAction={handleAction}
          isPrivate={isPrivate}
          hasCopilot
          hasShare
          hasNew
          hasPermissions={type === 'space'}
          hasRename={type !== 'space' && type !== 'root'}
          hasEdit={type !== 'root'}
          hasDelete={type !== 'root'}
          hasPrivate={type !== 'root'}
        />
      ),
    }),
    [handleContextMenu, contextMenuState],
  );
};

export default useContextMenu;
