import { memo } from 'react';
import { type StyleProp, type TextStyle, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import * as contentStore from 'store/nodes/content';
import { useMaterialType } from 'hooks';

import { View } from 'components/Themed';

import Info from './Info';

import useBodyRender from '../hooks/useBodyRender';
import useTitleRender from '../hooks/useTitleRender';

type ContentPanelProps = {
  style?: StyleProp<TextStyle>;
  resourceId: number;
  hasTitle?: boolean;
};

const ContentPanel = (props: ContentPanelProps) => {
  const { style, resourceId, hasTitle } = props;

  const data = useSelector(contentStore.selectors.dataById(resourceId));
  const materialType = useMaterialType(data);
  const bodyRender = useBodyRender(materialType, data);
  const titleRender = useTitleRender(materialType, data, hasTitle);

  if (!data) {
    return null;
  }

  return (
    <View style={[styles.component, style]}>
      <Info data={data} />
      {titleRender}
      {bodyRender}
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    flex: 1,
    paddingBottom: 64,
  },
});

export default memo(ContentPanel);
