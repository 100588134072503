import type { DefaultRootState } from 'react-redux';
import { type SagaReturnType, call, put, takeLatest } from 'redux-saga/effects';
import network from 'lib/network';

import { select } from 'store/utils/saga/effects';

import { actions } from '../slices/resource';

const selectResourcesInfo = (resourceIds: string[]) => (state: DefaultRootState) => {
  return resourceIds.map((id) => state.spaceResource.data[id]);
};

export const config = {
  action: actions.remove.type, // слушаем remove
  method: takeLatest, // берём последний вызов
};

export function* func(action: SagaReturnType<typeof actions.remove>) {
  const { resourceIds } = action.payload;

  const resourcesInfo = yield* select(selectResourcesInfo(resourceIds));

  yield put(actions.removeApply({ resourceIds }));

  for (const info of resourcesInfo) {
    if (!info) {
      continue;
    }

    const { spaceId, __typename } = info;
    const resourceType = __typename === 'Folder' ? 'folder' : 'content';

    try {
      const url = `/stack-2/user/team-space/${spaceId}/resources/${resourceType}/${info.id}`;
      const response: { data: null | any; hasError: boolean; errors: Error[] | null } = yield call(() => network.request(url).delete());

      if (response.hasError) {
        // Если ошибка — решаем, что делать
        // Можно показать алерт, отменить удаление, и т.д.
        // Для простоты пропустим
      }
    } catch (error) {
      // Если запрос упал
      // Можно откатить, показать сообщение и т.п.
      // Пока ничего не делаем
    }
  }

  yield put(actions.removeDone({ resourceIds }));
}
