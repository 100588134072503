import { type MouseEvent, type FC, memo, useCallback } from 'react';
import { Box, Checkbox } from '@mui/joy';

import type { SpaceEntityKey } from 'app/entities';
import Card from 'widgets/Card';

import { guard } from 'utils';

import useEntityById from '../model/useEntityById';
import { useSelection } from '../model/SelectionContext';

type ItemProps = {
  data: SpaceEntityKey;
  index: number;
};

const Item: FC<ItemProps> = (props) => {
  const { data, index } = props;

  const entity = useEntityById(data.id);
  const { hasSelections, isSelected, toggleSelection } = useSelection();
  const selected = isSelected(data);

  const handleCheckboxClick = useCallback(
    (event: MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
      toggleSelection(data);
    },
    [data, toggleSelection],
  );

  if (guard.isSpaceFolder(entity)) {
    return (
      <Box
        boxShadow={`0 0 0 1px ${selected ? 'var(--joy-palette-primary-solidBg)' : 'transparent'}`}
        borderRadius={16}
        sx={{ transition: 'boxShadow 200ms ease' }}
      >
        <Card
          id={Number(entity.id)}
          type="Folder"
          footerStart={
            <Box position="relative" zIndex={5}>
              <Checkbox checked={selected} onClick={handleCheckboxClick} />
            </Box>
          }
          hasDescription={false}
          hasSummary
          hasHighlight
          hasTags
          hasRemove
          hasQueue={false}
          hasLink
          hasEdit
          hasAuthor
          hasToCollection
          hasIndexed
        />
      </Box>
    );
  }
  if (guard.isSpaceContent(entity)) {
    return (
      <Box
        boxShadow={`0 0 0 1px ${selected ? 'var(--joy-palette-primary-solidBg)' : 'transparent'}`}
        borderRadius={16}
        sx={{ transition: 'boxShadow 200ms ease' }}
      >
        <Card
          id={Number(entity.resourceId)}
          type={entity.resourceType}
          footerStart={
            <Box position="relative" zIndex={5}>
              <Checkbox checked={selected} onClick={handleCheckboxClick} />
            </Box>
          }
          hasDescription={false}
          hasSummary
          hasHighlight
          hasTags
          hasRemove
          hasQueue={false}
          hasLink
          hasEdit
          hasAuthor
          hasToCollection
          hasIndexed
        />
      </Box>
    );
  }
  return null;
};

export default memo(Item);
