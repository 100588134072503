import { Fragment, memo, useCallback, useRef } from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';
import Box from '@mui/joy/Box';
import { isEqual } from 'lodash';

import ScrollBox from 'ui/ScrollBox';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';
import * as contentStore from 'store/nodes/content';

import NewNote, { type NewNoteRef } from './components/NewNote';
import ItemNote from './components/ItemNote';
import ItemHighlight from './components/ItemHighlight';

type NotesProps = {
  resourceType: 'collection' | 'material';
  resourceId: number;
  mixHighlight?: boolean;
};

const Notes = (props: NotesProps) => {
  const { resourceType, resourceId, mixHighlight } = props;
  const dispatcher = useDispatcher();

  const newNoteRef = useRef<NewNoteRef | null>(null);

  const isLoading = useSelector(noteStore.selectors.isLoading({ resourceType, resourceId }));

  const itemKeys = useSelector((state: DefaultRootState) => {
    if (mixHighlight && resourceType === 'material') {
      return contentStore.selectors.remarksById(resourceId)(state);
    }
    return noteStore.selectors
      .listByResource(
        resourceType,
        resourceId,
      )(state)
      .map((id) => ({
        type: 'note',
        id,
      }));
  });

  const handleAdd = useCallback(
    (html: string) => {
      newNoteRef.current?.clear();
      dispatcher.note.add({
        html,
        relation: { resourceType, resourceId },
      });
    },
    [resourceType, resourceId],
  );

  const handleRemove = useCallback((id: number, options?: { force?: boolean }) => {
    dispatcher.note.remove({
      id,
      force: options?.force,
    });
  }, []);

  const handleSave = useCallback((id: number, html: string) => {
    dispatcher.note.update({
      id,
      html,
    });
  }, []);

  return (
    <>
      <ScrollBox>
        <Box position="sticky" top={0} left={2} right={0} height={4} ml={1} zIndex={2} bgcolor="var(--joy-palette-background-body)" />
        <Box display="flex" flexDirection="column" gap={2.5} my={2} pb={4} ml={3} mr={2}>
          <NewNote ref={newNoteRef} onAdd={handleAdd} isLoading={isLoading} />
          {itemKeys.map(({ type, id }) => (
            <Fragment key={`${type}:${id}`}>
              {type === 'highlight' && <ItemHighlight id={id} />}
              {type === 'note' && <ItemNote id={id} onSave={handleSave} onRemove={handleRemove} />}
            </Fragment>
          ))}
        </Box>
      </ScrollBox>
    </>
  );
};

export default memo(Notes, (prevProps, nextProps) => isEqual(prevProps, nextProps));
