import { memo, useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Box, Typography } from '@mui/joy';

import { useNavigate } from 'navigation/hooks';

import { styleSheetToCss } from 'utils';
import Picture from 'components/Picture';

import { useAvatarText } from '../hooks/usePeparedInitials';
import type { PersonProps } from '../types';

const formatName = (name: string | undefined, surname: string | undefined): string => {
  const formatCamelCase = (str: string) => str.replace(/([a-z])([A-Z])/g, '$1 $2');
  const formatAtSymbol = (str: string) => str.replace('@', ' @');

  const formattedName = formatCamelCase(formatAtSymbol(name || ''));
  const formattedSurname = formatCamelCase(formatAtSymbol(surname || ''));

  return `${formattedName} ${formattedSurname}`.trim();
};

const Person = (props: PersonProps) => {
  const { style, id, login, type, name, surname, photo, profession } = props;

  const [symbolAvatar, setSymbolAvatar] = useState(!photo);
  const navigate = useNavigate();

  const handlePress = useCallback(() => {
    if (type === 'User' && login) {
      navigate('Profile', { login });
    }
    if (type === 'Author' && id) {
      navigate('Author', { id });
    }
  }, [type, login, id]);

  const initials = useAvatarText(`${name} ${surname}`);

  const classes = useStyle();

  const handleErrorImageLoad = useCallback(() => {
    setSymbolAvatar(true);
  }, []);

  return (
    <Box className={classes.Person} style={styleSheetToCss(style)} onClick={handlePress}>
      {symbolAvatar && (
        <Box className={classes.initialsContainer} sx={{ backgroundColor: (theme) => theme.palette.primary.softDisabledColor }}>
          <Typography className={classes.initials}>{initials}</Typography>
        </Box>
      )}
      {!symbolAvatar && <Picture onError={handleErrorImageLoad} source={photo} size="medium" aspectRatio={1} width={96} height={96} radius={42} />}
      <Typography className={classes.name}>{formatName(name, surname)}</Typography>
      {profession && (
        <Typography color="neutral" className={classes.profession}>
          {profession}
        </Typography>
      )}
    </Box>
  );
};

const useStyle = createUseStyles({
  Person: {
    width: '6em',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  initials: {
    color: '#ffffff',
    fontSize: '2em',
  },
  name: {
    marginTop: '0.625em',
    marginBottom: '0.3125em',
    paddingBottom: '0.25rem',
    lineHeight: '1em',
    fontSize: '1.1em',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal',
  },
  shortName: {
    whiteSpace: 'nowrap',
  },
  profession: {
    lineHeight: '1.2em',
    fontSize: '0.6875em',
    marginTop: '0',
  },
  initialsContainer: {
    width: '6em',
    height: '6em',
    borderRadius: '2.6250em',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
});

export default memo(Person);
