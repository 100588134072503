import { memo, useCallback, type ChangeEvent, type MouseEvent, type FC } from 'react';
import { isEqual } from 'lodash';
import { Box, Button, Radio, RadioGroup, ToggleButtonGroup, type BoxProps, Tooltip } from '@mui/joy';

import { usePerson } from 'hooks';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as askStore from 'store/nodes/ask';
import { type ScopeType } from 'store/nodes/ask/slice';

const ScopeSwitcher: FC<BoxProps> = (props) => {
  const { sx, ...rest } = props;
  const dispatcher = useDispatcher();

  const { person } = usePerson('User', 'my');
  const scope = useSelector(askStore.selectors.scope);

  const handleScopeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      return;
    }
    dispatcher.ask.setScope({
      scope: event.target.value as ScopeType,
    });
  }, []);

  const handleScopeMobileChange = useCallback((event: MouseEvent<HTMLElement>, value: ScopeType | null) => {
    if (!value) {
      return;
    }
    dispatcher.ask.setScope({ scope: value });
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={6}
      sx={{
        '@media (max-width: 599px)': {
          gap: 0.75,
        },
        ...sx,
      }}
      {...rest}
    >
      <Box display="flex" flexDirection="row" alignSelf="center" gap={2}>
        <ToggleButtonGroup
          defaultValue="Web"
          variant="soft"
          size="sm"
          sx={{
            '--Button-minHeight': '24px',
            '--joy-fontSize-sm': '12px',
            '--joy-fontWeight-lg': 400,
            '--ButtonGroup-radius': '40px',
            '@media (min-width: 600px)': {
              display: 'none',
            },
          }}
          onChange={handleScopeMobileChange}
          value={scope}
          orientation="horizontal"
        >
          <Button value="web">Web</Button>
          <Button value="research">Research</Button>
          <Button disabled={!person} value="library-fulltext">
            Library
          </Button>
          <Button value="global-fulltext">IKI</Button>
        </ToggleButtonGroup>
        <RadioGroup
          orientation="horizontal"
          defaultValue="Web"
          value={scope}
          sx={{
            '@media (max-width: 599px)': {
              display: 'none',
            },
          }}
          onChange={handleScopeChange}
        >
          <Tooltip arrow variant="outlined" size="sm" title="Web search for quick answers" disableInteractive>
            <Radio label="Web" color="neutral" value="web" />
          </Tooltip>
          <Tooltip
            arrow
            variant="outlined"
            size="sm"
            title={
              <>
                Advanced research assistant to automate your research – slow,
                <br />
                but high quality, searches in your library first, then in web
              </>
            }
            disableInteractive
          >
            <Radio label="Research" color="neutral" value="research" />
          </Tooltip>
          <Tooltip arrow variant="outlined" size="sm" title="Quick answers based on the knowledge in your library" disableInteractive>
            <Radio disabled={!person} label="Library" color="neutral" value="library-fulltext" />
          </Tooltip>
          <Tooltip arrow variant="outlined" size="sm" title="Query the all public knowledge in IKI" disableInteractive>
            <Radio label="IKI" color="neutral" value="global-fulltext" />
          </Tooltip>
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default memo(ScopeSwitcher, (prevProps, nextProps) => isEqual(prevProps, nextProps));
