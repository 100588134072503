import { type FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { type BoxProps, Box, IconButton, Tooltip } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as copilotStore from 'widgets/Copilot/store';
import Icon from 'ui/Icon';

const ShowToggle: FC<BoxProps> = (props) => {
  const { ...rest } = props;

  const dispatcher = useDispatcher();

  const isShow = useSelector(copilotStore.selectors.rightBarIsShow);

  const handleCopilot = useCallback(() => {
    if (isShow) {
      dispatcher.copilot.close();
    } else {
      dispatcher.copilot.open();
    }
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('resize'));
    }, 10);
  }, [isShow]);

  return (
    <Box {...rest}>
      <Tooltip arrow variant="outlined" size="sm" title={isShow ? 'Close AI Copilot' : 'Open AI Copilot'} disableInteractive>
        <IconButton size="sm" color="neutral" variant="soft" onClick={handleCopilot}>
          <Icon name={isShow ? 'arrow-right-long-to-line' : 'arrow-left-long-to-line'} fw weight="light" color="primary" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default memo(ShowToggle);
