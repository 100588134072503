import { type FC, type SyntheticEvent, memo, useCallback, useRef, useMemo } from 'react';
import { isEqual } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { Box, type BoxProps } from '@mui/joy';

import { delay } from 'utils';
import { useGetShare } from 'hooks';
import { useNavigate } from 'navigation/hooks';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import libraryIdBySpaceId from '../../store/selectors/libraryIdBySpaceId';

import useItemFocusHandler from '../../model/useItemFocusHandler';
import { useFolderRoute } from '../../model/folderRoute';
import isEntityIdIsRoot from '../../model/isEntityIdIsRoot';
import { parseItemId } from '../../model/itemIdParser';
import useTree from './model/useTree';
import useContextMenu, { type UseContentMenuParamsActionContext } from './model/useContextMenu';

import NewSpaceButton from './ui/NewSpaceButton';
import SpaceTreeView, { type SpaceTreeViewRef } from '../../elements/SpaceTreeView';

interface MenuTreeProps extends BoxProps {}

const MenuTree: FC<MenuTreeProps> = (props) => {
  const dispatcher = useDispatcher();

  const spaceTreeViewRef = useRef<SpaceTreeViewRef>(null);
  const { spaceId: currentSpaceId, folderId: currentFolderId } = useFolderRoute();
  const currentLibraryId = useSelector(libraryIdBySpaceId(currentSpaceId));
  const navigate = useNavigate();
  const { expandedItems, canMoveItemToNewPosition, handleExpandedItemsChange, handleItemPositionChange } = useTree();
  const share = useGetShare();

  const { ContextMenu, handleContextMenu } = useContextMenu({
    onAction: useCallback(
      async (event: SyntheticEvent, context: UseContentMenuParamsActionContext) => {
        console.log('DDD', context);
        if (context.name === 'Edit' && context.entityId === 'space') {
          dispatcher.modal.open('SpaceManageData', { mode: 'edit', spaceId: context.spaceId });
        }
        if (context.name === 'Permissions' && context.entityId === 'space') {
          dispatcher.modal.open('SpacePermission', { spaceId: context.spaceId });
        }
        if (context.name === 'Delete' && context.entityId === 'space') {
          dispatcher.spaceList.delete({ id: context.spaceId });
        }
        if (context.name === 'Rename' && context.entityId !== 'space') {
          spaceTreeViewRef.current?.getInteractionsBridge()?.getTreeItemInteractions(context.itemId)?.toggleItemEditing();
        }
        if (context.name === 'Delete' && context.entityId !== 'root') {
          dispatcher.spaceResource.remove({ resourceIds: [context.entityId] });
        }
        if (context.name === 'New') {
          const { spaceId, entityId } = parseItemId(context.itemId);
          const parentId = ['space', currentLibraryId].includes(entityId) ? 'root' : entityId;
          const newId = `new-item-${uuidV4()}`;
          const newItemId = `${spaceId}::${newId}`;
          const api = spaceTreeViewRef.current?.getApi();
          api?.setItemExpansion(event, context.itemId, true);
          dispatcher.spaceResource.createFolder({ spaceId, parentId, position: 'last', markerId: newId });
          await delay(50);
          api?.focusItem(event, newItemId);
          api?.getItemDOMElement(newItemId)?.scrollIntoView({ behavior: 'smooth' });
          await delay(10);
          spaceTreeViewRef.current?.getInteractionsBridge()?.getTreeItemInteractions(newItemId)?.toggleItemEditing();
        }
        if (context.name === 'Share') {
          const { spaceId, entityId } = parseItemId(context.itemId);
          if (entityId === 'space' || entityId === currentLibraryId) {
            share({ type: 'Space', id: spaceId });
          } else {
            console.log('{ type: \'Folder\', id: entityId }', { type: 'Folder', id: entityId });
            share({ type: 'Folder', id: entityId });
          }
        }
        if (context.name === 'TogglePrivate' && 'newValue' in context) {
          const { spaceId, entityId } = parseItemId(context.itemId);
          if (entityId === 'space') {
            dispatcher.spaceList.update({ id: spaceId, data: { isPrivate: context.newValue } });
          } else {
            dispatcher.spaceResource.updateFolder({ id: entityId, data: { isPrivate: context.newValue } });
          }
        }
      },
      [currentLibraryId, share],
    ),
    onOpen: useCallback(() => {
      dispatcher.spaceResource.cancelCreateFolderAll({ markerIdPattern: /^new-item-.*/ });
    }, []),
  });

  const handleItemLabelChangingSave = useCallback((itemId: string, newLabel: string) => {
    const { entityId } = parseItemId(itemId);
    if (/^new-item/.test(entityId)) {
      dispatcher.spaceResource.confirmCreateFolder({ markerId: entityId, title: newLabel || 'New folder' });
    } else {
      dispatcher.spaceResource.updateFolder({ id: entityId, data: { title: newLabel || 'Folder' } });
    }
  }, []);

  const handleItemLabelChangingCancel = useCallback((itemId: string) => {
    const { entityId } = parseItemId(itemId);
    if (/^new-item/.test(entityId)) {
      dispatcher.spaceResource.cancelCreateFolder({ markerId: entityId });
    }
  }, []);

  const { handler: selectHandler } = useItemFocusHandler(
    useCallback(
      (spaceId, folderId) => {
        if (/^new-item/.test(folderId) || folderId === 'space' || !folderId) {
          return;
        }
        if (folderId === 'space') {
          return;
        }
        const isRoot = isEntityIdIsRoot(spaceId, folderId);
        const nextFolderId = isRoot ? 'root' : folderId;
        if (currentSpaceId && currentFolderId && currentSpaceId === spaceId && currentFolderId === nextFolderId) {
          return;
        }
        navigate('Space', { spaceId, folderId: nextFolderId });
      },
      [navigate, currentSpaceId, currentFolderId],
    ),
  );

  const selectedItems = useMemo(
    () => `${currentSpaceId}::${currentFolderId?.replace('root', currentLibraryId || 'root')}`,
    [currentSpaceId, currentFolderId, currentLibraryId],
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" {...props}>
      <SpaceTreeView
        ref={spaceTreeViewRef}
        enableReordering
        enableEditing
        selectedItems={selectedItems}
        onSelectedItemsChange={selectHandler}
        defaultExpandedItems={expandedItems}
        onExpandedItemsChange={handleExpandedItemsChange}
        onItemPositionChange={handleItemPositionChange}
        canMoveItemToNewPosition={canMoveItemToNewPosition}
        onContextMenu={handleContextMenu}
        onItemLabelChangingSave={handleItemLabelChangingSave}
        onItemLabelChangingCancel={handleItemLabelChangingCancel}
      />
      <NewSpaceButton />
      <ContextMenu />
    </Box>
  );
};

export default memo(MenuTree, (prevProps, nextProps) => isEqual(prevProps, nextProps));
