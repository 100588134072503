import { memo } from 'react';
import { isEqual } from 'lodash';

import { type LinkToContentType } from 'app/entities';

import HorizontalSwiper from 'widgets/HorizontalSwiper';
import Card from 'widgets/Card';

type CollectionsSwiperProps = {
  items: LinkToContentType[];
};

const CollectionsSwiper = (props: CollectionsSwiperProps) => {
  const { items } = props;

  return (
    <HorizontalSwiper
      className="swiperCollection"
      breakpoints={{
        320: {
          slidesPerView: 1.25,
          spaceBetween: 16,
        },
        499: {
          slidesPerView: 1.5,
          spaceBetween: 16,
        },
        560: {
          slidesPerView: 2.5,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 2.5,
          spaceBetween: 16,
        },
        1280: {
          slidesPerView: 4.5,
          spaceBetween: 16,
        },
      }}
    >
      {items.map((item) => (
        <Card
          key={`${item.resourceType}-${item.resourceId}`}
          id={item.resourceId}
          type={item.resourceType}
          hasDescription
          hasQueue
          hasTags
          hasLink
          hasAuthor
          hasIndexed
          tagLimit={3}
          descriptionLineLimit={3}
          sx={{ mb: 4 }}
        />
      ))}
    </HorizontalSwiper>
  );
};

export default memo(CollectionsSwiper, (prevProps, nextProps) => isEqual(prevProps, nextProps));
