import { type FC, memo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { isEqual } from 'lodash';
import { Box, type BoxProps, Card, CardActions, Typography } from '@mui/joy';

import * as userStore from 'store/nodes/user';
import * as materialStore from 'store/nodes/content';

import Icon from 'ui/Icon';
import Avatar from 'components/Avatar';

type ItemHighlightProps = {
  id: number;
} & Omit<BoxProps, 'id'>;

const ItemHighlight: FC<ItemHighlightProps> = (props) => {
  const { id, sx } = props;

  const data = useSelector(materialStore.selectors.remark(id));
  const user = useSelector(userStore.selectors.getById(data?.user?.id));

  return (
    <Card
      variant="soft"
      color="neutral"
      size="sm"
      sx={{
        borderRadius: 26,
        marginTop: 2,
        '--Avatar-size': '24px',
        '.MuiIconButton-root': {
          opacity: 0,
          transition: '300ms opacity ease',
        },
        '&:hover .MuiIconButton-root': {
          opacity: 1,
        },
        ...sx,
      }}
    >
      <Box display="flex" p={0.5} pb={0} fontSize={16}>
        <Box
          sx={{
            borderLeft: `4px solid var(--joy-palette-highlight-borderColor)`,
            borderRadius: 2,
          }}
        />
        <Box pl={1}>{data?.text}</Box>
      </Box>
      <CardActions sx={{ minHeight: 36, '--Card-padding': 0 }}>
        <Box display="flex" gap={1} ml={0.5} mr="auto" alignItems="center">
          <Avatar user={user} vars={{ AvatarSize: '24px', Background: 2 }} />
          <Typography fontSize={12} fontWeight={500}>
            {`${user?.name || 'User'} posted`}
          </Typography>
          <Box height={6} width={6} overflow="hidden" sx={{ '& > i': { transform: 'translateY(-11px)' } }}>
            <Icon name="period" weight="solid" />
          </Box>
          <Typography fontSize={12} fontWeight={400}>
            {moment(data?.createdAt).fromNow()}
          </Typography>
        </Box>
      </CardActions>
    </Card>
  );
};

export default memo(ItemHighlight, (prevProps, nextProps) => isEqual(prevProps, nextProps));
