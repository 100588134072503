import { cancel, put, type SagaReturnType, takeLatest } from 'redux-saga/effects';

import network from 'lib/network';
import sourceEntityToSpaceEntity, { type SourceEntityType } from 'screens/Space/model/sourceEntityToSpaceEntity';
import { select, call } from 'store/utils/saga/effects';

import { actions } from '../slices/resource';
import Alert from 'components/Alert';

export const config = {
  action: actions.updateFolder.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.updateFolder>) {
  const { id } = action.payload;
  const { title, description, isPrivate } = action.payload.data;

  const folder = yield* select((state) => state.spaceResource.data?.[id]);
  const libraryId = yield* select((state) => state.spaceList.data?.[folder.spaceId]?.libraryId);
  if (!folder || folder.__typename !== 'Folder' || !libraryId) {
    yield put(actions.updateFolderDone({ id }));
    yield cancel();
    return;
  }

  yield put(
    actions.set({
      id,
      data: {
        title,
        description,
        isPrivate,
        __typename: 'Folder',
      },
      mode: 'extend',
    }),
  );

  const { data, hasError, errors } = yield* call(() =>
    network
      .request<SourceEntityType>(`/stack-2/user/team-space/${folder.spaceId}/folder/${folder.id}`)
      .body({
        title: typeof title !== 'undefined' ? title : folder.title,
        description: typeof description !== 'undefined' ? description : folder.description,
        isPrivate: typeof isPrivate !== 'undefined' ? isPrivate : folder.isPrivate,
        type: 'FOLDER',
      })
      .post(),
  );

  if (hasError || !data) {
    Alert.error(`Update folder failed.`);
    yield put(actions.set({ id, data: folder, mode: 'extend' }));
    yield cancel();
    return;
  }

  yield put(actions.set({ id, data: sourceEntityToSpaceEntity(libraryId, data), mode: 'extend' }));
  yield put(actions.updateFolderDone({ id }));
}
