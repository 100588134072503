import { type FC, type PropsWithChildren, memo, useMemo } from 'react';
import { Box, type BoxProps } from '@mui/joy';
import Icon from 'ui/Icon';

interface FolderIconProps extends BoxProps {
  url?: string | null;
}

const FolderIcon: FC<PropsWithChildren<FolderIconProps>> = (props) => {
  const { url, ...rest } = props;

  if (!url) {
    return null;
  }

  if (/svg:\/\//.test(url)) {
    return (
      <Box display="flex" width="1.25rem" height="1.25rem" alignItems="center" justifyContent="center" fontSize={17.4} {...rest}>
        <Icon name={url.replace('svg://', '')} weight="thin" size="1x" />
      </Box>
    );
  }

  return (
    <Box position="relative" width="1.25rem" height="1.25rem" borderRadius="calc(var(--joy-radius-md) / 2)" {...rest}>
      <Box
        width="100%"
        height="100%"
        component="img"
        loading="lazy"
        src={url}
        borderRadius="calc(var(--joy-radius-md) / 2)"
        sx={{ objectFit: 'cover' }}
      />
      <Box
        position="absolute"
        width="100%"
        height="100%"
        left={0}
        top={0}
        borderRadius="calc(var(--joy-radius-md) / 2)"
        sx={{ boxShadow: 'inset 0 0 4px rgba(var(--joy-shadowChannel))', opacity: 0.15 }}
      />
    </Box>
  );
};

export default memo(FolderIcon);
