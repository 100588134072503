import { memo, type PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';

import { Box, useTheme } from '@mui/joy';

import { usePlusContext } from '../../context';

const Body = (props: PropsWithChildren) => {
  const { children } = props;

  const theme = useTheme();
  const context = usePlusContext();

  const classes = useStyle({
    fontFamily: theme.fontFamily.body,
    backgroundColor: theme.palette.background.level1,
    variant: context.variant,
  });

  return <Box className={classes.Body}>{children}</Box>;
};

const useStyle = createUseStyles<
  'Body',
  {
    fontFamily?: string;
    backgroundColor?: string;
    variant?: 'dropdown' | 'panel' | null;
  }
>({
  Body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderRadius: (props) => (props.variant === 'dropdown' ? '1em' : 0),
    padding: '1.5em',
    backgroundColor: (props) => (props.variant === 'dropdown' ? props.backgroundColor : 'transparent'),
    fontFamily: (props) => props.fontFamily,
  },
});

export default memo(Body);
