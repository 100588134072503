import { memo, useCallback, useMemo, useState } from 'react';
import { type StyleProp, type ViewStyle, type GestureResponderEvent, StyleSheet, View } from 'react-native';
import { Typography } from '@mui/joy';

import { useSelector } from 'react-redux';

import { unit } from 'utils';
import { useNavigate, useRoute } from 'navigation/hooks';
import { isCommunityPeersByUserScreen } from 'navigation/guards';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as menuStore from 'store/nodes/menu';
import * as userProfile from 'store/nodes/user';

import Icon from 'ui/Icon';

import Button from 'components/Button';
import Avatar from 'components/Avatar';

import MenuButton from '../../elements/MenuButton';

type FollowPanelPanelProps = {
  style?: StyleProp<ViewStyle>;
  onAddPress?: (event: GestureResponderEvent) => void;
};

const FollowPanelPanel = (props: FollowPanelPanelProps) => {
  const { style, onAddPress } = props;
  const route = useRoute<'CommunityPeers' | 'News'>();
  const navigate = useNavigate();
  const dispatcher = useDispatcher();

  const [isShowMore, setShowMore] = useState<boolean>(false);

  const userId = useSelector(userProfile.selectors.getIdByLogin('my'));
  const followUsers = useSelector(menuStore.selectors.panelFollow);

  const handleMore = useCallback(() => {
    setShowMore(!isShowMore);
  }, [isShowMore]);

  const handlePersonPress = useCallback((event: GestureResponderEvent, context: { userLogin: string }) => {
    dispatcher.search.clear();
    navigate('CommunityPeers', { user: context.userLogin });
  }, []);

  const list = useMemo(() => {
    const collapsedMax = 6;
    const items = isShowMore ? followUsers : followUsers.slice(0, collapsedMax);
    return {
      items,
      hasMore: followUsers.length > collapsedMax,
      showCount: items.length,
      totalCount: followUsers.length,
    };
  }, [isShowMore, followUsers]);

  if (!userId) {
    return null;
  }

  return (
    <View style={style}>
      <View style={styles.header}>
        <Typography fontSize={14} fontWeight={400} textColor="text.secondary">
          Following
        </Typography>
        <Button onPress={onAddPress} type="button-icon" variant="text" width={24} height={24}>
          <Icon name="plus" fw weight="light" size="xs" color="primary" />
        </Button>
      </View>
      {list.items.map((item) => (
        <MenuButton
          key={item.id}
          context={{ userLogin: item.login }}
          onPress={handlePersonPress}
          iconLeft={<Avatar user={item} vars={{ AvatarSize: '24px', Background: 3 }} />}
          text={item.name}
          isSelected={isCommunityPeersByUserScreen(route) && route.params?.user === item.login}
        />
      ))}
      {list.hasMore && (
        <MenuButton
          onPress={handleMore}
          iconLeft={<Icon name={isShowMore ? 'angle-up' : 'angle-down'} fw={24} weight="light" color="primary" size="sm" />}
          text={isShowMore ? 'Show less' : `Show ${list.totalCount - list.showCount} more`}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: unit(4),
    paddingHorizontal: unit(20),
  },
});

export default memo(FollowPanelPanel);
