import { memo, useCallback } from 'react';
import { Box, Typography } from '@mui/joy';
import { usePerson, useResponsive } from 'hooks';
import { unit } from 'utils';

import { useNavigate, useRoute } from 'navigation/hooks';

import Icon from 'components/LegacyIcon';
import Avatar from 'components/Avatar';
import ListLayoutSwitcher from 'components/ListLayoutSwitcher';

import { useDispatcher } from 'store/utils/redux/hooks';

const TopBarDesktop = () => {
  const route = useRoute<'CommunityPeers'>();
  const navigate = useNavigate();
  const responsive = useResponsive();
  const dispatcher = useDispatcher();
  const { person } = usePerson('User', route.params?.user);

  const handleUserPress = useCallback(() => {
    if (!person?.login) {
      return;
    }
    navigate('Profile', { login: person?.login });
  }, [person?.login]);

  const handleSubscribe = useCallback(() => {
    dispatcher.modal.open('SearchUser');
  }, []);

  if (responsive.is.tabletL) {
    return null;
  }

  return (
    <Box my={2}>
      <Box display="flex" flexDirection="row" alignItems="center" ml={-0.2}>
        {!person && <Icon size={28} name="MenuCommunityOutline" />}
        <Box onClick={handleUserPress} display="flex" flexDirection="row" alignItems="center" sx={{ cursor: 'pointer' }}>
          {person && <Avatar user={person} />}
          {!!person && (
            <Typography fontSize={22} fontWeight="bolder" ml={1}>
              {`${person.name}'s`}
              <Typography color="neutral" fontSize={22} fontWeight="normal">
                {' '}
                recent activity
              </Typography>
            </Typography>
          )}
        </Box>
        {!person && (
          <Typography fontSize={22} fontWeight="bolder" ml={0.5}>
            Peers feed
            <Typography fontSize={22} fontWeight="normal" color="neutral">
              {' '}
              see what your peers find interesting
            </Typography>
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between" mt={0.5}>
        <Typography fontSize={17}>
          <Typography color="primary" fontWeight={500} onClick={handleSubscribe} sx={{ cursor: 'pointer' }}>
            subscribe
          </Typography>
          {' to more people.'}
        </Typography>
        <ListLayoutSwitcher stateKey="peers.list.mode" style={{ marginTop: unit(-2), marginRight: unit(-4) }} />
      </Box>
    </Box>
  );
};

export default memo(TopBarDesktop);
