import { forwardRef, memo, useCallback, useImperativeHandle, useRef } from 'react';
import { isEqual } from 'lodash';
import { Box, ButtonGroup, Card, type CardProps, CardActions, IconButton, CircularProgress } from '@mui/joy';

import { useEditorState } from 'hooks';

import { type EditorInputMethodsType } from 'ui/EditorInput';
import Icon from 'ui/Icon';
import EditorInput from 'ui/EditorInput';
import { trimHtml } from 'widgets/Notes/model/trimHtml';

export interface NewNoteRef {
  clear: () => void;
}

export interface NewNoteProps extends CardProps {
  onAdd?: (text: string) => void;
  isLoading?: boolean;
}

const NewNote = forwardRef<NewNoteRef, NewNoteProps>((props, forwardedRef) => {
  const { onAdd, isLoading, sx, ...rest } = props;

  const editorRef = useRef<EditorInputMethodsType | null>(null);

  const { html, hasContent, onChange } = useEditorState();

  const handleAdd = useCallback(() => {
    if (!hasContent) {
      return;
    }
    onAdd?.(trimHtml(html));
  }, [onAdd, hasContent, html]);

  useImperativeHandle(
    forwardedRef,
    () => ({
      clear: () => {
        editorRef.current?.set('<p></p>');
      },
    }),
    [],
  );

  return (
    <Card
      variant="soft"
      size="sm"
      sx={{
        borderRadius: 26,
        ...sx,
      }}
      {...rest}
    >
      <CardActions sx={{ minHeight: 32, '--Card-padding': 0, alignItems: 'flex-end' }}>
        <Box display="flex" flexDirection="column" gap={1} ml={0.5} my={0.5} fontSize={16} flex={1} alignItems="stratch">
          <EditorInput ref={editorRef} onChange={onChange} placeholder="Add new note" onSubmit={handleAdd} disabled={isLoading} />
        </Box>
        <ButtonGroup
          variant="soft"
          color="neutral"
          spacing={1}
          sx={{
            '--ButtonGroup-radius': '50%',
            '--ButtonGroup-separatorSize': 0,
          }}
        >
          <IconButton onClick={handleAdd} sx={{ my: -0.25 }} disabled={!hasContent || isLoading}>
            <Icon name="plus" color="inherit" />
            {isLoading && (
              <CircularProgress
                color="primary"
                size="md"
                sx={{
                  position: 'absolute',
                }}
              />
            )}
          </IconButton>
        </ButtonGroup>
      </CardActions>
    </Card>
  );
});

export default memo(NewNote, (prevProps, nextProps) => isEqual(prevProps, nextProps));
