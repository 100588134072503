import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Button, DialogContent, DialogTitle, Modal, ModalDialog, Stack, Divider } from '@mui/joy';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import Icon from 'ui/Icon';

import { useGetShare } from 'hooks';

import * as playlistStore from 'store/nodes/playlist';

import UserSelect from 'widgets/CollectionShare/components/UserSelect';
import UserList from 'widgets/CollectionShare/components/UserList';
import SelectInput from 'widgets/CollectionShare/ui/SelectInput';

type ShareModalProps = {
  open: boolean;
  resourceId: number;
  onClose: () => void;
};

const options = [
  { value: 'anyone', label: 'Anyone' },
  { value: 'private', label: 'Private' },
];

const ShareModal = (props: ShareModalProps) => {
  const { open, onClose, resourceId } = props;
  const dispatcher = useDispatcher();

  const data = useSelector(playlistStore.selectors.dataById(resourceId));

  const [selectedUser, setSelectedUser] = useState<number | string>('');
  const [permissions, setPermissions] = useState(0);
  const [privacy, setPrivacy] = useState(data?.isPrivate ? 'private' : 'anyone');
  const [shouldReset, setShouldReset] = useState(false);

  const isLoading = useSelector(playlistStore.selectors.isLoadingPermission(selectedUser, permissions, resourceId));

  const share = useGetShare();

  const handleShare = useCallback(() => {
    share(data);
  }, [share, data]);

  const handleUserChange = useCallback((value: number | string) => {
    setSelectedUser(value);
  }, []);

  const handlePermissionsChange = useCallback((newPermissions: string) => {
    if (!newPermissions) {
      return;
    }
    setPermissions(Number(newPermissions));
  }, []);

  const handleSubmit = useCallback(() => {
    if (!selectedUser || !permissions || !resourceId) {
      return;
    }
    dispatcher.playlist.createPermission({
      userId: selectedUser,
      permissionId: permissions,
      collectionId: resourceId,
    });
    setPermissions(0);
    setShouldReset(true);
  }, [permissions, resourceId]);

  const startDecoratorRender = useMemo(() => {
    if (privacy === 'anyone') {
      return <Icon name="lock-open" size="sm" weight="light" fw />;
    }
    if (privacy === 'private') {
      return <Icon name="lock" size="sm" weight="light" fw />;
    }
    return undefined;
  }, [privacy]);

  const handleChangePrivacy = useCallback(
    (e: any, value: string) => {
      setPrivacy(value);

      if (!resourceId || !data) {
        return;
      }

      const isPrivateBoolean = value === 'private';

      dispatcher.playlist.updateInBackground({
        id: resourceId,
        data: {
          title: data.title,
          description: data?.description,
          cover: data?.cover,
          isPrivate: isPrivateBoolean,
        },
      });
    },
    [data, resourceId, dispatcher.playlist],
  );

  useEffect(() => {
    if (shouldReset) {
      setShouldReset(false);
    }
  }, [shouldReset]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog minWidth={530}>
        <DialogTitle sx={{ mb: 0.5 }}>Share collection</DialogTitle>
        <DialogContent>
          <Stack gap={1} alignItems="flex-start" justifyContent="space-between" flexDirection="row">
            <UserSelect reset={shouldReset} onUserChange={handleUserChange} onPermissionsChange={handlePermissionsChange} onEnter={handleSubmit} />
            <Button disabled={!permissions} loading={isLoading} onClick={handleSubmit} color="neutral" type="submit">
              Invite
            </Button>
          </Stack>
          <Stack gap={1} alignItems="flex-start" justifyContent="space-between" flexDirection="row" marginTop={1}>
            <SelectInput startDecorator={startDecoratorRender} options={options} value={privacy} onChange={handleChangePrivacy} />
          </Stack>
          <Divider sx={{ mt: 0.5, mb: 1 }} />
          {data?.permissions?.map((permission) => (
            <UserList
              key={permission.user.id}
              userId={permission.user.id}
              permissionId={permission.permission.id.toString()}
              resourceId={resourceId}
            />
          ))}
          <Divider sx={{ my: 1 }} />
          <Stack alignItems="flex-start" justifyContent="space-between" flexDirection="row">
            <Button variant="plain" onClick={handleShare} startDecorator={<Icon color="inherit" name="link" fw weight="light" />}>
              Copy link
            </Button>
          </Stack>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default memo(ShareModal);
