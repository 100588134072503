import { type SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { type TreeViewItemReorderPosition } from '@mui/x-tree-view-pro/internals/plugins/useTreeViewItemsReordering/useTreeViewItemsReordering.types';
import { useDispatcher } from 'store/utils/redux/hooks';
import { parseItemId } from 'screens/Space/model/itemIdParser';

const getExpandedItemIds = () => {
  const stringifyItemIds = localStorage.getItem('space/menu/expanded');
  if (!stringifyItemIds) {
    return [];
  }
  try {
    return JSON.parse(stringifyItemIds);
  } catch {
    return [];
  }
};

const useTree = () => {
  const dispatcher = useDispatcher();

  const [expandedItems, setExpandedItems] = useState<string[]>(getExpandedItemIds());

  const handleExpandedItemsChange = useCallback((event: SyntheticEvent, itemIds: string[]) => {
    localStorage.setItem('space/menu/expanded', JSON.stringify(itemIds));
  }, []);

  const canMoveItemToNewPosition = useCallback(
    (params: { itemId: string; oldPosition: TreeViewItemReorderPosition; newPosition: TreeViewItemReorderPosition }) => {
      if (/::(space|root)/.test(params.itemId)) {
        return false;
      }
      if (params.newPosition.parentId?.includes('space') && params.newPosition.index === 0) {
        return false;
      }
      if (params.oldPosition.parentId?.includes('space') && params.newPosition.parentId?.includes('root')) {
        return false;
      }
      if (params.newPosition.parentId === null) {
        return false;
      }
      return true;
    },
    [],
  );

  const handleItemPositionChange = useCallback(
    (params: { itemId: string; oldPosition: TreeViewItemReorderPosition; newPosition: TreeViewItemReorderPosition }) => {
      const { entityId } = parseItemId(params.itemId);
      const { parentId: newParentKey, index: newIndex } = params.newPosition;
      if (!newParentKey) {
        return;
      }
      const { spaceId: newSpaceId, entityId: newParentId } = parseItemId(newParentKey.replace('space', 'root'));
      dispatcher.spaceResource.updatePosition({
        entityId,
        newPosition: {
          spaceId: newSpaceId,
          parentId: newParentId,
          index: newIndex,
        },
        treeName: 'structure',
      });
    },
    [],
  );

  return {
    expandedItems,
    canMoveItemToNewPosition,
    handleExpandedItemsChange,
    handleItemPositionChange,
  };
};

export default useTree;
