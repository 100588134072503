import { useMemo } from 'react';
import Copilot from 'widgets/Copilot';

const usePanelsRender = (spaceId?: number, folderId?: string | number) =>
  useMemo(() => {
    return [
      ...(spaceId && folderId
        ? [
            {
              label: 'AI Copilot',
              value: 'copilot',
              component: <Copilot variant="material" resourceId={2} />,
            },
            // {
            //   label: 'Remarks',
            //   value: 'notes',
            //   component: <Notes resourceType="material" resourceId={2} mixHighlight />,
            // },
          ]
        : []),
    ];
  }, [spaceId, folderId]);

export default usePanelsRender;
