import { memo, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/joy';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as playlistStore from 'store/nodes/playlist';

import PermissionSelect from 'widgets/CollectionShare/components/PermissionSelect';

import Avatar from 'components/Avatar';
import { usePerson } from 'hooks';

type userListProps = {
  userId: number;
  permissionId: string;
  resourceId: number;
};

const UserList = (props: userListProps) => {
  const { userId, permissionId, resourceId } = props;
  const [permission, setPermission] = useState(permissionId);
  const { person: myPerson } = usePerson('User', 'my');
  const { person: otherPerson } = usePerson('User', userId);

  const dispatcher = useDispatcher();
  const isLoading = useSelector(playlistStore.selectors.isLoadingPermission(userId, permission, resourceId));

  const handleSelectChange = useCallback(
    (_: any, newValue: string) => {
      setPermission(newValue);
      if (!userId || !newValue || !resourceId) {
        return;
      }
      if (newValue === 'delete') {
        dispatcher.playlist.deletePermission({
          userId,
          collectionId: resourceId,
          permissionId,
        });
        setPermission(permissionId);
        return;
      }
      dispatcher.playlist.updatePermission({
        userId,
        permissionId: Number(newValue),
        collectionId: resourceId,
      });
    },
    [userId, resourceId, permissionId],
  );

  if (!otherPerson) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" gap={1} ml={0.5} mr="auto" alignItems="center">
        <Avatar user={otherPerson} vars={{ Background: 2 }} />
        <Typography fontSize={15} fontWeight={500}>
          {`${otherPerson?.name || ''} ${otherPerson?.surname}` || 'User'}
        </Typography>
      </Box>
      <PermissionSelect disabled={userId === myPerson?.id} isLoading={isLoading} hasDelete value={permission} onChange={handleSelectChange} />
    </Box>
  );
};

export default memo(UserList);
