import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import type { SpaceFolderType } from 'app/entities';

import type { SpaceStructureStore } from '../types';

const initialState: SpaceStructureStore = {
  loading: {},
};

const structureSlice = createSlice({
  name: 'space/structure',
  initialState,
  reducers: {
    reset: (state) => {
      const resetState = cloneDeep(initialState);
      state.loading = resetState.loading;
    },
    init: () => undefined,
    initDone: () => undefined,
    loadFull: (state, action: PayloadAction<{ spaceId: number; folderId: SpaceFolderType['parentId'] }>) => undefined,
    loadFullDone: (state, action: PayloadAction<{ spaceId: number; folderId: SpaceFolderType['parentId'] }>) => undefined,
    loadPage: (state, action: PayloadAction<{ spaceId: number; parentIds: SpaceFolderType['parentId'][] }>) => {
      const { spaceId, parentIds } = action.payload;

      if (!state.loading[spaceId]) {
        state.loading[spaceId] = {};
      }

      parentIds.forEach((parentId) => {
        const parentKey = parentId || 'root';
        state.loading[spaceId][parentKey] = true;
      });
    },
    loadPageDone: (state, action: PayloadAction<{ spaceId: number; parentIds: SpaceFolderType['parentId'][] }>) => {
      const { spaceId, parentIds } = action.payload;

      if (!state.loading[spaceId]) {
        state.loading[spaceId] = {};
      }

      parentIds.forEach((parentId) => {
        const parentKey = parentId || 'root';
        state.loading[spaceId][parentKey] = false;
      });
    },
  },
});

export const { reducer, actions } = structureSlice;
