import { useParams } from 'navigation/hooks';
import { usePerson } from 'hooks';

const useLayoutName = () => {
  const params = useParams<'Library'>();
  const { person } = usePerson('User', 'my');

  if (person?.isLibraryEmpty) {
    return 'empty';
  }

  if (params.interaction === 'search') {
    return 'search';
  }

  return 'regular';
};

export default useLayoutName;
