import { type FC, memo, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';

import Avatar from 'components/Avatar';
import { usePerson } from 'hooks';

import PermissionSelect from '../ui/PermissionSelect';

type UserItemProps = {
  userId: number;
  permissionId: string;
  resourceId?: number;
};

const UserItem: FC<UserItemProps> = (props) => {
  const { userId, permissionId, resourceId } = props;
  const [permission, setPermission] = useState(permissionId);
  const { person: me } = usePerson('User', 'my');
  const { person: teammate } = usePerson('User', userId);

  const dispatcher = useDispatcher();

  const handleSelectChange = useCallback(
    (_: any, newValue: string) => {
      setPermission(newValue);
      if (!userId || !newValue || !resourceId) {
        return;
      }
      if (newValue === 'delete') {
        dispatcher.spacePermission.delete({
          userId,
          spaceId: resourceId,
        });
        setPermission(permissionId);
        return;
      }
      dispatcher.spacePermission.update({
        userId,
        permissionId: Number(newValue),
        spaceId: resourceId,
      });
    },
    [userId, resourceId, permissionId],
  );

  if (!teammate) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="row" gap={1.5} alignItems="center" alignSelf="stretch" sx={{ '--Avatar-size': '48px', userSelect: 'none' }}>
      <Avatar user={teammate} vars={{ Background: 2 }} />
      <Box display="flex" flexDirection="column">
        <Typography fontSize={16} fontWeight={400} lineHeight="var(--joy-lineHeight-xs)">
          {`${teammate?.name || ''} ${teammate?.surname}` || 'User'}
        </Typography>
        {teammate?.email && (
          <Typography fontSize={16} fontWeight={400} sx={{ opacity: 0.5 }} lineHeight="var(--joy-lineHeight-xs)">
            {teammate.email}
          </Typography>
        )}
      </Box>
      <Box flex={1} />
      <PermissionSelect disabled={userId === me?.id} hasDelete value={permission} onChange={handleSelectChange} />
    </Box>
  );
};

export default memo(UserItem);
