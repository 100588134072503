import { useMemo } from 'react';

export const parseItemId = (itemId: string) => {
  const [spaceId, entityId] = itemId.split('::');
  return {
    spaceId: Number(spaceId),
    entityId: String(entityId),
  } as {
    spaceId: number;
    entityId: 'space' | 'root' | string;
  };
};

export const useParseItemId = (itemId: string) => {
  return useMemo(() => parseItemId(itemId), [itemId]);
};
