import { useMemo } from 'react';
import { useColorScheme, useTheme } from '@mui/joy';

const useScrollStyle = () => {
  const theme = useTheme();
  const { mode } = useColorScheme();

  return useMemo(
    () => ({
      overflowY: 'scroll',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '0.5rem',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',
        borderRadius: '0.5rem',
        border: '2px solid transparent',
        backgroundClip: 'content-box',
      },
      '&:hover::-webkit-scrollbar-thumb:hover': {
        backgroundColor: theme.palette.primary.softDisabledColor,
      },
      '&:hover::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.background.level3,
      },
    }),
    [mode],
  );
};

export default useScrollStyle;
