import { type FC, type ReactNode, memo, useCallback, useEffect, useState, useRef } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/joy';
import { useDispatcher } from 'store/utils/redux/hooks';
import useSpaceById from 'screens/Space/model/useSpaceById';
import useScrollStyle from 'hooks/useScrollStyle';

import EveryoneItem, { type EveryoneItemOnChange } from '../ui/EveryoneItem';
import UserItem from '../ui/UserItem';
import UserSelect from '../ui/UserSelect';

type PermissionLayoutProps = {
  spaceId?: number;
  header?: ReactNode;
  onCancel: () => void;
};

const PermissionLayout: FC<PermissionLayoutProps> = (props) => {
  const { spaceId, header, onCancel } = props;
  const dispatcher = useDispatcher();

  const scrollStyle = useScrollStyle();
  const { space, spaceTeam } = useSpaceById(spaceId);

  const contentRef = useRef<HTMLDivElement>(null);

  const isLoading = false;

  const [selectedUserId, setSelectedUserId] = useState<number | string>('');
  const [permissionId, setPermissionId] = useState<number>(0);
  const [shouldReset, setShouldReset] = useState(false);

  const handleUserChange = useCallback((value: number | string) => {
    setSelectedUserId(value);
  }, []);

  const handlePermissionChange = useCallback((newPermission: string) => {
    if (!newPermission) {
      return;
    }
    setPermissionId(Number(newPermission));
  }, []);

  const handleSubmit = useCallback(() => {
    if (!selectedUserId || !permissionId || !spaceId) {
      return;
    }
    dispatcher.spacePermission.create({
      spaceId,
      userId: Number(selectedUserId),
      permissionId,
    });
    setPermissionId(0);
    setSelectedUserId('');
    setShouldReset(true);
  }, [permissionId, spaceId]);

  useEffect(() => {
    if (!spaceTeam.prevCount) {
      return;
    }
    if (spaceTeam.count > spaceTeam.prevCount) {
      const { current: content } = contentRef;
      if (!content) {
        return;
      }
      content.scrollTo({ top: content.scrollHeight, behavior: 'smooth' });
    }
  }, [spaceTeam]);

  const handlePrivacyChange = useCallback<EveryoneItemOnChange>(
    (newPrivacy) => {
      if (!spaceId) {
        return;
      }
      dispatcher.spaceList.update({
        id: spaceId,
        data: {
          isPrivate: newPrivacy === 'private',
        },
      });
    },
    [spaceId],
  );

  useEffect(() => {
    if (shouldReset) {
      setShouldReset(false);
    }
  }, [shouldReset]);

  return (
    <>
      <DialogTitle>
        <Box display="flex" flexDirection="column" mt={-1} flex={1}>
          {header}
          <Typography fontSize={13} fontWeight={400}>
            Share with your team
          </Typography>
          <Typography fontSize={13} fontWeight={400} sx={{ opacity: 0.5 }}>
            Add your teammates and create a shared ownership for this space
          </Typography>
          <Box display="flex" gap={1} alignItems="flex-start" justifyContent="space-between" flexDirection="row" mt={1}>
            <UserSelect reset={shouldReset} onUserChange={handleUserChange} onPermissionsChange={handlePermissionChange} onEnter={handleSubmit} />
            <Button variant="solid" disabled={!permissionId} loading={isLoading} onClick={handleSubmit} color="neutral" type="submit">
              Invite
            </Button>
          </Box>
          <Typography fontSize={13} fontWeight={400} mt={2}>
            Your team ({spaceTeam.count})
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent ref={contentRef} sx={{ ...scrollStyle }}>
        <Box display="flex" flexDirection="column" gap={2.5} mt={0.5} sx={{ '--joy-palette-background-surface': 'transparent' }}>
          <EveryoneItem spaceTitle={space?.title} value={space?.isPrivate ? 'private' : 'public'} onChange={handlePrivacyChange} />
          {spaceTeam.permissions.map((permission) => (
            <UserItem key={permission.user.id} userId={permission.user.id} permissionId={permission.permission.id.toString()} resourceId={spaceId} />
          ))}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 0, gap: 1 }}>
        <Box />
        <Button variant="solid" color="neutral" size="sm" onClick={onCancel} sx={{ fontWeight: 400, fontSize: 13 }}>
          Done
        </Button>
      </DialogActions>
    </>
  );
};

export default memo(PermissionLayout);
