import network from 'lib/network';
import type { MaterialType, PaginationResult, PaginationInput, CursorType } from 'app/entities';
import { requestCursorModifier } from 'utils';

export default async function fetchUserProfileContent(
  login?: string,
  pagination?: PaginationInput,
): Promise<PaginationResult<number>> {
  if (!login) {
    return {
      data: {
        items: [],
        paginationInfo: {
          nextCursor: null,
          hasNext: false,
        },
      },
      error: null,
    };
  }

  const query: Record<string, any> = {
    pageSize: pagination?.pageSize || 10,
  };
  if (pagination?.cursor) {
    query.cursor = pagination.cursor;
  }

  const { data, hasError, errors } = await network
    .request<Pick<MaterialType, 'id'>[]>(`/stack-1/user/profile/${login}/content`)
    .query(query)
    .get(requestCursorModifier());

  if (hasError || !data) {
    return {
      data: null,
      error: errors?.[0] || null,
    };
  }

  return {
    data: {
      items: data.items.map((item) => item.id),
      paginationInfo: {
        nextCursor: data.nextUrl,
        hasNext: Boolean(data.nextUrl),
      },
    },
    error: null,
  };
}
