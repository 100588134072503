import { type DefaultRootState } from 'react-redux';

const defaultUserSpace = (state: DefaultRootState) => {
  let resultId = 0;
  Object.values(state.spaceList.data || {}).forEach((space) => {
    if (resultId === 0) {
      resultId = space.id;
    }
    if (resultId > space.id) {
      resultId = space.id;
    }
  });
  return resultId;
};

export default defaultUserSpace;
