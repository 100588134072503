import { memo, useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { type StyleProp, type ViewStyle, StyleSheet, useWindowDimensions } from 'react-native';

import type { RelationVariants } from 'app/entities';

import { usePerson, useResponsive } from 'hooks';
import { useSelector, useDispatcher } from 'store/utils/redux/hooks';
import * as relationStore from 'store/nodes/relation';

import FeedList, { type FeedListMethods } from 'components/FeedList';
import { unit } from 'utils';
import { View } from 'components/Themed';
import SlimProfileItem from 'components/SlimProfileItem';
import ButtonSwitcher from 'components/ButtonSwitcher';

import { Box, Typography } from '@mui/joy';
import Header from './elements/Header';

export type RelationsProps = {
  login?: string;
  slim?: boolean;
  onPress?: (login: string, id: number) => void;
};

const Relations = (props: RelationsProps) => {
  const { login, slim, onPress } = props;
  const dispatcher = useDispatcher();
  const dimension = useWindowDimensions();
  const responsive = useResponsive();

  const [listType, setListType] = useState<RelationVariants>('subscriptions');

  const flatListRef = useRef<FeedListMethods>(null);

  const { person } = usePerson('User', login);
  const list = useSelector(relationStore.selectors.list(listType));
  const listMeta = useSelector(relationStore.selectors.listMeta(listType));

  const handleRefresh = useCallback(() => {
    if (!login) {
      return;
    }
    dispatcher.relation.load(login);
  }, [login]);

  const handleNextPage = useCallback(() => {
    dispatcher.relation.loadNextPage(listType);
  }, [listType]);

  const handleListTypeChange = useCallback((value: string) => {
    setListType(value as RelationVariants);
  }, []);

  useEffect(() => {
    flatListRef.current?.scrollToStart();
  }, [listType]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const sequenceFinal = useMemo(() => {
    const wireframes = [
      { id: 'w-1', type: 'wireframe' },
      { id: 'w-2', type: 'wireframe' },
      { id: 'w-3', type: 'wireframe' },
      { id: 'w-4', type: 'wireframe' },
      { id: 'w-5', type: 'wireframe' },
      { id: 'w-6', type: 'wireframe' },
    ];
    if (listMeta.firstPage.isLoading) {
      return wireframes;
    }
    if (listMeta.nextPage.isLoading) {
      return [...list.sequence, ...wireframes];
    }
    return list.sequence;
  }, [list.sequence, listMeta.firstPage.isLoading, listMeta.nextPage.isLoading]);

  const optionsFinal = [
    { label: 'Subscriptions', value: 'subscriptions' },
    { label: 'Subscribers', value: 'subscribers' },
  ];

  const renderItem = useCallback((item: { id: number }) => <SlimProfileItem id={item.id} onPress={onPress} />, [onPress]);

  const nameFinal = useMemo(() => {
    if (person) {
      return [person?.name, person?.surname].join(' ').trim();
    }
    return 'Guest';
  }, [person]);

  const componentStyle = useMemo(() => {
    const { height } = dimension;
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.Relations),
      height,
    };
    if (responsive.isMoreThen.mobile) {
      result.height = height - 100;
      result.paddingBottom = unit(40);
      result.borderRadius = unit(24);
    }
    return result;
  }, [responsive.isMoreThen.mobile]);

  if (slim) {
    return (
      <>
        <Box
          position="sticky"
          top={0}
          bgcolor={(theme) => theme.palette.background.level1}
          pb={1}
          mb={-1}
          zIndex={10}
          sx={{
            boxShadow: (theme) => `0 0 0 1px ${theme.palette.background.level1}`,
          }}
        >
          <Typography fontSize={22} fontWeight={500} mb={2}>
            {nameFinal}
          </Typography>
          <ButtonSwitcher tabStyle={{ flex: 1 }} items={optionsFinal} value={listType} onChange={handleListTypeChange} />
        </Box>
        {listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && list.sequence.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Typography fontSize={17}>{`No ${listType} yet`}</Typography>
          </Box>
        )}
        {(listMeta.firstPage.isLoading || listMeta.nextPage.isLoading || list.sequence.length > 0) && (
          <FeedList
            data={sequenceFinal}
            HeaderComponent={<Header />}
            ItemComponent={renderItem}
            paddingHorizontal={16}
            hasNextPage={list.paginationInfo.hasNext}
            onRefresh={handleRefresh}
            onEndReached={handleNextPage}
            style={styles.panelResult}
          />
        )}
      </>
    );
  }

  return (
    <View style={componentStyle} lightColor="#e7e7e7" darkColor="#181818" pointerEvents="auto">
      <Box mb={2}>
        <Typography fontSize={22} mb={2}>
          {nameFinal}
        </Typography>
        <ButtonSwitcher tabStyle={{ flex: 1 }} items={optionsFinal} value={listType} onChange={handleListTypeChange} />
      </Box>
      {listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && list.sequence.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Typography fontSize={17}>{`No ${listType} yet`}</Typography>
        </Box>
      )}
      {(listMeta.firstPage.isLoading || listMeta.nextPage.isLoading || list.sequence.length > 0) && (
        <FeedList
          scrollable
          data={sequenceFinal}
          HeaderComponent={<Header />}
          ItemComponent={renderItem}
          paddingHorizontal={16}
          hasNextPage={list.paginationInfo.hasNext}
          onRefresh={handleRefresh}
          onEndReached={handleNextPage}
          style={styles.panelResult}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  Relations: {
    width: '100%',
    maxWidth: unit(560),
    alignSelf: 'center',
    paddingHorizontal: unit(16),
    paddingTop: unit(40),
    paddingBottom: unit(16),
    borderTopLeftRadius: unit(24),
    borderTopRightRadius: unit(24),
    flexDirection: 'column',
  },
  desktop: {
    paddingBottom: unit(40),
    borderRadius: unit(24),
  },
  input: {
    flex: 1,
  },
  toolbarTypes: {
    marginHorizontal: unit(-16),
    marginBottom: unit(16),
  },
  panelResult: {
    // marginLeft: unit(-16),
    // marginRight: unit(-16),
    flex: 1,
  },
  button: {
    marginLeft: unit(6),
    width: unit(44),
    height: unit(44),
    borderRadius: unit(22),
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default memo(Relations);
