import { type HTMLAttributes, type SyntheticEvent, forwardRef, memo, useEffect, useCallback } from 'react';
import {
  TreeItem2,
  useTreeItem2Utils,
  type TreeItem2Props,
  type UseTreeItem2LabelInputSlotOwnProps,
  type UseTreeItem2LabelSlotOwnProps,
} from '@mui/x-tree-view-pro';
import { useColorScheme } from '@mui/joy';

import { Label, type LabelProps, LabelInput, type LabelInputProps } from './Label';

import { useParseItemId } from '../../../model/itemIdParser';
import useFolderMeta from '../../../model/useFolderMeta';
import { useTreeViewInteractions } from '../model/treeViewItemInteractionUtils';

export interface ItemProps extends TreeItem2Props {
  onItemLabelChangingStart?: (itemId: string) => void;
  onItemLabelChangingSave?: (itemId: string, newLabel: string) => void;
  onItemLabelChangingCancel?: (itemId: string) => void;
}

const Item = forwardRef<HTMLLIElement, ItemProps>((props, ref) => {
  const { itemId, children, onItemLabelChangingStart, onItemLabelChangingSave, onItemLabelChangingCancel } = props;

  const { setTreeItemInteractions } = useTreeViewInteractions();
  const { interactions, status } = useTreeItem2Utils({
    itemId,
    children,
  });

  useEffect(() => {
    setTreeItemInteractions(itemId, interactions);
    return () => {
      setTreeItemInteractions(itemId, undefined);
    };
  }, [itemId, interactions, setTreeItemInteractions]);

  const { colorScheme } = useColorScheme();
  const { icon, isPrivate, childrenCount, type } = useFolderMeta(itemId);

  const handleContentDoubleClick = useCallback<NonNullable<UseTreeItem2LabelSlotOwnProps['onDoubleClick']>>((event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handleInputBlur = useCallback<NonNullable<UseTreeItem2LabelInputSlotOwnProps['onBlur']>>((event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handleInputKeyDown = useCallback<NonNullable<UseTreeItem2LabelInputSlotOwnProps['onKeyDown']>>((event) => {
    event.defaultMuiPrevented = true;
  }, []);

  useEffect(() => {
    if (status.editing) {
      onItemLabelChangingStart?.(itemId);
    }
  }, [status.editing, itemId]);

  const handleSaveItemLabel = useCallback(
    (event: SyntheticEvent, label: string) => {
      interactions.handleSaveItemLabel(event, label);
      onItemLabelChangingSave?.(itemId, label);
    },
    [interactions.handleSaveItemLabel, itemId],
  );

  const handleCancelItemLabelEditing = useCallback(
    (event: SyntheticEvent) => {
      interactions.handleCancelItemLabelEditing(event);
      onItemLabelChangingCancel?.(itemId);
    },
    [interactions.handleCancelItemLabelEditing, itemId],
  );

  return (
    <TreeItem2
      {...props}
      ref={ref}
      slots={{
        label: Label,
        labelInput: LabelInput,
      }}
      slotProps={{
        root: {
          className: type === 'space' ? 'root-level' : undefined,
          itemId,
        } as HTMLAttributes<HTMLLIElement>,
        content: {
          style: {
            gap: 2,
            borderRadius: 18,
          },
        },
        iconContainer: {
          style: {
            color: `var(--joy-palette-neutral-${colorScheme === 'light' ? '500' : '300'})`,
            opacity: 0.5,
          },
        },
        label: {
          icon,
          childrenCount,
          isRoot: type === 'space',
          isPrivate,
          onDoubleClick: handleContentDoubleClick,
          editable: status.editable,
          editing: status.editing,
          toggleItemEditing: interactions.toggleItemEditing,
        } as LabelProps,
        labelInput: {
          icon,
          childrenCount,
          isRoot: type === 'space',
          isPrivate,
          onBlur: handleInputBlur,
          onKeyDown: handleInputKeyDown,
          handleSaveItemLabel,
          handleCancelItemLabelEditing,
        } as LabelInputProps,
      }}
    />
  );
});

export default memo(Item);
