import { memo, type ReactNode } from 'react';
import { CardActions } from '@mui/joy';
import type { CollectionType, MaterialType, SpaceFolderType } from 'app/entities';

import Actions from './Actions';

import type { CardType, LegacyCardType } from '../types';

type FooterProps = {
  id: number;
  type: CardType | LegacyCardType;
  data?: CollectionType | MaterialType | DocumentType | SpaceFolderType;
  isHover?: boolean;
  startActions?: ReactNode;
  hasQueue?: boolean;
  hasToCollection?: boolean;
  hasLink?: boolean;
  hasRemove?: boolean;
  hasEdit?: boolean;
};

const Footer = (props: FooterProps) => {
  const { id, data, type, isHover, startActions, hasQueue, hasToCollection, hasLink, hasRemove, hasEdit } = props;

  return (
    <CardActions
      sx={{
        minHeight: 32,
        '--Card-padding': 0,
        justifyContent: 'space-between',
        '@container (width < 400px )': {
          flexDirection: 'column',
          gap: 1.5,
        },
        '@container (width > 400px )': {
          flexDirection: 'row',
        },
      }}
    >
      <Actions
        isHover={isHover}
        resourceId={id}
        resourceType={type}
        startElements={startActions}
        hasQueue={hasQueue}
        hasToCollection={hasToCollection}
        hasLink={hasLink}
        hasRemove={hasRemove}
        hasEdit={hasEdit}
      />
    </CardActions>
  );
};

export default memo(Footer);
