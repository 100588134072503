import { DateTime } from 'luxon';
import { usePerson } from 'hooks';

const useCurrentPromo = () => {
  const { person } = usePerson('User', 'my');

  if (!person || !person.createdAt) {
    return {
      hasPromo: false,
    };
  }

  const userCreatedAt = DateTime.fromISO(person.createdAt);

  const promoDate = DateTime.fromISO('2024-11-10T00:00:00');

  if (userCreatedAt < promoDate) {
    return {
      hasPromo: true,
      description: '40% Perpetual discount for early adopters',
      code: 'EARLY40',
    };
  }

  return {
    hasPromo: false,
    description: 'Use black Friday code -40%',
    code: 'FRIDAY40',
  };
};

export default useCurrentPromo;
