import { cancel, takeEvery, type SagaReturnType, put } from 'redux-saga/effects';

import { getRoute } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';
import * as navigationStore from 'store/nodes/navigation';

import * as spaceResource from '../slices/resource';

export const config = {
  action: navigationStore.actions.runPageHandler.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof navigationStore.actions.runPageHandler>) {
  const route = yield* call(() => getRoute<'Space'>());
  if (!route.isScreen('Space')) {
    yield cancel();
    return;
  }
  const { spaceId, folderId } = route.params;

  yield put(spaceResource.actions.loadPage({ spaceId: Number(spaceId), folderIds: [folderId], mode: 'first' }));
}
