import { type ReactElement, memo, useMemo, useCallback } from 'react';
import { Box, Button, Typography } from '@mui/joy';
import { StyleSheet } from 'react-native';
import pluralize from 'pluralize';

import { useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';
import * as authorStore from 'store/nodes/author';

import { useGetOpenLink, usePerson, useThemeColor } from 'hooks';
import { guard, unit } from 'utils';
import { View } from 'components/Themed';
import Avatar from 'components/Avatar';

interface InfoProps {
  userLogin?: string;
  authorId?: number;
  onSubscribe?: () => void;
  onUnsubscribe?: () => void;
  onRelationsPress?: () => void;
}

const Info = (props: InfoProps): ReactElement => {
  const { userLogin, authorId, onSubscribe, onUnsubscribe, onRelationsPress } = props;

  const imageBackgroundColor = useThemeColor({
    light: '#a6a6a6',
    dark: '#4E4E53',
  });
  const textBackgroundColor = useThemeColor({
    light: '#ffffff',
    dark: '#232326',
  });
  const openLink = useGetOpenLink();

  const { person } = usePerson(userLogin ? { type: 'User', login: userLogin } : { type: 'Author', id: authorId });

  const isSubscripting = useSelector((state) => {
    if (guard.isUser(person)) {
      return userStore.selectors.isSubscripting(person?.login)(state);
    }
    return null;
  });

  const nameFinal = useMemo(() => {
    if (person) {
      return [person?.name, person?.surname].join(' ').trim();
    }
    return 'Guest';
  }, [person]);

  const loginFinal = useMemo(() => {
    if (guard.isUser(person)) {
      return `@${person.login}`;
    }
    if (guard.isAuthor(person) && person.name) {
      return `@${person.name.toLowerCase()}`;
    }
    return null;
  }, [person]);

  const handleOpenWebsite = useCallback(() => {
    if (!guard.isUser(person) || !person.websiteLink) {
      return;
    }
    openLink(person.websiteLink);
  }, [person]);

  if (!guard.isUser(person) && !guard.isAuthor(person)) {
    return (
      <View style={styles.Info}>
        <View style={styles.row}>
          <View style={styles.full}>
            <View style={[styles.wfName, { backgroundColor: textBackgroundColor }]} />
            <View style={[styles.wfFollowers, { backgroundColor: textBackgroundColor }]} />
            <View style={[styles.wfLogin, { backgroundColor: textBackgroundColor }]} />
          </View>
          <View>
            <View style={[styles.photo, { backgroundColor: imageBackgroundColor }]} />
          </View>
        </View>
        {userLogin && <View style={[styles.wfButton, { backgroundColor: textBackgroundColor }]} />}
      </View>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: '425px',
        pt: 4,
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box flex={1}>
          <Typography fontSize={22} fontWeight="bolder">
            {nameFinal}
          </Typography>
          {guard.isUser(person) && (
            <Box display="flex" flexDirection="row" alignItems="center" mt={1} onClick={onRelationsPress}>
              <Typography
                fontSize={16}
                sx={{ cursor: 'pointer', transition: 'opacity 200ms ease', '&:hover': { opacity: 0.7 } }}
              >{`${person?.subscribersCount} ${pluralize('Follower', person?.subscribersCount)}`}</Typography>
              <View style={styles.dot} lightColor="#000000" darkColor="#ffffff" />
              <Typography
                fontSize={16}
                sx={{ cursor: 'pointer', transition: 'opacity 200ms ease', '&:hover': { opacity: 0.7 } }}
              >{`${person?.subscriptionsCount} Following`}</Typography>
            </Box>
          )}
          {!!loginFinal && <Typography fontSize={15}>{loginFinal}</Typography>}
        </Box>
        <Box>
          <Avatar user={person} vars={{ AvatarSize: '80px' }} />
        </Box>
      </Box>
      {guard.isUser(person) && (!!person?.profession || !!person?.websiteLink) && (
        <View style={[styles.full, styles.block2]}>
          {!!person.profession && (
            <Typography fontSize={17} fontWeight="bolder">
              {person.profession}
            </Typography>
          )}
          {!!person.websiteLink && (
            <Typography
              sx={{
                cursor: guard.isLink(person?.websiteLink) ? 'pointer' : 'default',
              }}
              onClick={guard.isLink(person?.websiteLink) ? handleOpenWebsite : undefined}
              fontSize={13}
              color="neutral"
            >
              {person.websiteLink}
            </Typography>
          )}
        </View>
      )}
      <Box mt={2}>
        {guard.isUser(person) && person?.isSubscribed === false && (
          <Button variant="outlined" onClick={onSubscribe} loading={isSubscripting || false} size="sm">
            Follow
          </Button>
        )}
        {guard.isUser(person) && person?.isSubscribed === true && (
          <Button variant="outlined" onClick={onUnsubscribe} loading={isSubscripting || false} size="sm">
            Following
          </Button>
        )}
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  Info: {
    maxWidth: unit(425),
    paddingTop: unit(32),
  },
  row: {
    flexDirection: 'row',
  },
  full: {
    flex: 1,
  },
  padding: {
    paddingTop: unit(28),
  },
  photo: {
    width: unit(80),
    height: unit(80),
    borderRadius: unit(40),
  },
  alignCenter: {
    alignItems: 'center',
  },
  emptyPhoto: {
    width: unit(80),
    height: unit(80),
    borderRadius: unit(40),
    borderStyle: 'solid',
    borderColor: '#ffffff',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  action: {
    marginLeft: unit(6),
    width: unit(44),
    height: unit(44),
    borderRadius: unit(22),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#4e4e53',
  },
  block2: {
    marginTop: unit(16),
  },
  profession: {},
  websiteLink: {},
  social: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: unit(8),
  },
  dot: {
    width: 2,
    height: 2,
    borderRadius: 1,
    marginHorizontal: unit(8),
  },
  followBtn: {
    marginTop: unit(24),
    borderRadius: unit(5),
    alignSelf: 'flex-start',
  },
  wfName: {
    marginTop: unit(4),
    height: unit(20),
    width: unit(80),
  },
  wfFollowers: {
    marginTop: unit(16),
    height: unit(12),
    width: unit(150),
  },
  wfLogin: {
    marginTop: unit(8),
    height: unit(12),
    width: unit(60),
  },
  wfButton: {
    marginTop: unit(24),
    height: unit(40),
    width: unit(120),
    borderRadius: unit(5),
  },
});

export default memo(Info);
