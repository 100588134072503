import { type FC, memo, useCallback, useMemo } from 'react';
import { Box, type BoxProps, Typography } from '@mui/joy';
import Icon from 'ui/Icon';
import SelectInput, { type SelectInputProps } from '../ui/SelectInput';

const options = [
  { value: 'public', label: 'Accessed' },
  { value: 'private', label: 'Not accessed' },
];

export interface EveryoneItemOnChange {
  (value: 'public' | 'private'): void;
}

export interface EveryoneItemProps extends Omit<BoxProps, 'onChange'> {
  spaceTitle?: string;
  value?: 'public' | 'private' | string;
  onChange?: EveryoneItemOnChange;
}

const EveryoneItem: FC<EveryoneItemProps> = (props) => {
  const { spaceTitle, value, onChange } = props;

  const handleChangePrivacy = useCallback(
    (event: any, newValue: 'public' | 'private') => {
      onChange?.(newValue);
    },
    [onChange],
  );

  return (
    <Box display="flex" flexDirection="row" gap={1.5} alignItems="center" alignSelf="stretch">
      <Box
        width={48}
        height={48}
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="var(--joy-palette-background-level2)"
        borderRadius="50%"
      >
        <Icon name="user-group" weight="thin" />
      </Box>
      <Typography fontSize={16} fontWeight={400}>
        Everyone at {spaceTitle} Space
      </Typography>
      <Box flex={1} />
      <SelectInput options={options} value={value} onChange={handleChangePrivacy} />
    </Box>
  );
};

export default memo(EveryoneItem);
