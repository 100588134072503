import { memo, useMemo } from 'react';

import { useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import Card from 'widgets/Card';
import AuthorBlock from './AuthorBlock';

interface ItemProps {
  eventType: 'created' | 'completed' | 'commented';
  eventTime?: string;
  resourceId: number;
  resourceType: 'Material' | 'Collection' | 'Document';
  userId: number;
  comment?: string;
}

const Item = (props: ItemProps) => {
  const { eventType, eventTime, resourceId, resourceType, userId, comment } = props;

  const user = useSelector(userStore.selectors.getById(userId));

  const eventDescription = useMemo(() => {
    let result = '';
    if (eventType === 'created') {
      result = `${resourceType} added`;
    }
    if (eventType === 'completed') {
      result = `${resourceType} completed${comment ? `: ${comment}` : ''}`;
    }
    if (eventType === 'commented') {
      result = `New comment: ${comment}`;
    }
    return result;
  }, [eventType, resourceType]);

  return (
    <Card
      id={resourceId}
      type={resourceType}
      hasToCollection
      hasAuthor
      hasQueue
      hasLink
      hasTags
      tagLimit={6}
      descriptionLineLimit={8}
      AuthorBlockComponent={!!user && <AuthorBlock data={user} eventType={eventType} eventTime={eventTime} eventDescription={eventDescription} />}
    />
  );
};

export default memo(Item);
