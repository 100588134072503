import { put, all, take, takeLatest, delay } from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';

import { actions as spaceRootActions } from '../slices/root';
import { actions as spaceListActions } from '../slices/list';
import { actions as spacePermissionActions } from '../slices/permission';
import { actions as spaceStructureActions } from '../slices/structure';
import * as selectors from '../selectors';

export const config = {
  action: spaceRootActions.init.type,
  method: takeLatest,
};

export function* func() {
  yield put(spaceListActions.load());
  yield put(spacePermissionActions.loadAvailable());
  yield all([take(spaceListActions.loadDone.type), take(spacePermissionActions.loadAvailableDone.type)]);
  yield put(spaceRootActions.initDone());

  yield delay(10);
  const spaces = yield* select(selectors.list);
  for (const space of spaces) {
    yield put(spaceStructureActions.loadFull({ spaceId: space.id, folderId: 'root' }));
  }
}
