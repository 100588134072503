import { createSelector } from 'reselect';
import type { DefaultRootState } from 'react-redux';

import type { SpaceType, SpaceEntityType, SpaceEntityKey } from 'app/entities';
import { guard } from 'utils';

const getSpacesData = (state: DefaultRootState) => state.spaceList.data;
const getSpaceHidden = (state: DefaultRootState) => state.spaceList.hidden;
const getStructureData = (state: DefaultRootState) => state.spaceResource.data;
const getStructureTree = (state: DefaultRootState) => state.spaceResource.treeStructure;

const selectSpaces = createSelector([getSpacesData, getSpaceHidden], (spaceData, spaceHidden): SpaceType[] => {
  if (!spaceData) {
    return [];
  }
  return Object.values(spaceData).filter((item) => !spaceHidden.includes(item.id));
});

interface TreeViewBaseItem {
  id: string;
  label: string;
  children?: TreeViewBaseItem[];
  __typename?: 'space' | 'root' | 'node';
}

function buildFoldersTree(
  spaceId: number,
  parentId: string | 'root',
  tree: Record<number, Record<string, SpaceEntityKey[]>>,
  data: Record<number | string, SpaceEntityType>,
): TreeViewBaseItem[] {
  const children = tree[spaceId]?.[parentId];
  if (!children || children.length === 0) {
    return [];
  }

  return children
    .map((child) => {
      const entity = data[child.id];
      if (!entity || !guard.isSpaceFolder(entity)) {
        return undefined;
      }
      const isRoot = /^LIBRARY_ROOT$/.test(entity.title);
      return {
        id: `${spaceId}::${`${entity.id}`.replace(/^[0-9]+\./, '')}`,
        label: (isRoot ? 'Library' : entity.title) || 'Folder',
        children: buildFoldersTree(spaceId, String(entity.id), tree, data),
        __typename: isRoot ? 'root' : 'node',
      };
    })
    .filter(Boolean) as TreeViewBaseItem[];
}

const selectTree = createSelector([selectSpaces, getStructureTree, getStructureData], (spaces, structureTree, structureData): TreeViewBaseItem[] => {
  return spaces.map((space) => {
    const spaceId = space.id;
    const spaceNode: TreeViewBaseItem = {
      id: `${spaceId}::space`,
      label: space.title || `Space ${spaceId}`,
      children: buildFoldersTree(spaceId, 'root', structureTree, structureData),
      __typename: 'space',
    };

    return spaceNode;
  });
});

export default selectTree;
