import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, IconButton, type BoxProps } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as searchStore from 'store/nodes/search';

import { TextInput } from 'components/Form';
import Icon from 'ui/Icon';
import { isLibraryScreen } from 'navigation/guards';
import { useRoute } from 'navigation/hooks';
import { isEqual } from 'lodash';

import { useFontSize, useBorderRadius, useButtonSize, usePadding, usePlaceholder } from './hooks';

interface SearchInputProps extends BoxProps {
  size?: 'small' | 'medium' | 'large';
  autoFocus?: boolean;
  placeholder?: string;
}

const SearchInput = (props: SearchInputProps) => {
  const { size = 'medium', autoFocus = false, placeholder = 'Search...', ...rest } = props;
  const dispatcher = useDispatcher();
  const route = useRoute<'Library'>();

  const value = useSelector(searchStore.selectors.filterText);
  // const method = useSelector(searchStore.selectors.method);
  const isProcessed = route.params.interaction === 'search' && route.params.text === value;

  const borderRadius = useBorderRadius(size);
  const { inputVerticalPaddings, inputHorizontalPadding } = usePadding(size, value);
  const buttonSize = useButtonSize(size);
  // const placeholder = usePlaceholder(method);
  const fontSize = useFontSize(size);

  const handleChange = useCallback((text: string) => {
    dispatcher.search.setFilterText(text, false, false);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (isProcessed) {
      return;
    }
    dispatcher.search.setFilterText(value, true, true);
  }, [value, isProcessed]);

  return (
    <Box position="relative" flexDirection="row" width="80%" {...rest}>
      <TextInput
        autoFocus={autoFocus}
        placeholder={isLibraryScreen(route) ? 'Search by title in my library' : placeholder}
        style={{ flex: 1 }}
        inputFont={{
          size: fontSize as any,
        }}
        radius={borderRadius}
        pickerStyle={[
          inputVerticalPaddings,
          inputHorizontalPadding,
          {
            backgroundColor: 'var(--joy-palette-background-level1)',
          },
        ]}
        onChange={handleChange}
        value={value}
        onEnter={handleSubmit}
      />
      <Box position="absolute" flexDirection="row" right="4px" top="4px" gap="4px">
        {!!value && !isProcessed && (
          <IconButton
            variant="plain"
            onClick={handleSubmit}
            color="neutral"
            sx={{
              width: buttonSize,
              height: buttonSize,
              borderRadius: '50%',
              minHeight: 'unset',
            }}
          >
            <Icon
              name="up"
              fw
              weight="light"
              color="var(--joy-palette-neutral-plainColor)"
              sx={{
                fontSize,
              }}
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default memo(SearchInput, (prevProps, nextProps) => isEqual(prevProps, nextProps));
