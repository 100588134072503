import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useStripe, useElements, CardElement, AddressElement } from '@stripe/react-stripe-js';
import { type Stripe } from '@stripe/stripe-js';

import { usePerson } from 'hooks';
import network from 'lib/network';

import Alert from 'components/Alert';

type AddressValue = {
  name?: string;
  email?: string;
  address?: {
    line1?: string;
    line2: string | null;
    city?: string;
    state?: string;
    postal_code?: string;
    country?: string;
  };
};

type RetrievedProps = {
  id: string;
  last4: string;
  cardType: string;
  expMonth: number;
  expYear: number;
};

type PaymentStateProps = {
  planId: string;
  promoCode?: string | null;
  onSubmitPayment: (params: { planId: string; promoCode?: string | null; paymentMethodId: string; stripe: Stripe; hasTrial?: boolean }) => void;
  hasTrial?: boolean;
};

const usePaymentData = (props: PaymentStateProps) => {
  const { planId, promoCode, onSubmitPayment, hasTrial } = props;

  const stripe = useStripe();
  const elements = useElements();

  const { person } = usePerson('User', 'my');
  const [cardComplete, setCardComplete] = useState<boolean>(false);
  const [addressComplete, setAddressComplete] = useState<boolean>(false);
  const [retrievedPayment, setRetrievedPayment] = useState<RetrievedProps | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [addressValue, setAddressValue] = useState<AddressValue>({});

  const cardElementMounted = useRef(false);
  const addressElementMounted = useRef(false);

  const getRetrievePayment = useCallback(async () => {
    const { data, errors } = await network
      .request<RetrievedProps>('/stack-2/payment/retrieve_method', {
        baseUrl: process.env.PROTECTED_API_URL || '/api',
      })
      .get();

    if (data?.id) {
      setRetrievedPayment(data);
      setAddressComplete(true);
      setCardComplete(true);
    } else {
      setRetrievedPayment(null);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!elements) {
      return () => {};
    }
    getRetrievePayment();

    const cardElement = elements.getElement(CardElement);
    const addressElement = elements.getElement(AddressElement);

    const handleCardChange = (event: any) => {
      if (!cardElementMounted.current) {
        cardElementMounted.current = true;
        return;
      }
      setCardComplete(event.complete);
    };

    if (cardElement) {
      cardElement.on('change', handleCardChange);
    }

    const handleAddressChange = async () => {
      if (!addressElementMounted.current) {
        addressElementMounted.current = true;
      } else {
        const { value, complete } = (await addressElement?.getValue()) || {};
        setAddressComplete(complete || false);
        setAddressValue(value || {});
      }
    };

    if (addressElement) {
      addressElement.on('change', handleAddressChange);
    }

    return () => {
      cardElement?.off('change', handleCardChange);
      addressElement?.off('change', handleAddressChange);
    };
  }, [elements, getRetrievePayment]);

  const onCreatePaymentMethod = async () => {
    try {
      if (!stripe || !elements) {
        throw new Error('Stripe has not loaded yet.');
      }

      if (!addressValue || !person) {
        throw new Error('Address or user profile is missing.');
      }

      if (!planId) {
        throw new Error('Plan ID is missing.');
      }

      let paymentMethodId = '';

      if (retrievedPayment) {
        paymentMethodId = retrievedPayment.id;
      } else {
        const cardElement = elements.getElement(CardElement);

        if (!cardElement) {
          throw new Error('Card Element not found');
        }

        const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: addressValue.name,
            email: person.email,
            address: addressValue.address,
          },
        });

        if (paymentError) {
          throw paymentError;
        }

        if (!paymentMethod) {
          throw new Error('Payment method was not created.');
        }

        paymentMethodId = paymentMethod.id;
      }

      onSubmitPayment?.({
        planId,
        promoCode,
        paymentMethodId,
        stripe,
        hasTrial,
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      Alert.error(`An error occurred: ${errorMessage}`);
    }
  };

  return useMemo(
    () => ({
      cardComplete,
      addressComplete,
      loadingStripe: loading,
      onCreatePaymentMethod,
      retrievedPayment,
    }),
    [cardComplete, addressComplete, loading, onCreatePaymentMethod, retrievedPayment],
  );
};

export default usePaymentData;
