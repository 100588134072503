import React, { type ReactElement, memo, useCallback, useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/joy';

import type { CollectionType } from 'app/entities';
import { useNavigate } from 'navigation/hooks';

import { useColorScheme, usePerson } from 'hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import Icon from 'ui/Icon';
import EmptyPanel from 'screens/Profile/elements/EmptyPanel';
import CollectionsSwiper from 'screens/Profile/elements/Playlists/ui/CollectionsSwiper';

const defaultProps = {
  editable: false,
};

type PlaylistsProps = {
  userLogin?: string;
  bigLabel?: boolean;
  editable?: boolean;
} & typeof defaultProps;

const Playlists = (props: PlaylistsProps): ReactElement | null => {
  const { userLogin, bigLabel, editable } = props;

  const navigate = useNavigate();
  const dispatcher = useDispatcher();

  const [isEditable, setEditable] = useState(false);

  const { person } = usePerson('User', userLogin);

  const playlistIds = person?.playlistIds || [];

  const handleOpen = useCallback((type: string, id: number) => {
    navigate('Playlist', { resourceId: id });
  }, []);

  const handleEditStart = useCallback(() => {
    setEditable(true);
  }, []);

  const handleEditCancel = useCallback(() => {
    setEditable(false);
  }, []);

  const handleAddCollectionPress = useCallback(() => {
    if (!person?.isMy) {
      return;
    }
    dispatcher.modal.open('AddorEditCollection', {
      create: {
        withItems: [
          {
            resourceType: 'material',
          },
        ],
      },
    });
  }, [person?.isMy]);

  const handleEditPlaylist = useCallback((edit: CollectionType) => {
    dispatcher.modal.open('AddorEditCollection', { edit: { id: edit?.id } });
  }, []);

  const handleDeletePlaylist = useCallback((context: CollectionType) => {
    dispatcher.playlist.remove({ id: context.id });
  }, []);

  if (!userLogin || !person) {
    return null;
  }

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography fontSize={bigLabel ? 22 : 20} fontWeight="bolder" flex={1}>
          Collections
        </Typography>
        {userLogin === 'my' && editable && (
          <Tooltip arrow variant="outlined" size="sm" title="Edit or delete collection">
            <IconButton onClick={!isEditable ? handleEditStart : handleEditCancel}>
              <Icon size="xl" name="gear" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {person.isMy && (!Array.isArray(playlistIds) || !playlistIds.length) && <EmptyPanel mt={2} sx={{ cursor: 'pointer' }} onClick={handleAddCollectionPress} text="Your collections will be here" />}
      {!person.isMy && (!Array.isArray(playlistIds) || !playlistIds.length) && <EmptyPanel mt={2} text={`${person.name} has no collections`} />}
      {Array.isArray(playlistIds) && playlistIds.length > 0 && <CollectionsSwiper playlistIds={playlistIds} isEditable={isEditable} />}
    </Box>
  );
};

Playlists.defaultProps = defaultProps;
export default memo(Playlists);
