import { type ReactNode, memo, useCallback } from 'react';
import { Box, CardContent, Tooltip, Typography } from '@mui/joy';
import Icon from 'ui/Icon';
import { type AuthorType, type SpaceFolderType, type ImageType, type UserType } from 'app/entities';

import { usePerson } from 'hooks';

import CompositePoster from 'components/CompositePoster';

import DescriptionBlock from '../ui/DescriptionBlock';
import AuthorBlock from '../ui/AuthorBlock';

type SpaceFolderProps = {
  data?: SpaceFolderType;
  posterSources?: ImageType[] | string[];
  handleAuthorPress?: (value: UserType | AuthorType | null) => void;
  hasDescription?: boolean;
  hasSummary?: boolean;
  hasAuthor?: boolean;
  descriptionLineLimit?: number;
  AuthorBlockComponent?: ReactNode;
};

const SpaceFolder = (props: SpaceFolderProps) => {
  const { data, posterSources, handleAuthorPress, AuthorBlockComponent, descriptionLineLimit, hasDescription, hasAuthor } = props;

  const { person } = usePerson('User', data?.userId);

  const handlePress = useCallback(() => {
    if (!person) {
      return;
    }
    handleAuthorPress?.(person);
  }, [person]);

  return (
    <>
      {posterSources && posterSources.length > 0 && (
        <Box
          sx={{
            '@container (width > 400px )': {
              display: 'none',
            },
          }}
        >
          <CompositePoster sources={posterSources} radius={8} aspectRatio={4 / 3} />
        </Box>
      )}
      <CardContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            sx={{
              '@container (width <= 400px )': {
                gap: 1.5,
              },
            }}
          >
            {data?.title && (
              <Typography
                fontSize={17}
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                fontWeight={600}
              >
                {data.isPrivate && (
                  <Tooltip placement="top" arrow disableInteractive variant="outlined" size="sm" title="This collection is visible only to you.">
                    <Typography component="span" fontSize={16}>
                      <Icon size="sm" marginRight={1} weight="solid" color="var(--joy-palette-primary-solidDisabledColor)" name="eye-slash" />
                    </Typography>
                  </Tooltip>
                )}
                {data?.title?.trim?.()}
              </Typography>
            )}
            {hasDescription && data?.description && (
              <DescriptionBlock ellipsizeMode="tail" numberOfLines={descriptionLineLimit || 20} text={data.description} />
            )}
            {!!AuthorBlockComponent && AuthorBlockComponent}
            {!AuthorBlockComponent && hasAuthor && !!person && <AuthorBlock onPress={handlePress} createData={data?.createdAt} data={person} />}
          </Box>
          {posterSources && posterSources.length > 0 && (
            <Box
              sx={{
                ml: 1,
                '@container (width <= 400px )': {
                  display: 'none',
                },
              }}
            >
              <CompositePoster width={120} sources={posterSources} radius={8} aspectRatio={16 / 9} />
            </Box>
          )}
        </Box>
      </CardContent>
    </>
  );
};

export default memo(SpaceFolder);
