import { forwardRef, memo, useEffect, useMemo } from 'react';
import useCounter from 'hooks/useCounter';
import CircularProgress, { type CircularProgressProps } from '@mui/joy/CircularProgress';

export type CircularProgressCounterRef = {
  stop: () => void;
  start: () => void;
};

export type CircularProgressCounterProps = Omit<CircularProgressProps, 'value'> & {
  onFinish?: () => void;
};

const CircularProgressCounter = forwardRef<CircularProgressCounterRef, CircularProgressCounterProps>((props, forwardedRef) => {
  const { onFinish } = props;
  const counter = useCounter();

  useEffect(() => {
    if (forwardedRef && 'current' in forwardedRef) {
      forwardedRef.current = {
        start: () => {
          counter.start(50, 100);
        },
        stop: () => {
          counter.stop();
        },
      };
    }
  }, []);

  useEffect(() => {
    if (counter.value !== 1) {
      return;
    }
    if (!onFinish) {
      return;
    }
    setTimeout(() => {
      onFinish();
    }, 500);
  }, [counter.value, onFinish]);

  const value = useMemo(() => {
    if (counter.value === null) {
      return 0;
    }
    return (50 - counter.value) * 2;
  }, [counter.value]);

  return <CircularProgress {...props} value={value} />;
});

export default memo(CircularProgressCounter);
