import { memo, useCallback } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/joy';
import Avatar from 'components/Avatar';

import { useNavigate } from 'navigation/hooks';
import { usePerson } from 'hooks';

const UserPanel = () => {
  const navigate = useNavigate();

  const { person } = usePerson('User', 'my');

  const handleProfile = useCallback(() => {
    navigate('Profile', { login: 'my' });
  }, []);

  if (!person) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center" flex={1} my={1}>
      <Button
        startDecorator={<Avatar user={person} size="sm" vars={{ Background: 2 }} />}
        size="sm"
        onClick={handleProfile}
        color="neutral"
        variant="plain"
        sx={{
          '&': {
            paddingInline: 0,
          },
          '&:hover': {
            background: 'transparent',
          },
        }}
      >
        <Typography fontSize={17} fontWeight="bold">{`${person?.name}${person?.surname ? ` ${person?.surname[0]}.` : ''}`}</Typography>
      </Button>
    </Box>
  );
};

export default memo(UserPanel);
