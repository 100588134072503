import { useMemo } from 'react';
import { useRoute } from 'navigation/hooks';
import { getRoute } from 'navigation/methods';

/**
 * Общая функция, которая из spaceId и folderId вычисляет:
 *   1) spaceId (пустая строка, если не задано)
 *   2) folderId (равно 'root', если не задано)
 *   3) itemId (если folderId='root', берём spaceId, иначе 'spaceId::folderId')
 */
function parseFolderParams(
  spaceId?: number,
  folderId?: string,
): {
  spaceId: number;
  folderId: string;
  itemId: string;
} {
  const finalSpaceId = spaceId ?? NaN;
  const finalFolderId = folderId || 'root';

  return {
    spaceId: Number(finalSpaceId),
    folderId: String(finalFolderId || 'root'),
    itemId: `${finalSpaceId}::${finalFolderId}`,
  };
}

/**
 * Хук для React-компонентов.
 * Использует useRoute<'Space'> и parseFolderParams.
 */
export function useFolderRoute() {
  const { isScreen, params } = useRoute<'Space'>();
  const { spaceId, folderId } = params;

  return useMemo(() => {
    if (!isScreen('Space')) {
      return {
        spaceId: undefined,
        folderId: undefined,
        itemId: undefined,
      };
    }

    return parseFolderParams(Number(spaceId), String(folderId));
  }, [spaceId, folderId, isScreen]);
}
