import { type SyntheticEvent, type KeyboardEvent, type FC, type ChangeEventHandler, useCallback } from 'react';
import {
  TreeItem2Label,
  TreeItem2LabelInput,
  type UseTreeItem2LabelInputSlotOwnProps,
  type UseTreeItem2LabelSlotOwnProps,
} from '@mui/x-tree-view-pro';
import { Box } from '@mui/joy';

import Icon from 'ui/Icon';

import FolderIcon from './FolderIcon';

export interface LabelProps extends UseTreeItem2LabelSlotOwnProps {
  icon?: string | null;
  childrenCount?: number;
  isRoot: boolean;
  isPrivate?: boolean;
  editable: boolean;
  editing: boolean;
  toggleItemEditing: () => void;
}

export const Label: FC<LabelProps> = (props) => {
  const { icon, childrenCount, isRoot, isPrivate, editing, editable, children, toggleItemEditing, ...rest } = props;

  return (
    <TreeItem2Label
      {...rest}
      editable={editable}
      sx={{
        minHeight: 28,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        fontSize: isRoot ? 16 : 13,
        fontWeight: 400,
        color: 'var(--joy-palette-text-secondary)',
      }}
    >
      <FolderIcon url={icon} sx={{ pointerEvents: 'none' }} />
      <Box flex={1} sx={{ pointerEvents: 'none' }} mt={isRoot ? -0.25 : 0}>
        {children}
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" gap={1} mr={0.25} sx={{ pointerEvents: 'none', fontSize: 13 }}>
        {isPrivate && <Icon name="lock" size="xs" fw sx={{ opacity: 0.25 }} />}
        {childrenCount !== null && <div>{childrenCount}</div>}
      </Box>
    </TreeItem2Label>
  );
};

export interface LabelInputProps extends UseTreeItem2LabelInputSlotOwnProps {
  icon?: string;
  childrenCount?: number;
  isRoot: boolean;
  isPrivate?: boolean;
  handleCancelItemLabelEditing: (event: SyntheticEvent) => void;
  handleSaveItemLabel: (event: SyntheticEvent, label: string) => void;
  value: string;
}

export const LabelInput: FC<Omit<LabelInputProps, 'ref'>> = (props) => {
  const { isRoot, handleCancelItemLabelEditing, handleSaveItemLabel, onChange, value, ...rest } = props;

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        handleCancelItemLabelEditing(event);
      } else if (event.key === 'Enter') {
        handleSaveItemLabel(event, value);
      }
    },
    [handleCancelItemLabelEditing, handleSaveItemLabel, value],
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    onChange?.(event);
  }, []);

  return (
    <>
      <TreeItem2LabelInput
        {...rest}
        onChange={handleChange}
        value={value}
        onKeyDown={handleKeyDown}
        sx={{
          minHeight: 28,
          fontSize: isRoot ? 16 : 13,
        }}
      />
    </>
  );
};
