import { type SagaReturnType, put, takeEvery, delay, cancel } from 'redux-saga/effects';

import network from 'lib/network';
import { requestCursorModifier } from 'utils';

import { call, select } from 'store/utils/saga/effects';

import Alert from 'components/Alert';

import { actions } from '../slices/resource';
import folderHasNext from '../selectors/folderHasNext';
import folderNextCursor from '../selectors/folderNextCursor';
import spaceById from '../selectors/spaceById';

import sourceEntityToSpaceEntity, { type SourceEntityType } from '../../model/sourceEntityToSpaceEntity';

export const config = {
  action: actions.loadPage.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadPage>) {
  const { spaceId, folderIds, mode, treeName } = action.payload;
  const space = yield* select(spaceById(spaceId));

  if (!space) {
    yield put(actions.loadPageDone({ spaceId, folderIds, treeName }));
    yield cancel();
    return;
  }

  for (const parentId of folderIds) {
    const parentKey = parentId || 'root';

    let cursor: string | null = null;
    if (mode === 'next') {
      const hasNext = yield* select(folderHasNext(spaceId, parentKey));
      if (!hasNext) {
        continue;
      }
      cursor = yield* select(folderNextCursor(spaceId, parentKey));
      if (!cursor) {
        continue;
      }
    }

    const { data, errors, hasError } = yield* call(() =>
      network
        .request<SourceEntityType[]>(`/stack-2/user/team-space/${spaceId}/${parentKey}/folder`)
        .query({ pageSize: 30, ...(cursor ? { cursor } : undefined) })
        .get(requestCursorModifier()),
    );

    if (hasError || !data) {
      Alert.error(errors);
      continue;
    }

    const items = sourceEntityToSpaceEntity(space.libraryId, data.items);

    const nextCursor = data.nextCursor ?? null;
    const hasNext = data.hasNext ?? false;

    yield put(actions.setPage({ data: items, mode, treeName }));
    yield put(
      actions.setPagination({
        spaceId,
        folderId: parentKey,
        cursor: nextCursor,
        hasNext,
      }),
    );
  }

  yield delay(10);
  yield put(actions.loadPageDone({ spaceId, folderIds, treeName }));
}
