import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';
import { type CollectionPermissionType } from 'app/entities';

import Alert from 'components/Alert';
import network from 'lib/network';

import dialog from 'components/Dialog';

import { actions } from '../slices/permission';

export const config = {
  action: actions.delete.type,
  method: takeEvery,
};

const confirm = (): Promise<boolean> =>
  new Promise((resolve) => {
    dialog.show(
      {
        title: 'Confirmation',
        text: 'Are you sure want to delete\nthis user permission?',
      },
      [
        {
          label: 'Cancel',
          variant: 'outlined',
          lightColor: '#929292',
          darkColor: '#77767E',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(false);
          },
        },
        {
          label: 'Yes',
          variant: 'contained',
          lightColor: '#db3327',
          darkColor: '#db3327',
          textLightColor: '#ffffff',
          textDarkColor: '#ffffff',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(true);
          },
        },
      ],
    );
  });

export function* func(action: SagaReturnType<typeof actions.delete>) {
  const { spaceId, userId } = action.payload;

  const isConfirmed = yield* call(() => confirm());
  if (!spaceId || !userId || !isConfirmed) {
    yield put(actions.deleteDone({ spaceId, userId }));
    yield cancel();
    return;
  }

  yield put(actions.removeUserPermissions({ keys: [{ spaceId, userId }] }));

  const { data, hasError } = yield* call(() =>
    network.request<CollectionPermissionType[]>(`/stack-2/user/team-space/${spaceId}/deletePermission`).body({ user_id: userId }).delete(),
  );

  if (hasError || !data) {
    Alert.error('Error delete permission');
    yield put(actions.deleteDone({ spaceId, userId }));
    yield cancel();
    return;
  }

  yield put(actions.deleteDone({ spaceId, userId }));
}
