import { memo, useCallback } from 'react';
import { Button, Typography } from '@mui/joy';
import Icon from 'ui/Icon';
import { useDispatcher } from 'store/utils/redux/hooks';

const NewSpaceButton = () => {
  const dispatcher = useDispatcher();

  const handleCreateSpace = useCallback(() => {
    dispatcher.modal.open('SpaceManageData', { mode: 'create' });
  }, []);

  return (
    <Button
      onClick={handleCreateSpace}
      size="sm"
      variant="plain"
      color="neutral"
      sx={{ fontWeight: 400 }}
      endDecorator={<Icon name="add" mt={0.25} size="sm" />}
    >
      <Typography fontSize={14} textColor="inherit" flex={1} textAlign="left">
        Add new space
      </Typography>
    </Button>
  );
};

export default memo(NewSpaceButton);
