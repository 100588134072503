import { createSelector } from 'reselect';
import type { DefaultRootState } from 'react-redux';
import type { SpaceType } from 'app/entities';

const getSpaceData = (state: DefaultRootState) => state.spaceList.data;
const getSpaceHidden = (state: DefaultRootState) => state.spaceList.hidden;

export const selectSpaces = createSelector([getSpaceData, getSpaceHidden], (spaceData, spaceHidden): SpaceType[] => {
  if (!spaceData) {
    return [];
  }
  return Object.values(spaceData).filter((item) => !spaceHidden.includes(item.id));
});

export default selectSpaces;
