import { type CSSProperties, memo, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { type GestureResponderEvent } from 'react-native';
import { createUseStyles } from 'react-jss';
import { Typography, useTheme } from '@mui/joy';
import cn from 'classnames';

import { unit, styleSheetToCss } from 'utils';

import Icon from 'components/LegacyIcon';

import * as userProfile from 'store/nodes/user';

import type { MenuButtonProps } from './types';

const MenuButton = <CT,>(props: MenuButtonProps<CT>) => {
  const {
    style,
    styleSlim,
    href,
    selectedIcon,
    text,
    slimText,
    context,
    revertSlim,
    isSlim,
    isEnabled,
    disabled,
    isSelected,
    iconLeft,
    iconRight,
    hoverLevel,
    onPress,
  } = props;

  const theme = useTheme();
  const hoverColor = theme.palette.background[hoverLevel || 'level1'];
  const user = useSelector(userProfile.selectors.getMy);
  const classes = useStyles({
    disabled,
    hoverColor: hoverColor || 'red',
    revertSlim,
  });

  const handleClick = useCallback(
    (event: { preventDefault: () => void }) => {
      if (disabled) {
        return;
      }
      if (href) {
        event.preventDefault();
      }
      onPress?.(event as GestureResponderEvent, context as CT);
    },
    [onPress, href, context, disabled],
  );

  const webStyle = useMemo(() => {
    if (!style || (!style && !styleSlim && isSlim)) {
      return undefined;
    }
    let result: CSSProperties = {};
    if (style) {
      result = {
        ...result,
        ...styleSheetToCss(style),
      };
    }
    if (isSlim && styleSlim) {
      result = {
        ...result,
        ...styleSheetToCss(styleSlim),
      };
    }
    return result;
  }, [style, styleSlim, isSlim]);

  const iconLeftRender = useMemo(
    () => (
      <>
        {typeof iconLeft === 'string' && (
          <Icon style={{ opacity: isSelected || isEnabled ? 1 : 0.65 }} name={(!isSelected ? iconLeft : selectedIcon || iconLeft) as any} size={24} />
        )}
        {typeof iconLeft !== 'string' && !!iconLeft && iconLeft}
      </>
    ),
    [iconLeft, selectedIcon, isSelected, isEnabled],
  );

  const iconRightRender = useMemo(
    () => (
      <>
        {typeof iconRight === 'string' && (
          <Icon
            style={{ opacity: isSelected || isEnabled ? 1 : 0.65 }}
            name={(!isSelected ? iconRight : selectedIcon || iconRight) as any}
            size={24}
          />
        )}
        {typeof iconRight !== 'string' && !!iconRight && iconRight}
      </>
    ),
    [iconRight, selectedIcon, isSelected, isEnabled],
  );

  let marginLeft: string | number | undefined;

  if (!iconLeft) {
    marginLeft = 0;
  } else {
    marginLeft = isSlim ? undefined : unit(12);
  }

  const textRender = useMemo(
    () => (
      <Typography
        style={{
          marginLeft,
          marginTop: isSlim ? unit(2) : undefined,
          opacity: isSelected || isEnabled ? 1 : 0.65,
        }}
        fontSize={!isSlim ? 13 : 11}
        fontWeight={400}
      >
        {!isSlim ? text : slimText || text}
      </Typography>
    ),
    [text, slimText, isSelected, isEnabled, isSlim, iconLeft],
  );

  const renderBody = useMemo(
    () => (
      <>
        {iconLeftRender}
        {textRender}
        {iconRightRender}
      </>
    ),
    [iconLeftRender, textRender, iconRightRender],
  );

  if (isSlim && !user) {
    return null;
  }

  if (href) {
    return (
      <a
        href={href}
        className={cn(classes.button, {
          [classes.selected]: isSelected,
          [classes.slim]: isSlim,
        })}
        style={webStyle}
        onClick={handleClick}
      >
        {renderBody}
      </a>
    );
  }

  return (
    <button
      type="button"
      className={cn(classes.button, {
        [classes.selected]: isSelected,
        [classes.slim]: isSlim,
      })}
      style={webStyle}
      onClick={handleClick}
    >
      {renderBody}
    </button>
  );
};

type styleClasses = 'button' | 'slim' | 'selected' | 'enabled';

type styleProps = {
  disabled?: boolean;
  hoverColor?: string;
  revertSlim?: boolean;
};

const useStyles = createUseStyles<styleClasses, styleProps>({
  button: {
    '&': {
      display: 'flex',
      padding: 0,
      margin: 0,
      border: 0,
      borderRadius: unit(17),
      fontSize: 'inherit',
      textDecoration: 'none',
      cursor: (props) => (props.disabled ? 'default' : 'pointer'),
      background: 'transparent',
      textAlign: 'left',
      paddingTop: unit(5),
      paddingBottom: unit(5),
      paddingLeft: unit(10),
      paddingRight: unit(10),
      marginLeft: unit(10),
      marginRight: unit(10),
      alignItems: 'center',
      opacity: (props) => (props.disabled ? 0.75 : 1),
    },
    '&:hover': {
      background: (props) => (props.disabled ? undefined : props.hoverColor),
    },
  },
  slim: {
    flexDirection: (props) => (props.revertSlim ? 'column-reverse' : 'column'),
    borderRadius: 0,
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
    textAlign: 'center',
  },
  selected: {
    background: (props) => props.hoverColor,
  },
  enabled: {},
});

export default memo(MenuButton);
