import { type SyntheticEvent, memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';
import useScrollStyle from 'hooks/useScrollStyle';

import * as modalStore from 'widgets/modals/store';

import { parseItemId } from '../model/itemIdParser';
import SpaceTreeView from '../elements/SpaceTreeView';

export interface ResourcesToFolderParams {
  resourceIds: string[];
  mode: 'add' | 'move';
}

const ResourcesToFolder = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(modalStore.selectors.state('ResourcesToFolder'));
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const scrollStyle = useScrollStyle();
  const handleModalClose = useCallback(async () => {
    dispatcher.modal.close('ResourcesToFolder', {
      isCancel: true,
    });
  }, []);

  const handleSelectItem = useCallback((event: SyntheticEvent<Element>, itemId: string | null) => {
    if (!itemId) {
      return;
    }
    setSelectedItemId(itemId);
  }, []);

  const handleConfirm = useCallback(async () => {
    if (!selectedItemId) {
      return;
    }
    const { spaceId, entityId } = parseItemId(selectedItemId);
    dispatcher.spaceResource.moveToFolder({
      entityIds: params?.resourceIds,
      newPosition: {
        spaceId,
        parentId: entityId,
        to: 'end',
      },
    });
    dispatcher.modal.close('ResourcesToFolder', {
      isCancel: false,
    });
  }, [selectedItemId, params?.resourceIds]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ModalDialog
        variant="soft"
        color="neutral"
        size="lg"
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: 'min(600px, calc(100% - 32px))',
          maxWidth: 'min(500px, calc(100% - 32px))',
          borderRadius: '1.25rem',
          '--Card-padding': '2.5rem',
          '--FormLabel-color': '--joy-palette-neutral-plainDisabledColor',
          '@media (max-width: 767px)': {
            maxHeight: 'calc(100% - 32px)',
          },
        }}
      >
        <DialogTitle sx={{ fontSize: 34, fontWeight: 400, lineHeight: 1.2 }}>
          {params?.mode === 'add' ? 'Add materials' : 'Move materials'}
        </DialogTitle>
        <DialogContent
          sx={{
            position: 'relative',
            gap: 3,
            border: '1px solid var(--joy-palette-background-level2)',
            borderRadius: 'var(--joy-radius-xl)',
            mt: 0.5,
          }}
        >
          <Box position="absolute" top={4} right={4} bottom={4} left={4} overflow="auto" sx={{ pr: 1, ...scrollStyle }}>
            <SpaceTreeView
              enableReordering={false}
              enableEditing={false}
              selectedItems={selectedItemId}
              expandRootOnMount
              onSelectedItemsChange={handleSelectItem}
              sx={{ minHeight: 300 }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ gap: 1, pt: 0 }}>
          <Button variant="solid" color="neutral" size="sm" disabled={!selectedItemId} onClick={handleConfirm}>
            Confirm
          </Button>
          <Button variant="outlined" color="neutral" size="sm" onClick={handleModalClose}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default memo(ResourcesToFolder);
