import { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, IconButton, Tooltip } from '@mui/joy';
import Icon from 'ui/Icon';

import { useRoute } from 'navigation/hooks';

import { useGetShare } from 'hooks';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as playlistStore from 'store/nodes/playlist';

import CollectionShare from 'widgets/CollectionShare';

const ActionsBar = () => {
  const route = useRoute<'Playlist'>();
  const { resourceId } = route.params;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatcher = useDispatcher();

  const data = useSelector(playlistStore.selectors.dataById(resourceId));

  const userPermission = useSelector(playlistStore.selectors.userPermission(resourceId as number, 'my'));

  const share = useGetShare();

  const handleEdit = useCallback(() => {
    if (!data || !userPermission.hasWrite) {
      return;
    }
    dispatcher.modal.open('AddorEditCollection', { edit: { id: data?.id } });
  }, [userPermission.hasWrite, data?.id]);

  const handleDelete = useCallback(() => {
    if (!data?.id || !userPermission.hasWrite) {
      return;
    }
    dispatcher.playlist.remove({ id: data.id });
  }, [userPermission.hasWrite, data?.id]);

  const handleInQueue = useCallback(() => {
    if (!data?.id) {
      return;
    }
    dispatcher.interaction.toggleBookmark('playlist', data?.id);
  }, [data?.id, userPermission.hasWrite]);

  const handleShare = useCallback(() => {
    share(data);
  }, [share, data]);

  const handleOpenModal = useCallback(() => {
    if (!userPermission.hasInvite) {
      return;
    }
    setIsModalOpen(true);
  }, [userPermission.hasInvite]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  if (!resourceId) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="row" gap={1.5}>
      {userPermission.hasInvite && (
        <>
          <Tooltip arrow variant="outlined" size="sm" title="Share collection" disableInteractive>
            <IconButton size="sm" color="neutral" variant="soft" onClick={handleOpenModal}>
              <Icon name="user-plus" fw weight="light" />
            </IconButton>
          </Tooltip>
          <CollectionShare resourceId={resourceId} open={isModalOpen} onClose={handleCloseModal} />
        </>
      )}
      {userPermission.hasWrite && (
        <>
          <Tooltip arrow variant="outlined" size="sm" title="Edit collection" disableInteractive>
            <IconButton size="sm" color="neutral" variant="soft" onClick={handleEdit}>
              <Icon name="pen-to-square" fw weight="light" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow variant="outlined" size="sm" title="Delete collection" disableInteractive>
            <IconButton size="sm" color="neutral" variant="soft" onClick={handleDelete}>
              <Icon name="trash" fw weight="light" />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip arrow variant="outlined" size="sm" title="Copy link" disableInteractive>
        <IconButton size="sm" color="neutral" variant="soft" onClick={handleShare}>
          <Icon name="link" fw weight="light" />
        </IconButton>
      </Tooltip>
      <Tooltip
        arrow
        variant="outlined"
        size="sm"
        title={!data?.isBookmarked ? 'Add collection to bookmarks' : 'Remove collection from bookmarks'}
        disableInteractive
      >
        <IconButton size="sm" color="neutral" variant="soft" onClick={handleInQueue}>
          <Icon name="bookmark" fw weight={!data?.isBookmarked ? 'light' : 'solid'} />
        </IconButton>
      </Tooltip>
      {/* {userData && (
        <Tooltip
          arrow
          variant="outlined"
          size="sm"
          title={
            data?.isSmartListEnabled
              ? 'Hide recommendations'
              : 'Show recommendations'
          }
          disableInteractive
        >
          <IconButton
            size="sm"
            color={data?.isSmartListEnabled ? 'primary' : 'neutral'}
            variant={data?.isSmartListEnabled ? 'solid' : 'soft'}
            onClick={handleSmartList}
          >
            <Icon
              name="stars"
              fw
              weight="light"
              color={data?.isSmartListEnabled ? '#FFFFFF' : 'primary'}
            />
          </IconButton>
        </Tooltip>
      )} */}
    </Box>
  );
};

export default memo(ActionsBar);
