import React, { memo, useCallback, useState } from 'react';
import { type StyleProp, type ViewStyle, StyleSheet } from 'react-native';
import { Box, Button, Typography } from '@mui/joy';

import { unit } from 'utils';
import { useThemeColor } from 'hooks';

import { View } from 'components/Themed';
import Lottie from 'components/Lottie';
import HtmlViewer from 'components/HtmlViewer';

import useContent from './model/useContent';

type VideoViewerProps = {
  style?: StyleProp<ViewStyle>;
  src?: string;
  content?: string | null;
};

const VideoViewer = (props: VideoViewerProps) => {
  const { style, src, content } = props;

  const [loading, setLoading] = useState(true);
  const [isExpand, setIsExpand] = useState(false);

  const lottieColor = useThemeColor({ light: '#497CFF', dark: '#497CFF' });

  const { shortText, html, hasText, hasExpand } = useContent(content, 500);

  const handleExpandToggle = useCallback(() => {
    setIsExpand((prevState) => {
      return !prevState;
    });
  }, []);

  if (!src) {
    return null;
  }

  return (
    <Box
      sx={{
        '& iframe': {
          border: 'unset',
        },
      }}
    >
      <View style={[styles.component, style]}>
        {loading && (
          <View style={styles.loading}>
            <Lottie name="LoadingCircle" loop width={160} height={160} color={lottieColor} />
          </View>
        )}
        <iframe
          width="100%"
          height="100%"
          src={src?.replace(/.*\?v=(.*)$/, 'https://www.youtube.com/embed/$1')}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          onLoad={() => setLoading(false)}
        />
      </View>
      {hasText && (
        <Box mt={4}>
          <Box display="flex" flexDirection="row" alignItems="flex-end" gap={1}>
            <Typography level="h3">Transcript</Typography>
            {hasExpand && (
              <Button
                size="sm"
                variant="outlined"
                color="neutral"
                sx={{ minHeight: 0, py: 0, mb: 0.25, px: 1, fontWeight: 400 }}
                onClick={handleExpandToggle}
              >
                show {isExpand ? 'less' : 'full'}
              </Button>
            )}
          </Box>
          <Box mt={0} maxWidth={700} mr="auto">
            {hasExpand && <HtmlViewer content={(!isExpand ? `<p>${shortText}</p>` : html) || ''} />}
            {!hasExpand && <HtmlViewer content={html || ''} />}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  component: {
    position: 'relative',
    flexDirection: 'row',
    overflow: 'hidden',
    width: '100%',
    aspectRatio: 16 / 9,
    borderRadius: unit(5),
  },
  loading: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: unit(320),
  },
});

export default memo(VideoViewer);
